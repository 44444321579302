import React, { useEffect, useRef } from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';

const Latex = ({ children }) => {
    const latexRef = useRef(null);

    useEffect(() => {
        if (latexRef.current) {
            katex.render(children, latexRef.current, {
                throwOnError: false,
                errorColor: '#000000', // Change this to match your background color
            });
        }
    }, [children]);

    return <div ref={latexRef} className="latex" />;
};

export default Latex;
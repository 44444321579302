import React from 'react';
import SideBar from '../../components/SideBar';
import TRegrade from '../../components/Teacher/TRegrade';

const TProfilePage = () => {
  return (
    <div className="HomePageHeader">
      <div id="Main-container" className="flex flex-row">
        <div id="Left-Sidebar" className="sticky top-0 h-screen overflow-y-auto">
          <SideBar activePage="Regrade" />
        </div>
        <div id="Right-content" className="flex-grow">
          <TRegrade />
        </div>
      </div>
    </div>
  );
};

export default TProfilePage;

import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {default as React, useCallback, useEffect, useRef, useState} from 'react';
import {useAuth} from '../../auth/AuthContext';
import {TQuestionColumn} from './TQuestionColumn.js';

import {DndContext, rectIntersection} from '@dnd-kit/core';
import {arrayMove} from '@dnd-kit/sortable';
import {apiUrl, gradeApi} from '../../api.js';

import 'katex/dist/katex.min.css';

function TPset({
                   assignment_id,
                   updateTrigger,
                   setUpdateTrigger,
                   isPublished,
                   pullQuestions,
                   setPullQuestions,
                   setEdited,
                   edited,
                   setSolRecent,
                   solRecent
               }) {
    const [examPaper, setExamPaper] = useState([]);
    const [instructions, setInstructions] = useState('');
    const [editableInstructions, setEditableInstructions] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [created, setCreated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [iframeKey, setIframeKey] = useState(Date.now());
    const [lastSaved, setLastSaved] = useState(null);
    const [showSavedPopup, setShowSavedPopup] = useState(false);
    const {user} = useAuth();
    const instructionsRef = useRef(null);
    const [pdfUrl, setPdfUrl] = useState('');
    const [teacherPdfUrl, setTeacherPdfUrl] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const saveTimeoutRef = useRef(null);
    const lastSavedStateRef = useRef(null);
    const [previews, setPreviews] = useState({});
    const disabledStyle = isPublished ? '!opacity-50' : '';
    const [focusedQuestion, setFocusedQuestion] = useState('');
    const [focusedSubQuestion, setFocusedSubQuestion] = useState('');
    const [gradingInstructions, setGradingInstructions] = useState('');
    const [editableGradingInstructions, setEditableGradingInstructions] = useState('');
    const autoSaveIntervalRef = useRef(null);
    const [isGradingInstructionsVisible, setIsGradingInstructionsVisible] = useState(false);
    const [feedbackInstructions, setFeedbackInstructions] = useState('');
    const [editableFeedbackInstructions, setEditableFeedbackInstructions] = useState('');
    const [isFeedbackInstructionsVisible, setIsFeedbackInstructionsVisible] = useState(false);

    const loadDraft = useCallback(async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/load-draft/`,
                config: {headers: {Authorization: `Bearer ${user.session_token}`}}
            });
            if (response.data.draft_data) {
                const {
                    examPaper,
                    instructions,
                    editableInstructions,
                    created,
                    edited,
                    pdfUrl,
                    teacherPdfUrl,
                    gradingInstructions,
                    feedbackInstructions
                } = JSON.parse(response.data.draft_data);
                if (examPaper && examPaper.length > 0) {
                    setExamPaper(examPaper);
                }
                // console.log(instructions);
                if (instructions) {
                    setInstructions(instructions);
                    setEditableInstructions(instructions);
                } else {
                    const defaultInstructions = `- Show your work. To receive full credit, your answers must be neatly written and logically organized. Complete justifications and explanations are expected, unless a particular question indicates otherwise.
- Write your answers in the space provided. If your answers are not written in the space provided, they will not be seen by the grader.
- Simplification. Please leave your final answer in a "polite" form. For example, e^pi * sin(1/2) is a great way to write that particular number. On the other hand, cos(pi) can be simplified to -1.
- Academic integrity is expected of all students at all times, whether in the presence or absence of the faculty. Understanding this, I declare I shall not give, use, or receive unauthorized aid in this assignment.`;
                    setInstructions(defaultInstructions);
                    setEditableInstructions(defaultInstructions);
                }
                if (feedbackInstructions) {
                    setFeedbackInstructions(feedbackInstructions);
                    setEditableFeedbackInstructions(feedbackInstructions);
                } else {
                    const defaultFeedbackInstructions = 'Keep your feedback concise and constructive.';
                    setFeedbackInstructions(defaultFeedbackInstructions);
                    setEditableFeedbackInstructions(defaultFeedbackInstructions);
                }
                if (gradingInstructions) {
                    setGradingInstructions(gradingInstructions);
                    setEditableGradingInstructions(gradingInstructions);
                } else {
                    const defaultGradingInstructions =
                        "Grade the student's work carefully. Pay attention to their problem-solving approach, clarity of explanations, and correctness of calculations. Select the appropriate rubric item, based on the demonstrated understanding and effort.";
                    setGradingInstructions(defaultGradingInstructions);
                    setEditableGradingInstructions(defaultGradingInstructions);
                }
                if (created !== undefined) {
                    setCreated(created);
                }

                if (edited !== undefined) {
                    setEdited(edited);
                }

                if (pdfUrl) {
                    setPdfUrl(pdfUrl);
                }
                if (teacherPdfUrl) {
                    setTeacherPdfUrl(teacherPdfUrl);
                }
            } else {
                const defaultInstructions = `- Show your work. To receive full credit, your answers must be neatly written and logically organized. Complete justifications and explanations are expected, unless a particular question indicates otherwise.
- Write your answers in the space provided. If your answers are not written in the space provided, they will not be seen by the grader.
- Simplification. Please leave your final answer in a "polite" form. For example, e^pi * sin(1/2) is a great way to write that particular number. On the other hand, cos(pi) can be simplified to -1.
- Academic integrity is expected of all students at all times, whether in the presence or absence of the faculty. Understanding this, I declare I shall not give, use, or receive unauthorized aid in this assignment.`;
                setInstructions(defaultInstructions);
                setEditableInstructions(defaultInstructions);
                const defaultGradingInstructions =
                    "Grade the student's work carefully. Pay attention to their problem-solving approach, clarity of explanations, and correctness of calculations. Select the appropriate rubric item, based on the demonstrated understanding and effort.";
                setGradingInstructions(defaultGradingInstructions);
                setEditableGradingInstructions(defaultGradingInstructions);
                const defaultFeedbackInstructions = 'Keep your feedback concise and constructive.';
                setFeedbackInstructions(defaultFeedbackInstructions);
                setEditableFeedbackInstructions(defaultFeedbackInstructions);
            }
            setIsDataLoaded(true);
        } catch (error) {
            console.error('Error loading draft:', error);
            setIsDataLoaded(true);
        }
    }, [assignment_id, user.session_token]);

    useEffect(() => {
        loadDraft();
    }, []);

    useEffect(() => {
        if (!solRecent) {
            setEdited(true);
        }
        setSolRecent(false);
    }, [examPaper]);

    useEffect(() => {
        if (pullQuestions === true) {
            getQuestions();
            setPullQuestions(false);
            setEdited(false);
        }
    }, [pullQuestions]);

    const saveProgress = useCallback(
        async (additionalData = {}) => {
            if (isPublished) {
                return;
            }
            const dataToSave = {
                examPaper,
                instructions: editableInstructions,
                created,
                edited,
                pdfUrl,
                teacherPdfUrl,
                gradingInstructions: editableGradingInstructions,
                feedbackInstructions: editableFeedbackInstructions,
                ...additionalData
            };
            // console.log(examPaper);
            try {
                const response = await gradeApi.post({
                    path: `/api/assignment/${assignment_id}/save-draft/`,
                    body: dataToSave,
                    config: {headers: {Authorization: `Bearer ${user.session_token}`}}
                });

                // console.log(dataToSave);
                try {
                    const responseData = JSON.parse(response.data.draft_data);

                    // console.log(responseData);
                    // Update examPaper with new imageS3Keys
                    examPaper.forEach((question, questionIndex) => {
                        const responseQuestion = responseData.examPaper[questionIndex];
                        if (responseQuestion) {
                            if (responseQuestion.imageS3Key) {
                                question.imageS3Key = responseQuestion.imageS3Key;
                                question.imageUrl = null;
                            }

                            // Update subquestions
                            if (question.subQuestions && responseQuestion.subQuestions) {
                                question.subQuestions.forEach((subQuestion, subIndex) => {
                                    const responseSubQuestion = responseQuestion.subQuestions[subIndex];
                                    if (responseSubQuestion && responseSubQuestion.imageS3Key) {
                                        subQuestion.imageS3Key = responseSubQuestion.imageS3Key;
                                        subQuestion.imageUrl = null;
                                    }
                                });
                            }
                        }
                    });

                    // console.log('Updated examPaper with new imageS3Keys:', examPaper);

                    setLastSaved(new Date());
                    setShowSavedPopup(true);
                    setTimeout(() => setShowSavedPopup(false), 2000);
                    lastSavedStateRef.current = JSON.stringify(responseData);

                    if (responseData.instructions) {
                        setInstructions(responseData.instructions);
                        setEditableInstructions(responseData.instructions);
                    }
                } catch (error) {
                    console.error('Error updating data:', error);
                }
            } catch (error) {
                console.error('Error saving draft:', error);
            }
        },
        [
            examPaper,
            editableInstructions,
            created,
            edited,
            pdfUrl,
            teacherPdfUrl,
            editableGradingInstructions,
            editableFeedbackInstructions,
            assignment_id,
            user.session_token,
            isPublished
        ]
    );

    useEffect(() => {
        autoSaveIntervalRef.current = setInterval(() => {
            saveProgress();
        }, 30000); // auto-save interval every 30 sec

        return () => {
            if (autoSaveIntervalRef.current) {
                clearInterval(autoSaveIntervalRef.current);
            }
        };
    }, [saveProgress]);

    const handleGradingInstructionsEdit = () => {
        setIsEditing(true);
    };

    const handleGradingInstructionsChange = event => {
        setEditableGradingInstructions(event.target.value);
    };

    const handleGradingInstructionsSave = () => {
        setGradingInstructions(editableGradingInstructions);
        setIsEditing(false);
        saveProgress({gradingInstructions: editableGradingInstructions});
    };

    const handleFeedbackInstructionsEdit = () => {
        setIsEditing(true);
    };

    const handleFeedbackInstructionsChange = event => {
        setEditableFeedbackInstructions(event.target.value);
    };

    const handleFeedbackInstructionsSave = async () => {
        try {
            setFeedbackInstructions(editableFeedbackInstructions);
            setIsEditing(false);
            saveProgress({feedbackInstructions: editableFeedbackInstructions});
        } catch (error) {
            console.error('Error updating feedback instructions:', error);
        }
    };

    const triggerSave = useCallback(() => {
        if (!saveTimeoutRef.current) {
            saveTimeoutRef.current = setTimeout(() => {
                saveProgress();
                saveTimeoutRef.current = null;
            }, 5000);
        }
    }, [saveProgress]);

    useEffect(() => {
        return () => {
            if (saveTimeoutRef.current) {
                clearTimeout(saveTimeoutRef.current);
            }
        };
    }, []);

    const uploadQuestions = async () => {
        setLoading(true);
        if (examPaper.length === 0) {
            setError('Exam needs at least one question');
            setLoading(false);
            return;
        }

        for (let question of examPaper) {
            if (question.subQuestions.length === 0) {
                setError('Each question must have at least one subquestion.');
                setLoading(false);
                return;
            }
            for (let subQuestion of question.subQuestions) {
                if (subQuestion.questionType === 'freeform') {
                    if (subQuestion.questionSizing === '') {
                        setError('Sizing must be set for every subquestion.');
                        setLoading(false);
                        return;
                    }
                    if (subQuestion.rubricItems.length < 2) {
                        setError('Each freeform subquestion must have at least two rubric items.');
                        setLoading(false);
                        return;
                    }

                    // if (subQuestion.solution === '') {
                    //     setError("Each freeform question must have a solution.");
                    //     setLoading(false);
                    //     return;
                    // }
                    for (let rubricItem of subQuestion.rubricItems) {
                        if (String(rubricItem.value).trim() === '' || rubricItem.descriptor.trim() === '') {
                            setError('Each rubric item must have a descriptor and point value.');
                            setLoading(false);
                            return;
                        }
                    }
                } else if (subQuestion.questionType === 'mcq') {
                    if (subQuestion.mcqOptions.length < 2) {
                        setError('Each MCQ subquestion must have at least two options.');
                        setLoading(false);
                        return;
                    }
                    let hasCorrectAnswer = subQuestion.mcqOptions.some(option => option.isCorrect);
                    if (!hasCorrectAnswer) {
                        setError('Each MCQ subquestion must have at least one correct answer.');
                        setLoading(false);
                        return;
                    }
                    if (!subQuestion.weight || subQuestion.weight < 1) {
                        setError('Each MCQ subquestion must have a weight of at least 1.');
                        setLoading(false);
                        return;
                    }
                } else {
                    setError('Invalid question type for subquestion.');
                    setLoading(false);
                    return;
                }
            }
        }

        const payload = {
            exam_paper: examPaper.map(question => ({
                ...question,
                subQuestions: question.subQuestions.map(subQuestion => ({
                    ...subQuestion,
                    mcqOptions: subQuestion.questionType === 'mcq' ? subQuestion.mcqOptions : undefined,
                    rubricItems:
                        subQuestion.questionType === 'mcq'
                            ? [
                                {descriptor: 'Correct Answer', value: subQuestion.weight.toString()},
                                {descriptor: 'Incorrect Answer', value: '0'}
                            ]
                            : subQuestion.rubricItems,
                    ...(subQuestion.questionType === 'mcq' && {mcqOptions: subQuestion.mcqOptions})
                }))
            })),
            instructions: editableInstructions,
            model_instructions: gradingInstructions,
            feedback_instructions: feedbackInstructions
        };

        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment_id}/constructor/`,
                body: payload,
                config: {headers: {Authorization: `Bearer ${user.session_token}`}}
            });
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/build/`,
                config: {headers: {Authorization: `Bearer ${user.session_token}`}}
            });
            setPdfUrl(response.data.url);
            setTeacherPdfUrl(response.data.teacher_url);
            saveProgress({
                pdfUrl: response.data.url,
                teacherPdfUrl: response.data.teacher_url,
                created: true,
                edited: false
            });
            setLoading(false);
            setEdited(false);
            setUpdateTrigger(prev => !prev);
            setIframeKey(Date.now());
        } catch (error) {
            setError(error.response.request.response || 'An error occurred. Please try again.');
            setLoading(false);
        }
        setError('');
    };

    const getQuestions = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/questions-frontend/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const questionsFromDB = response.data;
            setExamPaper(questionsFromDB.questions);
            saveProgress({examPaper: questionsFromDB.questions});
            if (questionsFromDB.questions.length !== 0) {
                setCreated(true);
            }
            setTeacherPdfUrl(questionsFromDB.teacher_url);
            // console.log(examPaper);
            // console.log(questionsFromDB.questions);
        } catch (error) {
            console.log(error);
            setCreated(false);
        }
        setEdited(false);
    };

    const addSubQuestion = questionId => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const newSubQuestionId = question.subQuestions.length + 1;
                setFocusedSubQuestion(newSubQuestionId);
                return {
                    ...question,
                    subQuestions: [
                        ...question.subQuestions,
                        {
                            id: newSubQuestionId,
                            questionType: 'freeform',
                            question: '',
                            questionSizing: 40,
                            solution: '',
                            rubricItems: [
                                {descriptor: 'Fully correct', value: '2'},
                                {descriptor: 'Partially correct', value: '1'},
                                {descriptor: 'Incorrect or no work', value: '0'}
                            ],
                            mcqOptions: [
                                {option: 'A', text: '', isCorrect: false},
                                {option: 'B', text: '', isCorrect: false}
                            ],
                            image: null,
                            imageSize: 50,
                            weight: 1
                        }
                    ]
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const handleWeightChange = (questionId, subQuestionId, event) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                    if (subQuestion.id === subQuestionId) {
                        return {
                            ...subQuestion,
                            weight: parseInt(event.target.value)
                        };
                    }
                    return subQuestion;
                });
                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const handleQuestionTypeChange = (questionId, subQuestionId, event) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                    if (subQuestion.id === subQuestionId) {
                        return {
                            ...subQuestion,
                            questionType: event.target.value
                        };
                    }
                    return subQuestion;
                });
                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const addMCQOption = (questionId, subQuestionId) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                    if (subQuestion.id === subQuestionId) {
                        const currentOptions = subQuestion.mcqOptions;
                        const nextOption = String.fromCharCode(65 + currentOptions.length);
                        return {
                            ...subQuestion,
                            mcqOptions: [...currentOptions, {option: nextOption, text: '', isCorrect: false}]
                        };
                    }
                    return subQuestion;
                });
                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const handleMCQOptionChange = (questionId, subQuestionId, optionIndex, event) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                    if (subQuestion.id === subQuestionId) {
                        const updatedOptions = subQuestion.mcqOptions.map((option, oIndex) => {
                            if (oIndex === optionIndex) {
                                return {
                                    ...option,
                                    text: event.target.value
                                };
                            }
                            return option;
                        });
                        return {
                            ...subQuestion,
                            mcqOptions: updatedOptions
                        };
                    }
                    return subQuestion;
                });
                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const handleMCQCorrectAnswer = (questionId, subQuestionId, optionIndex) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                    if (subQuestion.id === subQuestionId) {
                        const updatedOptions = subQuestion.mcqOptions.map((option, oIndex) => ({
                            ...option,
                            isCorrect: oIndex === optionIndex
                        }));
                        return {
                            ...subQuestion,
                            mcqOptions: updatedOptions
                        };
                    }
                    return subQuestion;
                });
                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const deleteMCQOption = (questionId, subQuestionId, optionIndex) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                    if (subQuestion.id === subQuestionId) {
                        // Remove the option at optionIndex
                        const updatedOptions = subQuestion.mcqOptions.filter(
                            (_, oIndex) => oIndex !== optionIndex
                        );
                        // Renumber the remaining options
                        const renumberedOptions = updatedOptions.map((option, index) => ({
                            ...option,
                            option: String.fromCharCode(65 + index)
                        }));
                        return {
                            ...subQuestion,
                            mcqOptions: renumberedOptions
                        };
                    }
                    return subQuestion;
                });
                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };
    const addRubricItem = (questionId, subQuestionId) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                    if (subQuestion.id === subQuestionId) {
                        return {
                            ...subQuestion,
                            rubricItems: [...subQuestion.rubricItems, {descriptor: '', value: ''}]
                        };
                    }
                    return subQuestion;
                });
                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const handleQuestionChange = (questionId, subQuestionId, event) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                    if (subQuestion.id === subQuestionId) {
                        return {
                            ...subQuestion,
                            question: event.target.value
                        };
                    }
                    return subQuestion;
                });
                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const handleSolutionChange = (questionId, subQuestionId, event) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                    if (subQuestion.id === subQuestionId) {
                        return {
                            ...subQuestion,
                            solution: event.target.value
                        };
                    }
                    return subQuestion;
                });
                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const handleQuestionSizingChange = (questionId, subQuestionId, event) => {
        const value = event.target.value;
        const parsedValue = value === '' ? '' : parseInt(value, 10);
        if (value === '' || (/^\d+$/.test(value) && parsedValue >= 1 && parsedValue <= 100)) {
            const updatedExamPaper = examPaper.map(question => {
                if (question.id === questionId) {
                    const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                        if (subQuestion.id === subQuestionId) {
                            return {
                                ...subQuestion,
                                questionSizing: parsedValue
                            };
                        }
                        return subQuestion;
                    });
                    return {
                        ...question,
                        subQuestions: updatedSubQuestions
                    };
                }
                return question;
            });
            setExamPaper(updatedExamPaper);
            triggerSave();
        }
    };

    const handleRubricDescriptorChange = (questionId, subQuestionId, rubricIndex, event) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                    if (subQuestion.id === subQuestionId) {
                        const updatedRubricItems = subQuestion.rubricItems.map((rubricItem, rIndex) => {
                            if (rIndex === rubricIndex) {
                                return {
                                    ...rubricItem,
                                    descriptor: event.target.value
                                };
                            }
                            return rubricItem;
                        });
                        return {
                            ...subQuestion,
                            rubricItems: updatedRubricItems
                        };
                    }
                    return subQuestion;
                });
                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const handleRubricValueChange = (questionId, subQuestionId, rubricIndex, event) => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
            const updatedExamPaper = examPaper.map(question => {
                if (question.id === questionId) {
                    const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                        if (subQuestion.id === subQuestionId) {
                            const updatedRubricItems = subQuestion.rubricItems.map((rubricItem, rIndex) => {
                                if (rIndex === rubricIndex) {
                                    return {
                                        ...rubricItem,
                                        value: event.target.value
                                    };
                                }
                                return rubricItem;
                            });
                            return {
                                ...subQuestion,
                                rubricItems: updatedRubricItems
                            };
                        }
                        return subQuestion;
                    });
                    return {
                        ...question,
                        subQuestions: updatedSubQuestions
                    };
                }
                return question;
            });
            setExamPaper(updatedExamPaper);
            triggerSave();
        }
    };

    const deleteQuestion = questionId => {
        const updatedExamPaper = examPaper
            .filter(question => question.id !== questionId)
            .map((question, index) => ({
                ...question,
                id: index + 1
            }));
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const deleteSubQuestion = (questionId, subQuestionId) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const updatedSubQuestions = question.subQuestions.filter(
                    subQuestion => subQuestion.id !== subQuestionId
                );
                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const deleteRubricItem = (questionId, subQuestionId, rubricIndex) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                    if (subQuestion.id === subQuestionId) {
                        const updatedRubricItems = subQuestion.rubricItems.filter(
                            (_, rIndex) => rIndex !== rubricIndex
                        );
                        return {
                            ...subQuestion,
                            rubricItems: updatedRubricItems
                        };
                    }
                    return subQuestion;
                });
                return {
                    ...question,
                    subQuestions: updatedSubQuestions
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const handleImageUpload = async (event, questionId, subQuestionId = null) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const imageUrl = reader.result;
                const updatedExamPaper = examPaper.map(question => {
                    if (question.id === questionId) {
                        if (subQuestionId === null) {
                            return {
                                ...question,
                                imageUrl,
                                imageS3Key: null,
                                imageSize: 50
                            };
                        } else {
                            const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                                if (subQuestion.id === subQuestionId) {
                                    return {
                                        ...subQuestion,
                                        imageUrl,
                                        imageS3Key: null,
                                        imageSize: 50
                                    };
                                }
                                return subQuestion;
                            });
                            return {
                                ...question,
                                subQuestions: updatedSubQuestions
                            };
                        }
                    }
                    return question;
                });

                // Save progress and wait for the response
                await saveProgress({examPaper: updatedExamPaper});

                // After saveProgress, update the state with the latest data
                setExamPaper(prevExamPaper => {
                    return prevExamPaper.map(question => {
                        if (question.id === questionId) {
                            if (subQuestionId === null) {
                                return {
                                    ...question,
                                    imageS3Key: question.imageS3Key,
                                    imageUrl: null,
                                    imageSize: 50
                                };
                            } else {
                                return {
                                    ...question,
                                    subQuestions: question.subQuestions.map(subQuestion => {
                                        if (subQuestion.id === subQuestionId) {
                                            return {
                                                ...subQuestion,
                                                imageS3Key: subQuestion.imageS3Key,
                                                imageUrl: null,
                                                imageSize: 50
                                            };
                                        }
                                        return subQuestion;
                                    })
                                };
                            }
                        }
                        return question;
                    });
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageSizeChange = (questionId, subQuestionId, event) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                if (subQuestionId === null) {
                    return {
                        ...question,
                        imageSize:
                            event.target.value === '' || event.target.value > 100
                                ? 0
                                : parseInt(event.target.value)
                    };
                } else {
                    const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                        if (subQuestion.id === subQuestionId) {
                            return {
                                ...subQuestion,
                                imageSize:
                                    event.target.value === '' || event.target.value > 100
                                        ? 0
                                        : parseInt(event.target.value)
                            };
                        }
                        return subQuestion;
                    });
                    return {
                        ...question,
                        subQuestions: updatedSubQuestions
                    };
                }
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const deleteImage = (questionId, subQuestionId = null) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                if (subQuestionId === null) {
                    return {
                        ...question,
                        imageUrl: null,
                        imageS3Key: null
                    };
                } else {
                    const updatedSubQuestions = question.subQuestions.map(subQuestion => {
                        if (subQuestion.id === subQuestionId) {
                            return {
                                ...subQuestion,
                                imageUrl: null,
                                imageS3Key: null
                            };
                        }
                        return subQuestion;
                    });
                    return {
                        ...question,
                        subQuestions: updatedSubQuestions
                    };
                }
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const handlePreviewClick = async (questionId, subQuestionId) => {
        const question = examPaper.find(q => q.id === questionId);
        const subQuestion = question.subQuestions.find(sq => sq.id === subQuestionId);

        try {
            const response = await gradeApi.post({
                path: `/api/preview-latex/`,
                body: {
                    questionId,
                    subQuestionId,
                    description: question.description,
                    subQuestion: subQuestion.question,
                    questionType: subQuestion.questionType,
                    mcqOptions: subQuestion.mcqOptions,
                    questionImageS3Key: question.imageS3Key,
                    subQuestionImageS3Key: subQuestion.imageS3Key,
                    questionImageSize: question.imageSize,
                    subQuestionImageSize: subQuestion.imageSize
                },
                config: {
                    headers: {Authorization: `Bearer ${user.session_token}`},
                    responseType: 'arraybuffer'
                }
            });

            const blob = new Blob([response.data], {type: 'image/png'});
            const imageUrl = URL.createObjectURL(blob);

            const img = new Image();
            img.onload = () => {
                const dpi = 400;
                const widthInches = img.naturalWidth / dpi;
                const heightInches = img.naturalHeight / dpi;
                const widthCm = widthInches * 2.54;
                const heightCm = heightInches * 2.54;

                setPreviews(prev => ({
                    ...prev,
                    [`${questionId}-${subQuestionId}`]: {
                        url: imageUrl,
                        width: `${widthCm}cm`,
                        height: `${heightCm}cm`
                    }
                }));
            };
            img.src = imageUrl;
        } catch (error) {
            console.error('Error previewing LaTeX:', error);
            setError('Failed to generate preview');
        }
    };

    const handleInstructionsEdit = () => {
        setIsEditing(true);
    };
    const handleInstructionsChange = event => {
        setEditableInstructions(event.target.value);
    };

    const handleInstructionsSave = async () => {
        try {
            setInstructions(editableInstructions);
            setIsEditing(false);
            saveProgress({instructions: editableInstructions});
        } catch (error) {
            console.error('Error updating instructions:', error);
        }
    };

    const handleDescriptionChange = (questionId, event) => {
        const updatedExamPaper = examPaper.map(question => {
            if (question.id === questionId) {
                return {
                    ...question,
                    description: event.target.value
                };
            }
            return question;
        });
        setExamPaper(updatedExamPaper);
        triggerSave();
    };

    const addQuestion = () => {
        const newQuestion = {
            id: examPaper.length + 1, // Use the length of the examPaper array to set the ID
            description: '',
            subQuestions: []
        };
        setExamPaper([...examPaper, newQuestion]);
        setFocusedQuestion(newQuestion.id);
        triggerSave();
    };

    const setSubQuestions = (id, subQuestions) => {
        const newExamPaper = prevExamPaper =>
            prevExamPaper.map(question =>
                question.id === id ? {...question, subQuestions} : question
            );
        const newExamPaper1 = newExamPaper(examPaper);
        setExamPaper(newExamPaper1);
        saveProgress({examPaper: newExamPaper1});
    };

    const getTaskPos = id => examPaper.findIndex(question => question.id === id);

    const updateQuestionIds = examPaper => {
        return examPaper.map((question, index) => ({
            ...question,
            id: index + 1 // Assign new ID based on position
        }));
    };
    const handleDragEnd = event => {
        // Check if active and over are defined
        const {active, over} = event;

        if (!active || !over) {
            console.error('Drag event is missing active or over property:', event);
            return;
        }

        if (!active.id || !over.id) {
            console.error('Active or over is missing id property:', {active, over});
            return;
        }

        if (active.id === over.id) {
            return;
        }

        const originalFocusedPos = getTaskPos(focusedQuestion);
        const newExamPaper = examPaper => {
            const originalPos = getTaskPos(active.id);
            const newPos = getTaskPos(over.id);

            if (originalPos === -1 || newPos === -1) {
                console.error('Could not find position for active or over:', {
                    active,
                    over,
                    originalPos,
                    newPos
                });
                return examPaper; // Return original exam paper if positions are invalid
            }

            const updatedPaper = arrayMove(examPaper, originalPos, newPos);

            if (focusedQuestion !== '') {
                const newFocusedPos = updatedPaper.findIndex(
                    (question, index) => index === originalFocusedPos
                );
                if (newFocusedPos !== -1) {
                    setFocusedQuestion(updatedPaper[newFocusedPos].id);
                }
            }
            return updateQuestionIds(updatedPaper);
        };
        const newExamPaper1 = newExamPaper(examPaper);
        // console.log(newExamPaper1);
        setExamPaper(newExamPaper1);
        saveProgress({examPaper: newExamPaper1});
    };

    return (
        <div className={`flex-grow container`}>
            <div className={`flex-grow container ${disabledStyle}`}>
                <div className="relative">
                    {/*{isPublished && (*/}
                    {/*    <div className={`${disabledStyle}`}/>*/}
                    {/*)}*/}
                    <div className="flex flex-col w-full mb-5">
                        <h2 className="text-xl font-bold mb-1">Step 1: Build Assignment</h2>
                        <p className="text-sm mb-4">
                            Enter the questions, preferably in LaTeX, and, optionally, edit rubrics.
                            <br/> Don't worry about the solution key for now; we'll come back to that.
                        </p>
                        <p className="text-sm">Exam Instructions:</p>
                    </div>
                    <div
                        className={`sexy-border rounded-xl mb-4 w-full text-sm h-64 ${isEditing ? 'shadow-lg' : ''}`}
                    >
                        {isEditing ? (
                            <div className="w-full h-full flex flex-col scroll-auto">
                <textarea
                    ref={instructionsRef}
                    disabled={isPublished}
                    value={editableInstructions}
                    className={`actual-input p-4 ${disabledStyle}`}
                    style={{height: 'auto'}}
                    onChange={handleInstructionsChange}
                />
                                <div className="border-t">
                                    <div className="p-2">
                                        <button
                                            disabled={isPublished}
                                            className="less-sexy-button w-40 h-9"
                                            onClick={handleInstructionsSave}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="w-full h-full flex flex-col">
                                <p className="overflow-y-scroll w-full h-full p-4 text-smallish whitespace-pre-wrap">
                                    {instructions}
                                </p>
                                <div className="border-t">
                                    <div className="p-2">
                                        <button
                                            disabled={isPublished}
                                            className="less-sexy-button w-40 h-9"
                                            onClick={handleInstructionsEdit}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col w-full mb-5 mt-8">
                        <button
                            className="flex items-center text-sm text-gray-500 mb-2"
                            onClick={() => setIsGradingInstructionsVisible(!isGradingInstructionsVisible)}
                        >
                            <img
                                src="/assets/back_arrow.png"
                                className={`size-5 opacity-60 transform ${isGradingInstructionsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                alt="Toggle grading instructions"
                            />
                            Grading Instructions (optional)
                        </button>
                    </div>
                    {isGradingInstructionsVisible && (
                        <div
                            className={`sexy-border rounded-xl mb-4 w-full text-sm h-40 ${isEditing ? 'shadow-lg' : ''}`}
                        >
                            {isEditing ? (
                                <div className="w-full h-full flex flex-col scroll-auto">
                  <textarea
                      disabled={isPublished}
                      value={editableGradingInstructions}
                      className={`actual-input p-4 ${disabledStyle} text-xs text-gray-500`}
                      style={{height: 'auto'}}
                      onChange={handleGradingInstructionsChange}
                  />
                                    <div className="border-t">
                                        <div className="p-2">
                                            <button
                                                disabled={isPublished}
                                                className="less-sexy-button w-40 h-9 opacity-50"
                                                onClick={handleGradingInstructionsSave}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full h-full flex flex-col">
                                    <p className="overflow-y-scroll w-full h-full p-4 text-xs text-gray-500 whitespace-pre-wrap">
                                        {gradingInstructions}
                                    </p>
                                    <div className="border-t">
                                        <div className="p-2">
                                            <button
                                                disabled={isPublished}
                                                className="less-sexy-button w-40 h-9 opacity-50"
                                                onClick={handleGradingInstructionsEdit}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="flex flex-col w-full mb-5 mt-5">
                        <button
                            className="flex items-center text-sm text-gray-500 mb-2"
                            onClick={() => setIsFeedbackInstructionsVisible(!isFeedbackInstructionsVisible)}
                        >
                            <img
                                src="/assets/back_arrow.png"
                                className={`size-5 opacity-60 transform ${isFeedbackInstructionsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                alt="Toggle feedback instructions"
                            />
                            Feedback Instructions (optional)
                        </button>
                    </div>
                    {isFeedbackInstructionsVisible && (
                        <div
                            className={`sexy-border rounded-xl mb-4 w-full text-sm h-40 ${isEditing ? 'shadow-lg' : ''}`}
                        >
                            {isEditing ? (
                                <div className="w-full h-full flex flex-col scroll-auto">
                  <textarea
                      disabled={isPublished}
                      value={editableFeedbackInstructions}
                      className={`actual-input p-4 ${disabledStyle} text-xs text-gray-500`}
                      style={{height: 'auto'}}
                      onChange={handleFeedbackInstructionsChange}
                  />
                                    <div className="border-t">
                                        <div className="p-2">
                                            <button
                                                disabled={isPublished}
                                                className="less-sexy-button w-40 h-9 opacity-50"
                                                onClick={handleFeedbackInstructionsSave}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full h-full flex flex-col">
                                    <p className="overflow-y-scroll w-full h-full p-4 text-xs text-gray-500 whitespace-pre-wrap">
                                        {feedbackInstructions}
                                    </p>
                                    <div className="border-t">
                                        <div className="p-2">
                                            <button
                                                disabled={isPublished}
                                                className="less-sexy-button w-40 h-9 opacity-50"
                                                onClick={handleFeedbackInstructionsEdit}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {showSavedPopup && (
                        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded">
                            Progress saved
                        </div>
                    )}
                    {lastSaved && (
                        <div className="text-sm text-gray-500 mt-2">
                            Last saved: {lastSaved.toLocaleTimeString()}
                        </div>
                    )}
                    <DndContext
                        collisionDetection={rectIntersection}
                        modifiers={[restrictToVerticalAxis]}
                        onDragEnd={handleDragEnd}
                    >
                        <TQuestionColumn
                            examPaper={examPaper}
                            isPublished={isPublished}
                            deleteQuestion={deleteQuestion}
                            handleDescriptionChange={handleDescriptionChange}
                            handleImageUpload={handleImageUpload}
                            deleteImage={deleteImage}
                            handleImageSizeChange={handleImageSizeChange}
                            handlePreviewClick={handlePreviewClick}
                            previews={previews}
                            handleQuestionSizingChange={handleQuestionSizingChange}
                            deleteRubricItem={deleteRubricItem}
                            deleteSubQuestion={deleteSubQuestion}
                            handleQuestionTypeChange={handleQuestionTypeChange}
                            handleQuestionChange={handleQuestionChange}
                            handleRubricDescriptorChange={handleRubricDescriptorChange}
                            handleRubricValueChange={handleRubricValueChange}
                            addRubricItem={addRubricItem}
                            handleWeightChange={handleWeightChange}
                            handleMCQOptionChange={handleMCQOptionChange}
                            handleMCQCorrectAnswer={handleMCQCorrectAnswer}
                            deleteMCQOption={deleteMCQOption}
                            addMCQOption={addMCQOption}
                            handleSolutionChange={handleSolutionChange}
                            addSubQuestion={addSubQuestion}
                            focusedQuestion={focusedQuestion}
                            setFocusedQuestion={setFocusedQuestion}
                            setSubQuestions={setSubQuestions}
                            triggerSave={triggerSave}
                            saveProgress={saveProgress}
                            focusedSubQuestion={focusedSubQuestion}
                            setFocusedSubQuestion={setFocusedSubQuestion}
                        />
                    </DndContext>

                    <div className="flex justify-between mt-10">
                        <button
                            disabled={isPublished}
                            className={`sexy-button h-[38px] w-[180px] ${disabledStyle}`}
                            onClick={addQuestion}
                        >
                            Add Question
                        </button>
                        <button
                            className={`less-sexy-button h-[38px] w-[180px] ${disabledStyle}`}
                            disabled={isPublished || loading}
                            onClick={uploadQuestions}
                        >
                            {created ? 'Update PDF' : 'Generate PDF'}
                        </button>
                    </div>
                    <div className="flex flex-col items-center mt-4">
                        {loading && (
                            <div role="status">
                                <svg
                                    aria-hidden="true"
                                    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 mb-4"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                    />
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                        {error && <div className="text-red-500 text-sm my-2">{error}</div>}
                    </div>
                </div>
                {teacherPdfUrl && (
                    <div className="w-full h-svh overflow-hidden flex flex-col items-center mt-10">
                        <iframe
                            key={iframeKey}
                            name="fixedName"
                            src={`${apiUrl}/api/gen/${teacherPdfUrl}`}
                            title="PDF Viewer"
                            frameBorder="0"
                            className="w-3/4 h-full"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default TPset;

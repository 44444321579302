import React, {useState, useCallback, useRef} from 'react';

function DragAndDropUpload({onFileChange}) {
    const [file, setFile] = useState(null);
    const [dragging, setDragging] = useState(false);
    const [error, setError] = useState(null);
    const inputRef = useRef(null);

    const handleFileChange = (selectedFile) => {
        setError(null);
        if (selectedFile) {
            if (selectedFile.type === "text/csv" || selectedFile.name.endsWith('.csv')) {
                setFile(selectedFile);
                onFileChange(selectedFile);
                // console.log("CSV file selected:", selectedFile);
            } else {
                setError("Please select a CSV file");
                console.error("Please select a CSV file");
            }
        } else {
            setError("No file selected");
            console.error("No file selected");
        }
    };

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setError(null);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileChange(e.dataTransfer.files[0]);
        } else {
            setError("No file dropped");
        }
        setDragging(false);
    }, []);

    const handleDrag = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragIn = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    }, []);

    const handleDragOut = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    }, []);

    return (
        <div className="mb-4">
            <label
                onDrop={handleDrop}
                onDragOver={handleDrag}
                onDragEnter={handleDragIn}
                onDragLeave={handleDragOut}
                className={`flex justify-center w-full h-16 px-4 transition bg-white border-2 ${
                    dragging ? 'border-blue-300' : error ? 'border-red-500' : 'border-gray-500'
                } border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-900 focus:outline-none`}
            >
                <span className="flex items-center space-x-2">
                    <span className="text-gray-600 italic text-sm">
                        Drag & drop CSV file here or <span className="underline">Browse</span>
                    </span>
                </span>
                <input
                    ref={inputRef}
                    type="file"
                    accept=".csv"
                    name="file_upload"
                    className="hidden"
                    onChange={(e) => {
                        if (e.target && e.target.files && e.target.files[0]) {
                            handleFileChange(e.target.files[0]);
                        } else {
                            setError("No file selected");
                        }
                    }}
                />
            </label>
            {file && <div className="mt-2 text-sm text-gray-600">File: {file.name}</div>}
            {error && <div className="mt-2 text-sm text-red-500">{error}</div>}
        </div>
    );
}

export default DragAndDropUpload;
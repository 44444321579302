import React from 'react';
import TEssay from './TEssay';
import TPset from './TPset';

const TConstructor = ({
  assignment_id,
  updateTrigger,
  setUpdateTrigger,
  pullQuestions,
  setPullQuestions,
  is_essay,
  is_published,
  criteria,
  setCriteria,
  instructions,
  setInstructions,
  setEdited,
  edited,
  showRubric,
  setShowRubric,
  setSolRecent,
  solRecent,
  essayGradingInstructions,
  setEssayGradingInstructions
}) => {
  if (is_essay) {
    return (
      <TEssay
        assignment_id={assignment_id}
        updateTrigger={updateTrigger}
        setUpdateTrigger={setUpdateTrigger}
        criteria={criteria}
        setCriteria={setCriteria}
        instructions={instructions}
        setInstructions={setInstructions}
        isPublished={is_published}
        pullQuestions={pullQuestions}
        setPullQuestions={setPullQuestions}
        gradingInstructions={essayGradingInstructions}
        setGradingInstructions={setEssayGradingInstructions}
        showRubric={showRubric}
        setShowRubric={setShowRubric}
      ></TEssay>
    );
  }
  return (
    <TPset
      assignment_id={assignment_id}
      updateTrigger={updateTrigger}
      setUpdateTrigger={setUpdateTrigger}
      pullQuestions={pullQuestions}
      setPullQuestions={setPullQuestions}
      isPublished={is_published}
      setEdited={setEdited}
      edited={edited}
      setSolRecent={setSolRecent}
      solRecent={solRecent}
    ></TPset>
  );
};

export default TConstructor;

import React, {useState} from 'react';
import {gradeApi} from '../api.js';

import '../App.css';

const Form = ({setPageToggle, GradeWizHeader}) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await gradeApi.post({
                path: '/api/retrieve-password/',
                body: {email: email}
            });
            if (response.data.error) {
                setError(response.data.error);
                setSuccess(false);
            } else {
                setSuccess(true);
                setError('Password reset email sent successfully.');
            }
            // console.log(response.data);
        } catch (error) {
            setError(error.response?.data?.error || 'An error occurred. Please try again.');
            setSuccess(false);
            console.error('Error retrieving password:', error);
        }
    };

    const isFormValid = email;

    const [emailFocused, setEmailFocused] = useState(false);

    return (
        <div className="h-full flex flex-col justify-between">
            <div>
                <div className="mb-10">
                    <GradeWizHeader/>
                </div>
                <h2 className="custom-title-sm text-black mb-5 font-bold">Forgot Password</h2>
                <form className="" onSubmit={handleSubmit}>
                    <div
                        className={`flex items-center input-entry-box ${success ? 'border-green-500' : error !== '' ? 'border-red-500' : ''}`}
                        onFocus={() => {
                            setEmailFocused(true);
                            setError('');
                            setSuccess(false);
                        }}
                        onBlur={() => setEmailFocused(false)}
                    >
                        <div className="size-9 mr-0.5 flex items-center justify-center">
                            <img
                                src="/assets/authentication/email.png"
                                className={emailFocused || error !== '' || success ? 'size-5' : 'size-5 opacity-30'}
                            />
                        </div>
                        <input
                            type="email"
                            value={email}
                            className="actual-input mr-2.5"
                            placeholder="Email"
                            onChange={e => setEmail(e.target.value.toLowerCase())}
                        />
                    </div>
                    {error !== '' && (
                        <div
                            className={`you-dirty-dog ${success ? 'text-green-500' : 'text-red-500'} text-base`}
                        >
                            {error}
                        </div>
                    )}
                    <button
                        type="submit"
                        disabled={!isFormValid}
                        className={`mt-3.5 bg-black text-white text-sm font-medium py-2 px-4 rounded-full transition-all duration-300 w-full ${isFormValid ? 'bg-black text-white' : 'text-black border border-border cursor-not-allowed'}`}
                    >
                        Reset Password
                    </button>
                </form>
            </div>
            <div>
                <div className="mb-6">
                    <hr className="border-t border-gray-100"></hr>
                </div>
                <button
                    className="login-button custom-functional block mx-auto text-gray-800 text-smallish"
                    onClick={() => setPageToggle('sign-in')}
                >
                    Sign In
                </button>
            </div>
        </div>
    );
};

export default Form;

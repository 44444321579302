import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gradeApi } from '../api.js';
import { useAuth } from '../auth/AuthContext';
import ConfirmationModal from './ConfirmationModal';

import '../App.css';

function TopBar({
    assignment_in,
    submissions_in,
    setUpdateTrigger,
    essayCriteria,
    essayInstructions,
    setPullQuestions,
    edited,
    essayGradingInstructions,
    showRubric,
    topErrorMessage,
    setTopErrorMessage
}) {
    const [assignment, setAssignment] = useState(null);
    const [submissions, setSubmissions] = useState([]);
    const [assignmentPublished, setAssignmentPublished] = useState(false);
    const [gradesPublished, setGradesPublished] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();
    const [dueDateError, setDueDateError] = useState(false);
    const [dueTimeError, setDueTimeError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dateFocused, setDateFocused] = useState(false);
    const [timeFocused, setTimeFocused] = useState(false);
    const [dueDate, setDueDate] = useState('');
    const [dueTime, setDueTime] = useState('');
    const [isExportGradesModalOpen, setIsExportGradesModalOpen] = useState(false);
    const [exportScoreType, setExportScoreType] = useState('percentage');

    const stopDueDateError = () => {
        if (dueDateError) {
            setDueDateError(false);
            setTopErrorMessage('');
        }
    };

    const stopDueTimeError = () => {
        if (dueTimeError) {
            setDueTimeError(false);
            setTopErrorMessage('');
        }
    };

    useEffect(() => {
        if (assignment_in) {
            console.log(assignment_in)
            setAssignment(assignment_in);
            setAssignmentPublished(assignment_in.is_assignment_published);
            setGradesPublished(assignment_in.is_grades_published);
            setDueDate(assignment_in.due_date);
            setDueTime(assignment_in.due_time);
        }
        if (submissions_in) {
            setSubmissions(submissions_in);
        }
    }, [assignment_in, submissions_in]);

    const handlePublishClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleConfirmPublish = () => {
        publishAssignment();
        setIsModalOpen(false);
    };

    const uploadQuestions = async () => {
        setLoading(true);
        const payload = {
            exam_paper: [
                {
                    description: 'Grade this essay',
                    subQuestions: essayCriteria.map((criterion, index) => ({
                        questionType: 'freeform',
                        question: criterion.criterion,
                        questionSizing: 50,
                        solution: '',
                        rubricItems: criterion.rubricItems.map((rubricItem, subIndex) => ({
                            descriptor: rubricItem.descriptor,
                            value: rubricItem.value
                        })),
                        mcqOptions: undefined
                    }))
                }
            ],
            instructions: essayInstructions,
            model_instructions: essayGradingInstructions,
            show_rubric: showRubric
        };

        // console.log(payload)
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment_in.id}/constructor/`,
                body: payload,
                config: { headers: { Authorization: `Bearer ${user.session_token}` } }
            });
            setLoading(false);
            setUpdateTrigger(prev => !prev);
            setPullQuestions(true);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setTopErrorMessage(error.response.data || 'An error occurred');
            } else if (error.request) {
                // The request was made but no response was received
                setTopErrorMessage('No response received from server');
            } else {
                // Something happened in setting up the request that triggered an Error
                setTopErrorMessage(error.message || 'An error occurred');
            }
            console.error('Error in uploadQuestions:', error);
        }
    };

    const publishAssignment = async () => {
        if (assignmentPublished) {
            setTopErrorMessage("You can't unpublish the assignment once it is published.");
            return;
        }

        if (!assignment.teacher_url && !assignment.is_essay) {
            setTopErrorMessage('Please generate the assignment PDF before publishing the assignment.');
            return;
        }

        if (assignment.is_essay) {
            if (essayCriteria.length === 0) {
                setTopErrorMessage('Essay must have least one criterion.');
                return;
            }
            for (let cri of essayCriteria) {
                if (cri.rubricItems.length === 0) {
                    setTopErrorMessage('Every criterion must have at least one rubric item.');
                    return;
                }

                if (cri.criterion.trim() === '') {
                    setTopErrorMessage('Essay criteria cannot be empty.');
                    return;
                }
                for (let rubricItem of cri.rubricItems) {
                    if (
                        String(rubricItem.value).trim() === '' ||
                        String(rubricItem.descriptor).trim() === ''
                    ) {
                        setTopErrorMessage('Each rubric item must have a descriptor and point value.');
                        return;
                    }
                }
            }
        } else {
            if (!assignment.teacher_url) {
                setTopErrorMessage('Please generate the assignment PDF before publishing the assignment.');
                return;
            }

            if (edited) {
                setTopErrorMessage(
                    'You have made changes to the assignment without regenerating your PDF. Please regenerate PDF.'
                );
                return;
            }
        }
        //

        if (assignment.is_essay) {
            await uploadQuestions();
        }

        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment.id}/publish-assignment/`,
                body: {
                    is_assignment_published: true
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log(assignment);
            setAssignmentPublished(true);
            setTopErrorMessage('');
            setUpdateTrigger(prev => !prev);
            if (!assignment.is_essay) {
                // Send the generate-latex request without waiting for the response
                gradeApi
                    .post({
                        path: `/api/assignment/${assignment.id}/generate-latex/`,
                        body: {}, // Empty body
                        config: {
                            headers: {
                                Authorization: `Bearer ${user.session_token}`
                            }
                        }
                    })
                    .catch(error => {
                        console.error('Error generating LaTeX:', error);
                    });
            }
        } catch (error) {
            // console.log(error);
            if (error.response && error.response.data && error.response.data.error) {
                // Display the specific error message from the backend
                setTopErrorMessage(error.response.data.error);
            } else {
                // Fallback to a generic error message if no specific message is available
                setTopErrorMessage('Failed to publish assignment. Please try again.');
            }
            console.error('Error publishing assignment:', error);
        }
    };

    const publishGrades = async () => {
        if (!assignment.is_assignment_published) {
            setTopErrorMessage('You must publish this assignment before you can publish grades.');
            return;
        }

        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment.id}/publish-grades/`,
                body: {
                    is_grades_published: !gradesPublished
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setGradesPublished(!gradesPublished);
            setTopErrorMessage('');
        } catch (error) {
            setTopErrorMessage(error.response.data.error);
            return;
        }
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment.id}/store-agg-feedback/`,
                body: {
                    is_grades_published: !gradesPublished
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
        } catch (error) {
            setTopErrorMessage('Failed to generate aggregate feedback');
            return;
        }
    };

    const downloadCSV = () => {
        setIsExportGradesModalOpen(true);
    };

    const confirmExportGrades = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment.id}/export/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    },
                    params: {
                        score_type: exportScoreType
                    },
                    responseType: 'blob'
                }
            });

            const blob = new Blob([response.data], { type: 'text/csv' });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = `assignment_grades_${assignment.id}.csv`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            setIsExportGradesModalOpen(false);
        } catch (error) {
            setTopErrorMessage('Failed to export grades. Please try again.');
        }
    };

    const updateDueDate = async () => {
        if (dueDateError) {
            setDueDateError(false);
            setTopErrorMessage('');
        }

        try {
            await gradeApi.patch({
                path: `/api/assignment/${assignment.id}/due-date`,
                body: {
                    due_date: dueDate
                },
                headers: {
                    Authorization: `Bearer ${user.session_token}`
                }
            });
            setTopErrorMessage('');
            // console.log('Due date updated successfully');
        } catch (error) {
            setTopErrorMessage(
                error.response?.data?.error || 'Failed to update due date. Please try again.'
            );
        }
    };

    const updateDueTime = async () => {
        try {
            await gradeApi.patch({
                path: `/api/assignment/${assignment.id}/due-time`,
                body: {
                    due_time: dueTime
                },
                headers: {
                    Authorization: `Bearer ${user.session_token}`
                }
            });
            setTopErrorMessage('');
            // console.log('Due time updated successfully');
        } catch (error) {
            setTopErrorMessage(
                error.response?.data?.message || 'Failed to update due time. Please try again.'
            );
        }
    };

    const handleDueDateChange = e => {
        setDueDate(e.target.value);
    };

    const handleDueTimeChange = e => {
        setDueTime(e.target.value);
    };

    const handleDueDateBlur = () => {
        updateDueDate();
        setDateFocused(false);
    };

    const handleDueTimeBlur = () => {
        updateDueTime();
        setTimeFocused(false);
    };

    return (
        <div
            id="HomeTeacher"
            className="flex flex-row fixed z-40 bg-white topbar-padding left-side-bar-size right-0 px-6"
        >
            <div id="Main-Content-container" className="flex flex-col space-y-4 w-full">
                <div className="flex flex-row items-center space-x-3 h-topbar-font-box-size">
                    <Link to="/tclasses">
                        <div
                            className="topbar-leftmost-icon-overaching-size flex items-center justify-center bg-gray-50 rounded-full">
                            <img src="/assets/sidebar/menu-home.png" className="size-5" alt="Card View" />
                        </div>
                    </Link>
                    <Link to={`/tclasses`}>
                        <div className="text-black topbar-text-size font-medium">My Classes</div>
                    </Link>
                    <Link to={`/tclasses`}>
                        <div className="size-6 flex items-center justify-center">
                            <button>
                                <img src="/assets/back_arrow.png" className="size-6" />
                            </button>
                        </div>
                    </Link>
                    <Link to={`/tassignments/${assignment_in.course_id}`}>
                        <div className="text-black topbar-text-size font-medium">
                            {assignment_in.course.name.length > 40 ?
                                assignment_in.course.name.substring(0, 37) + "..." :
                                assignment_in.course.name}
                        </div>
                    </Link>
                    <Link to={`/tassignments/${assignment_in.course_id}`}>
                        <div className="size-6 flex items-center justify-center">
                            <button>
                                <img src="/assets/back_arrow.png" className="size-6" />
                            </button>
                        </div>
                    </Link>
                    <div className="text-black topbar-text-size font-medium">
                        {assignment ? assignment.title.length > 40 ?
                            assignment.title.substring(0, 37) + "..." :
                            assignment.title : ""}
                    </div>
                    {assignment_in.is_group_task ? <div>(Group) </div> : <div></div>}
                </div>
                <div className="FIRSTGRID flex">
                    <div className="w-[58px]"></div>
                    <div className="flex flex-grow justify-between items-center">
                        <div className="flex flex-grow space-x-4 items-center">
                            <div
                                className={`relative flex items-center font-normal bg-white input-entry-box text-black w-[200px] ${dueDateError ? 'border-red-500' : ''}`}
                                onFocus={() => {
                                    setDateFocused(true);
                                    stopDueDateError();
                                }}
                                onBlur={handleDueDateBlur}
                            >
                                <div className={`flex items-center w-full ${dueDateError ? 'border-red-500' : ''}`}>
                                    {!dateFocused && dueDate === '' && (
                                        <div
                                            className="absolute ml-2.5 pointer-events-none text-gray-400 bg-white w-36"
                                            style={{ userSelect: 'none' }}
                                        >
                                            Due Date
                                        </div>
                                    )}
                                    <input
                                        type="date"
                                        value={dueDate}
                                        className={`actual-input ml-2.5 mr-2.5 w-full ${dueDateError ? 'text-red-500' : ''}`}
                                        onChange={handleDueDateChange}
                                        onFocus={() => stopDueDateError()}
                                    />
                                </div>
                                <div
                                    className="size-6 flex items-center justify-center pointer-events-none absolute right-0 bg-white mr-2">
                                    <img
                                        src={dueDateError ? '/assets/red-calendar.png' : '/assets/calendar.png'}
                                        className="size-5"
                                        alt="Calendar icon"
                                    />
                                </div>
                            </div>
                            <div
                                className={`relative flex items-center font-normal bg-white input-entry-box text-black w-[200px] ${dueTimeError ? 'border-red-500' : ''}`}
                                onFocus={() => {
                                    setTimeFocused(true);
                                    stopDueTimeError();
                                }}
                                onBlur={handleDueTimeBlur}
                            >
                                <div className={`flex items-center w-full ${dueTimeError ? 'border-red-500' : ''}`}>
                                    {!timeFocused && dueTime === '' && (
                                        <div
                                            className="absolute ml-2.5 pointer-events-none text-gray-400 bg-white w-36"
                                            style={{ userSelect: 'none' }}
                                        >
                                            Due Time
                                        </div>
                                    )}
                                    <input
                                        type="time"
                                        value={dueTime}
                                        className={`actual-input ml-2.5 mr-2.5 w-full ${dueTimeError ? 'text-red-500' : ''}`}
                                        onChange={handleDueTimeChange}
                                        onFocus={() => stopDueTimeError()}
                                    />
                                </div>
                                <div
                                    className="size-6 flex items-center justify-center pointer-events-none absolute right-0 bg-white mr-2">
                                    <img
                                        src={dueTimeError ? '/assets/red-clock.png' : '/assets/clock.png'}
                                        className="size-5"
                                        alt="Clock icon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row space-x-9 text-smallish items-center h-10">
                            <div>
                                <button
                                    className={`px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow ${assignmentPublished ? 'bg-gray-300 cursor-not-allowed' : 'hover:bg-gray-300'}`}
                                    disabled={assignmentPublished}
                                    onClick={handlePublishClick}
                                >
                                    Publish Assignment
                                </button>
                            </div>
                            {gradesPublished && (
                                <button
                                    className="px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                                    onClick={downloadCSV}
                                >
                                    Download Grades
                                </button>
                            )}
                            <div>
                                <button
                                    className="px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow"
                                    onClick={publishGrades}
                                >
                                    {gradesPublished ? 'Unpublish Grades' : 'Publish Grades'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {topErrorMessage && <div className="text-red-500 text-sm my-2">{topErrorMessage}</div>}
            </div>

            <ConfirmationModal
                isOpen={isExportGradesModalOpen}
                message="Are you sure you want to export the grades for this assignment? Download will start automatically."
                onClose={() => setIsExportGradesModalOpen(false)}
                onConfirm={confirmExportGrades}
            >
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Select score type:</label>
                    <select
                        value={exportScoreType}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        onChange={e => setExportScoreType(e.target.value)}
                    >
                        <option value="percentage">Percentage Scores</option>
                        <option value="raw">Raw Scores</option>
                    </select>
                </div>
            </ConfirmationModal>
            <ConfirmationModal
                isOpen={isModalOpen}
                message="Once you publish the assignment, students will be able to view it and you won't be able to unpublish it."
                onClose={handleCloseModal}
                onConfirm={handleConfirmPublish}
            />
        </div>
    );
}

export default TopBar;

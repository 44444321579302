import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gradeApi } from '../../api';
import '../../App.css';
import { useAuth } from '../../auth/AuthContext';
import TDetails from '../../components/Teacher/TDetails';

function TDetailsPage() {
  let { assignment_id, submission_id } = useParams();
  const [submissionIds, setSubmissionIds] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const getSubmissions = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/assignment/${assignment_id}/submissions/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setSubmissionIds(response.data.submissions.map(sub => sub.id)); // Ensure the correct property
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch submissions. Please try again.');
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubmissions();
  }, [assignment_id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (submissionIds) {
    return (
      <TDetails
        assignment_id={assignment_id}
        submission_id={submission_id}
        submissionIds={submissionIds}
      />
    );
  }

  return <div>No submissions found.</div>; // Fallback if no submissions found
}

export default TDetailsPage;

import React from "react";

const StudentList = ({ studentStats, assignmentNames }) => {
    const assignmentIds = Object.keys(assignmentNames);

    return (
        <div className="flex-grow container mx-auto pt-8 px-6 bg-white-50">
            <div className="flex flex-col w-full mb-5">
                <h2 className="text-xl font-bold mb-1">Student List</h2>
                <p className="text-sm mb-4">View and analyze the performance of each student across all assignments.</p>
            </div>
            <div className="sexy-border rounded-xl mb-4 w-full overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-orange-200">
                        <tr>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                                Student ID
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                                Student Name
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                                Total Score
                            </th>
                            {assignmentIds.map((id) => (
                                <th key={id} scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                                    {assignmentNames[id]}
                                </th>
                            ))}

                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {Object.keys(studentStats).length > 0 ? (
                            Object.entries(studentStats).map(([userId, data]) => (
                                <tr key={userId} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{userId}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{data.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">
                                        {data.totalScore.toFixed(2)}
                                    </td>
                                    {assignmentIds.map((id) => (
                                        <td key={id} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {data.grades[id] !== undefined ? data.grades[id].toFixed(2) : '0.00'}
                                        </td>
                                    ))}

                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={assignmentIds.length + 3}
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default StudentList;
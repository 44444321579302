import React from 'react';

const BigStepperLine = () => {
  return (
    <div className="constructor-steps-width constructor-vertical-margin relative flex justify-center items-center">
      <div className="w-[0.04px] h-full bg-gray-300 absolute"></div>
    </div>
  );
};

export default BigStepperLine;
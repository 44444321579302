import React, {useState} from 'react';
import {gradeApi} from '../api.js';

import '../App.css';

const Form = ({setPageToggle, backToggle, GradeWizHeader}) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await gradeApi.post({
                path: '/retrieve-password/',
                body: {email: email}
            });
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setError('Password reset email sent successfully.');
            }
            // console.log(response.data);
        } catch (error) {
            setError('An error occurred. Please try again.');
            console.error('Error retrieving password:', error);
        }
    };

    const isFormValid = email;

    const [emailFocused, setEmailFocused] = useState(false);

    return (
        <div className="h-full flex flex-col justify-between">
            <div>
                <div className="mb-10">
                    <GradeWizHeader/>
                </div>
                <h2 className="custom-title-sm text-black mb-5 font-bold">Terms and Conditions</h2>
                <div
                    className="h-[250px] overflow-y-hidden hover:overflow-y-scroll space-y-4 text-smallish hover:w-[310px]">
                    <p>
                        By creating a GradeWiz account, you are choosing to voluntarily participate in
                        extracurricular assessments facilitated by GradeWiz Corporation. Our GradeWiz software
                        independently evaluates responses, distinct from academic grading systems.
                    </p>

                    <p>
                        Please be aware that this account registration is independent of any academic
                        institution, including your University. Your involvement in GradeWiz assessments will
                        not affect your academic performance or standing.
                    </p>

                    <p>
                        Your decision to register for a GradeWiz account is entirely optional. You retain the
                        right to opt out of assessments at any time without facing any negative repercussions
                        regarding your academic status or relationship with your University.
                    </p>

                    <p>
                        By creating a GradeWiz account, you acknowledge and grant consent to GradeWiz
                        Corporation for the collection, utilization, and secure storage of your personal and
                        assessment-related data in accordance with our Privacy Policy.
                    </p>

                    <p>
                        Rest assured, your data will be treated with confidentiality, securely stored, and not
                        disclosed without your explicit consent, unless mandated by law.
                    </p>

                    <p>
                        GradeWiz account holders are entitled to access, review, rectify, or request deletion of
                        their personal data maintained by GradeWiz Corporation. Such requests can be submitted
                        directly through our website's Contact Section, ensuring compliance with relevant data
                        protection regulations.
                    </p>
                </div>
            </div>
            <button
                className="mt-3.5 bg-black text-white text-sm font-medium py-2 px-4 rounded-full transition-all duration-300 w-full"
                onClick={() => setPageToggle(backToggle)}
            >
                Back
            </button>

            <div>
                <div className="mb-6">
                    <hr className="border-t border-gray-100"></hr>
                </div>
                <button
                    className="login-button custom-functional block mx-auto text-gray-800 text-smallish"
                    onClick={() => setPageToggle('sign-in')}
                >
                    Sign In
                </button>
            </div>
        </div>
    );
};

export default Form;

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAuth} from '../../auth/AuthContext.js';

import {gradeApi} from '../../api.js';

import 'katex/dist/katex.min.css';

function TEssay({
                    assignment_id,
                    updateTrigger,
                    setUpdateTrigger,
                    isPublished,
                    criteria,
                    setCriteria,
                    instructions,
                    setInstructions,
                    gradingInstructions,
                    setGradingInstructions,
                    pullQuestions,
                    setPullQuestions,
                    showRubric,
                    setShowRubric
                }) {
    const [editableInstructions, setEditableInstructions] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [subUrl, setSubUrl] = useState('');
    const [created, setCreated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [publishError, setPublishError] = useState('');
    const [iframeKey, setIframeKey] = useState(Date.now());
    const {user} = useAuth();
    const instructionsRef = useRef(null);

    const [lastSaved, setLastSaved] = useState(null);
    const [showSavedPopup, setShowSavedPopup] = useState(false);
    const saveTimeoutRef = useRef(null);
    const lastSavedStateRef = useRef(null);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const disabledStyle = isPublished ? '!opacity-50 !cursor-not-allowed pointer-events-none' : '';
    const [editableGradingInstructions, setEditableGradingInstructions] = useState('');
    const [isGradingInstructionsVisible, setIsGradingInstructionsVisible] = useState(false);

    const getQuestions = async () => {
        // console.log('pulling from backend');
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/questions-frontend/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const questionsFromDB = response.data;

            // Assuming the first item in questions array is our essay question
            const essayQuestion = questionsFromDB.questions[0];

            // Map the subQuestions to criteria
            const mappedCriteria = essayQuestion.subQuestions.map(subQ => ({
                criterion: subQ.question,
                rubricItems: subQ.rubricItems.map(item => ({
                    descriptor: item.descriptor,
                    value: item.value
                }))
            }));

            setCriteria(mappedCriteria);
            setInstructions(questionsFromDB.instructions);
            setEditableInstructions(questionsFromDB.instructions);

            if (questionsFromDB.questions.length !== 0) {
                setCreated(true);
            }

            setShowRubric(questionsFromDB.showRubric);

            setSubUrl(questionsFromDB.teacher_url);
            saveProgress({
                criteria: mappedCriteria,
                instructions: questionsFromDB.instructions,
                editableInstructions: questionsFromDB.instructions,
                created: questionsFromDB.questions.length !== 0,
                subUrl: questionsFromDB.teacher_url,
                showRubric: questionsFromDB.showRubric
            });
        } catch (error) {
            console.log(error);
            setCreated(false);
        }
    };
    useEffect(() => {
        if (pullQuestions === true) {
            getQuestions();
            setPullQuestions(false);
        }
    }, [pullQuestions]);

    const loadDraft = useCallback(async () => {
        // console.log('loaddraft called');
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/load-draft/`,
                config: {headers: {Authorization: `Bearer ${user.session_token}`}}
            });
            if (response.data.draft_data) {
                const {
                    criteria,
                    instructions,
                    editableInstructions,
                    created,
                    subUrl,
                    gradingInstructions,
                    showRubric
                } = JSON.parse(response.data.draft_data);
                if (criteria && criteria.length > 0) {
                    setCriteria(criteria);
                }
                if (instructions) {
                    setInstructions(instructions);
                }
                if (editableInstructions) {
                    setEditableInstructions(editableInstructions);
                } else {
                    const defaultInstructions = `Write an essay. To receive full credit, your essay must be neatly written and logically organized. 
Academic integrity is expected of all students at all times, whether in the presence or absence of the faculty. Understanding this, I declare I shall not give, use, or receive unauthorized aid in this assignment.`;
                    setInstructions(defaultInstructions);
                    setEditableInstructions(defaultInstructions);
                }
                if (gradingInstructions) {
                    setGradingInstructions(gradingInstructions);
                    setEditableGradingInstructions(gradingInstructions);
                    setShowRubric(showRubric);
                } else {
                    const defaultGradingInstructions =
                        "Grade the student's essay carefully. Pay attention to their arguments, clarity of expression, and adherence to the essay prompt. Assign points based on the rubric provided.";
                    setGradingInstructions(defaultGradingInstructions);
                    setEditableGradingInstructions(defaultGradingInstructions);
                }
                if (created !== undefined) {
                    setCreated(created);
                }
                if (subUrl) {
                    setSubUrl(subUrl);
                }
                if (showRubric !== undefined) {
                    setShowRubric(showRubric);
                }
            } else {
                const defaultInstructions = `Write an essay. To receive full credit, your essay must be neatly written and logically organized. 
Academic integrity is expected of all students at all times, whether in the presence or absence of the faculty. Understanding this, I declare I shall not give, use, or receive unauthorized aid in this assignment.`;
                setInstructions(defaultInstructions);
                setEditableInstructions(defaultInstructions);
                const defaultGradingInstructions =
                    "Grade the student's essay carefully. Pay attention to their arguments, clarity of expression, and adherence to the essay prompt. Assign points based on the rubric provided.";
                setGradingInstructions(defaultGradingInstructions);
                setEditableGradingInstructions(defaultGradingInstructions);
            }
            setIsDataLoaded(true);
        } catch (error) {
            console.error('Error loading draft:', error);
            setIsDataLoaded(true);
        }
    }, [assignment_id, user.session_token]);

    useEffect(() => {
        if (isPublished) {
            getQuestions();
        } else {
            loadDraft();
        }
    }, []);

    const saveProgress = useCallback(
        (additionalData = {}) => {
            if (isPublished) {
                return;
            }
            // console.log('saving progress');
            // console.log(criteria);
            const dataToSave = {
                criteria,
                instructions,
                editableInstructions,
                created,
                subUrl,
                gradingInstructions,
                showRubric,
                ...additionalData
            };
            gradeApi
                .post({
                    path: `/api/assignment/${assignment_id}/save-draft/`,
                    body: dataToSave,
                    headers: {Authorization: `Bearer ${user.session_token}`}
                })
                .then(() => {
                    setLastSaved(new Date());
                    setShowSavedPopup(true);
                    setTimeout(() => setShowSavedPopup(false), 2000);
                    lastSavedStateRef.current = JSON.stringify(dataToSave);
                })
                .catch(error => console.error('Error saving draft:', error));
        },
        [
            criteria,
            instructions,
            editableInstructions,
            created,
            subUrl,
            gradingInstructions,
            showRubric,
            assignment_id,
            user.session_token
        ]
    );

    const triggerSave = useCallback(() => {
        if (!saveTimeoutRef.current) {
            saveTimeoutRef.current = setTimeout(() => {
                saveProgress();
                saveTimeoutRef.current = null;
            }, 5000);
        }
    }, [saveProgress]);

    useEffect(() => {
        return () => {
            if (saveTimeoutRef.current) {
                clearTimeout(saveTimeoutRef.current);
            }
        };
    }, []);

    const addCriterion = () => {
        setCriteria([...criteria, {criterion: '', rubricItems: [{descriptor: '', value: ''}]}]);
        triggerSave();
    };

    const handleCriterionChange = (index, event) => {
        const updatedCriteria = [...criteria];
        updatedCriteria[index].criterion = event.target.value;
        setCriteria(updatedCriteria);
        triggerSave();
    };

    const addRubricItem = index => {
        const updatedCriteria = [...criteria];
        updatedCriteria[index].rubricItems.push({descriptor: '', value: ''});
        setCriteria(updatedCriteria);
        triggerSave();
    };

    const handleRubricDescriptorChange = (criterionIndex, rubricIndex, event) => {
        const updatedCriteria = [...criteria];
        updatedCriteria[criterionIndex].rubricItems[rubricIndex].descriptor = event.target.value;
        setCriteria(updatedCriteria);
        triggerSave();
    };

    const handleRubricValueChange = (criterionIndex, rubricIndex, event) => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
            const updatedCriteria = [...criteria];
            updatedCriteria[criterionIndex].rubricItems[rubricIndex].value = event.target.value;
            setCriteria(updatedCriteria);
            triggerSave();
        }
    };

    const deleteCriterion = index => {
        const updatedCriteria = [...criteria];
        updatedCriteria.splice(index, 1);
        setCriteria(updatedCriteria);
        triggerSave();
    };

    const deleteRubricItem = (criterionIndex, rubricIndex) => {
        const updatedCriteria = [...criteria];
        updatedCriteria[criterionIndex].rubricItems.splice(rubricIndex, 1);
        setCriteria(updatedCriteria);
        triggerSave();
    };

    const handleInstructionsEdit = () => {
        setIsEditing(true);
    };

    const handleInstructionsChange = event => {
        setEditableInstructions(event.target.value);
        triggerSave();
    };

    const handleInstructionsSave = async () => {
        try {
            setInstructions(editableInstructions);
            setIsEditing(false);
            saveProgress({instructions: editableInstructions});
        } catch (error) {
            console.error('Error updating instructions:', error);
        }
    };

    const handleGradingInstructionsEdit = () => {
        setIsEditing(true);
    };

    const handleGradingInstructionsChange = event => {
        setEditableGradingInstructions(event.target.value);
    };

    const handleGradingInstructionsSave = () => {
        setGradingInstructions(editableGradingInstructions);
        setIsEditing(false);
        saveProgress({gradingInstructions: editableGradingInstructions});
    };

    const handleShowRubricChange = event => {
        setShowRubric(event.target.checked);
        saveProgress({showRubric: event.target.checked});
    };

    const textAreaRefs = useRef({});

    useEffect(() => {
        Object.keys(textAreaRefs.current).forEach(criterionIndex => {
            const criterionTextArea = textAreaRefs.current[criterionIndex]?.criterion;
            if (criterionTextArea) {
                criterionTextArea.style.height = 'auto';
                criterionTextArea.style.height = criterionTextArea.scrollHeight + 'px';
            }

            const rubricItemRefs = textAreaRefs.current[criterionIndex]?.rubricItems;
            if (rubricItemRefs) {
                rubricItemRefs.forEach(itemRefs => {
                    if (itemRefs.descriptor) {
                        itemRefs.descriptor.style.height = 'auto';
                        itemRefs.descriptor.style.height = itemRefs.descriptor.scrollHeight + 'px';
                    }
                });
            }
        });
    }, [criteria]);

    return (
        <div className={`flex-grow container mx-auto pt-8 pl-4 pr-4 ${disabledStyle}`}>
            <div className="flex flex-col w-full mb-5">
                <h2 className="text-xl font-bold mb-1">Step 1: Build Assignment</h2>
                <p className="text-sm mb-4">Enter the grading criteria and rubric items for the essay.</p>
            </div>
            <div className={`sexy-border rounded-xl mb-4 w-full text-sm ${isEditing ? 'shadow-lg' : ''}`}>
                <h3 className="text-lg font-semibold mb-2 p-4">Essay Instructions</h3>
                {isEditing ? (
                    <div className="p-4">
            <textarea
                ref={instructionsRef}
                value={editableInstructions}
                className={`border rounded px-2 py-1 mb-4 w-full h-40 ${disabledStyle}`}
                style={{height: 'auto'}}
                disabled={isPublished}
                onChange={handleInstructionsChange}
            />
                        <button
                            className={`less-sexy-button w-40 h-9 ${disabledStyle}`}
                            disabled={isPublished}
                            onClick={handleInstructionsSave}
                        >
                            Save
                        </button>
                    </div>
                ) : (
                    <div className="p-4">
                        <p className="mb-4">{instructions}</p>
                        <button
                            className={`less-sexy-button w-40 h-9 ${disabledStyle}`}
                            disabled={isPublished}
                            onClick={handleInstructionsEdit}
                        >
                            Edit
                        </button>
                    </div>
                )}
            </div>
            <div className="flex flex-col w-full mb-5 mt-8">
                <button
                    className="flex items-center text-sm text-gray-500 mb-2"
                    onClick={() => setIsGradingInstructionsVisible(!isGradingInstructionsVisible)}
                >
                    <img
                        src="/assets/back_arrow.png"
                        className={`size-5 opacity-60 transform ${isGradingInstructionsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                        alt="Toggle grading instructions"
                    />
                    Grading Instructions (optional)
                </button>
            </div>
            {isGradingInstructionsVisible && (
                <div
                    className={`sexy-border rounded-xl mb-4 w-full text-sm h-40 ${isEditing ? 'shadow-lg' : ''}`}
                >
                    {isEditing ? (
                        <div className="w-full h-full flex flex-col scroll-auto">
              <textarea
                  disabled={isPublished}
                  value={editableGradingInstructions}
                  className={`actual-input p-4 ${disabledStyle} text-xs text-gray-500`}
                  style={{height: 'auto'}}
                  onChange={handleGradingInstructionsChange}
              />
                            <div className="border-t">
                                <div className="p-2">
                                    <button
                                        disabled={isPublished}
                                        className="less-sexy-button w-40 h-9 opacity-50"
                                        onClick={handleGradingInstructionsSave}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full h-full flex flex-col">
                            <p className="overflow-y-scroll w-full h-full p-4 text-xs text-gray-500 whitespace-pre-wrap">
                                {gradingInstructions}
                            </p>
                            <div className="border-t">
                                <div className="p-2">
                                    <button
                                        disabled={isPublished}
                                        className="less-sexy-button w-40 h-9 opacity-50"
                                        onClick={handleGradingInstructionsEdit}
                                    >
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className="sexy-border rounded-xl p-4 mb-4">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-semibold">Grading Criteria</h3>

                    <div className="flex items-center">
                        <div className="relative inline-block mr-2">
                            <input
                                type="checkbox"
                                id="showRubricCheckbox"
                                checked={showRubric}
                                disabled={isPublished}
                                className="sr-only peer"
                                onChange={handleShowRubricChange}
                            />

                            <label
                                htmlFor="showRubricCheckbox"
                                className={`${
                                    isPublished ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                                } w-5 h-5 bg-white border border-gray-300 rounded-3xl peer-checked:bg-orange-200 peer-checked:border-orange-300 flex items-center justify-center transition-colors duration-200 ease-in-out`}
                            ></label>
                        </div>

                        <label htmlFor="showRubricCheckbox" className="text-sm font-medium text-gray-700">
                            Make Rubric Visible
                        </label>
                    </div>
                </div>
                <div className="min-w-full bg-white">
                    <div className="flex">
                        <div className="w-1/2 px-4 py-2 font-bold">Criterion</div>
                        <div className="w-1/2 px-4 py-2 font-bold">Rubric Items</div>
                    </div>
                    {criteria.map((criterion, criterionIndex) => (
                        <React.Fragment key={criterionIndex}>
                            <div key={criterionIndex} className="flex border-b">
                                <div className="w-1/2 px-4 py-2">
                                    <div className="flex items-start mb-2">
                    <textarea
                        ref={el => {
                            if (!textAreaRefs.current[criterionIndex]) {
                                textAreaRefs.current[criterionIndex] = {rubricItems: []};
                            }
                            textAreaRefs.current[criterionIndex].criterion = el;
                        }}
                        value={criterion.criterion}
                        placeholder="Enter criterion"
                        className={`essay-rubric-input-box ${disabledStyle}`}
                        disabled={isPublished}
                        rows="1"
                        onChange={event => handleCriterionChange(criterionIndex, event)}
                    />
                                        <button
                                            className={`flex-shrink-0 ${disabledStyle}`}
                                            disabled={isPublished}
                                            onClick={() => deleteCriterion(criterionIndex)}
                                        >
                                            <img src="/assets/trash.png" className="size-4" alt="Delete criterion"/>
                                        </button>
                                    </div>
                                </div>
                                <div className="w-1/2 px-4 py-2">
                                    {criterion.rubricItems.map((rubricItem, rubricIndex) => (
                                        <div key={rubricIndex} className="flex mb-2 space-x-2">
                      <textarea
                          ref={el => {
                              if (!textAreaRefs.current[criterionIndex]) {
                                  textAreaRefs.current[criterionIndex] = {rubricItems: []};
                              }
                              if (!textAreaRefs.current[criterionIndex].rubricItems[rubricIndex]) {
                                  textAreaRefs.current[criterionIndex].rubricItems[rubricIndex] = {};
                              }
                              textAreaRefs.current[criterionIndex].rubricItems[rubricIndex].descriptor =
                                  el;
                          }}
                          type="text"
                          value={rubricItem.descriptor}
                          placeholder="Descriptor"
                          className={`essay-rubric-input-box min-h-10 ${disabledStyle}`}
                          disabled={isPublished}
                          onChange={event =>
                              handleRubricDescriptorChange(criterionIndex, rubricIndex, event)
                          }
                      />
                                            <div className="flex-shrink-0 w-20 mr-2">
                                                <input
                                                    type="number"
                                                    value={rubricItem.value}
                                                    placeholder="Pts"
                                                    className={`essay-rubric-input-box min-h-10 ${disabledStyle}`}
                                                    disabled={isPublished}
                                                    onChange={event =>
                                                        handleRubricValueChange(criterionIndex, rubricIndex, event)
                                                    }
                                                />
                                            </div>
                                            <button
                                                className={`flex-shrink-0 ${disabledStyle}`}
                                                disabled={isPublished}
                                                onClick={() => deleteRubricItem(criterionIndex, rubricIndex)}
                                            >
                                                <img src="/assets/trash.png" className="size-4"
                                                     alt="Delete rubric item"/>
                                            </button>
                                        </div>
                                    ))}
                                    <div className="flex justify-end">
                                        <button
                                            className={`less-sexy-button h-9 px-4 ${disabledStyle}`}
                                            disabled={isPublished}
                                            onClick={() => addRubricItem(criterionIndex)}
                                        >
                                            Add Rubric Item
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>

                <button
                    className={`sexy-button h-[38px] w-[180px] mt-4 ${disabledStyle}`}
                    disabled={isPublished}
                    onClick={addCriterion}
                >
                    Add Criterion
                </button>
            </div>
            {showSavedPopup && (
                <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded">
                    Progress saved
                </div>
            )}
            {lastSaved && (
                <div className="text-sm text-gray-500 mt-2">
                    Last saved: {lastSaved.toLocaleTimeString()}
                </div>
            )}
            <div className="flex flex-col items-center mt-4">
                {loading && (
                    <div role="status">
                        <svg
                            aria-hidden="true"
                            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 mb-4"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
                {error && <div className="text-red-500 text-sm my-2">{error}</div>}
            </div>
            {subUrl && (
                <div className="w-full h-svh overflow-hidden flex flex-col items-center mt-10">
                    <iframe
                        key={iframeKey}
                        name="fixedName"
                        src={`${apiUrl}/api/gen/${subUrl}`}
                        title="PDF Viewer"
                        frameBorder="0"
                        className="w-3/4 h-full"
                    />
                </div>
            )}
        </div>
    );
}

export default TEssay;

import axios from 'axios';
import React, {useEffect, useState} from 'react';

const Verifier = () => {
    const [file, setFile] = useState();
    const [array, setArray] = useState([]);
    const [row, setRow] = useState(0);
    const [isEditing, setIsEditing] = useState(false);

    const fileReader = new FileReader();

    const csvFileToArray = string => {
        const csvHeader = string.slice(0, string.indexOf('\n')).split('\t');
        const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

        const array = csvRows.map(i => {
            const values = i.split('\t');
            const obj = csvHeader.reduce((object, header, index) => {
                object[header] = values[index];
                return object;
            }, {});
            return obj;
        });

        setArray(array);
    };

    const getPic = async formula => {
        var body;
        var preamble = `\\usepackage{amsmath}
  \\usepackage{amsfonts}
  \\usepackage{amssymb}`;
        var fontsize = 17;
        var textcolor = '000000';

        formula = formula.replace(/%/g, '%25');
        formula = formula.replace(/&/g, '%26');

        preamble = preamble.replace(/%/g, '%25');
        preamble = preamble.replace(/&/g, '%26');

        body = 'formula=' + formula;
        body = body + '&fsize=' + fontsize + 'px';
        body = body + '&fcolor=' + textcolor;
        body = body + '&mode=0';
        body = body + '&out=1&remhost=quicklatex.com';
        if (preamble !== '') {
            body = body + '&preamble=' + preamble;
        }
        body = body + '&rnd=' + Math.random() * 100;

        const response = await axios.post(`http://127.0.0.1:8000/latex`, body);
        // console.log(response.data);
        const data = response.data;
        return data.split('\n')[1].split(' ')[0];
    };

    const Parser = ({arrayRow}) => {
        const [question, setQuestion] = useState('');
        const [answer, setAnswer] = useState('');

        useEffect(() => {
            getPic(arrayRow.Question).then(url => {
                setQuestion(url);
            });
            getPic(arrayRow.Answer).then(url => {
                setAnswer(url);
            });
        }, [arrayRow]);

        return (
            <div className="mx-auto p-6 mt-4 flex flex-col">
                <h2 className="custom-title-sm text-black mb-4">{arrayRow.ClassName}</h2>
                <h2 className="text-black mb-4">Question:</h2>
                {question ? <img alt="Question" src={question}/> : ''}
                <h2 className="text-black mb-4">Answer:</h2>
                {answer ? <img alt="Answer" src={answer}/> : ''}
                <div className="m-4">{arrayRow.Gradescope}</div>
            </div>
        );
    };

    const Editer = ({arrayRow}) => {
        const [localRow, setLocalRow] = useState(arrayRow);

        const handleInputChange = e => {
            const {name, value} = e.target;
            // Update local state
            setLocalRow(prev => ({...prev, [name]: value}));
        };

        const handleUpdateRow = (index, updatedRow) => {
            const newArray = [...array];
            newArray[index] = updatedRow;
            setArray(newArray);
        };

        const saveChanges = () => {
            handleUpdateRow(row, localRow);
            setIsEditing(!isEditing);
        };

        return (
            <div className="mx-auto p-6 mt-4 flex flex-col">
                <h2 className="custom-title-sm text-black mb-4">{localRow.ClassName}</h2>
                <h2 className="text-black mb-4">Question:</h2>
                <textarea
                    name="Question"
                    value={localRow.Question}
                    className="mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    rows="4"
                    onChange={handleInputChange}
                />

                <h2 className="text-black mb-4">Answer:</h2>
                <textarea
                    name="Answer"
                    value={localRow.Answer}
                    className="mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    rows="4"
                    onChange={handleInputChange}
                />
                <div className="m-4">{localRow.Gradescope}</div>
                <button
                    className="px-4 py-3 rounded custom-functional block mt-2 w-full border-2 border-black hover:bg-gray-300"
                    onClick={saveChanges}
                >
                    Save Changes
                </button>
            </div>
        );
    };

    const handleOnSubmit = e => {
        e.preventDefault();

        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(file);
            // console.log('test');
        }
    };

    const handleOnChange = e => {
        setFile(e.target.files[0]);
    };

    const nextRow = () => {
        setRow(Math.min(row + 1, array.length - 1));
    };

    const prevRow = () => {
        setRow(Math.max(row - 1, 0));
    };

    const edit = () => {
        setIsEditing(!isEditing);
    };

    function exportToCsv(filename, rows) {
        var processRow = function (row) {
            var finalVal = '';
            for (var j = 0; j < row.length; j++) {
                // console.log(row[j]);
                var innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                }
                var result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0) {
                    result = '"' + result + '"';
                }
                if (j > 0) {
                    finalVal += ',';
                }
                finalVal += result;
            }
            return finalVal + '\n';
        };

        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(Object.values(rows[i]));
        }

        var blob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement('a');
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    const handleDownload = () => {
        exportToCsv('dataset.csv', array);
    };

    const isFormValid = file ? true : false;

    return (
        <div className="max-w-[480px] mx-auto p-6 mt-4">
            <h2 className="custom-title-sm text-black mb-4">Data Verifier</h2>
            <form className="space-y-4" onSubmit={handleOnSubmit}>
                <div className="flex flex-col">
                    <label className="text-black custom-functional-med">Upload File:</label>
                    <input
                        type={'file'}
                        id={'csvFileInput'}
                        accept={['.csv', '.tsv']}
                        onChange={handleOnChange}
                    />
                </div>
                <button
                    type="submit"
                    disabled={!isFormValid}
                    className={`px-4 py-3 rounded custom-functional transition-all duration-300 w-full ${
                        isFormValid
                            ? 'bg-black text-white'
                            : 'text-black border border-border cursor-not-allowed'
                    }`}
                >
                    Upload CSV
                </button>
            </form>

            {array.length !== 0 ? (
                isEditing ? (
                    <Editer arrayRow={array[row]}/>
                ) : (
                    <Parser arrayRow={array[row]}/>
                )
            ) : (
                ''
            )}

            {array.length !== 0 ? (
                <div className="flex flex-row">
                    <button
                        className="px-4 py-3 mx-4 rounded custom-functional block mt-2 w-full border-2 border-black hover:bg-gray-300"
                        onClick={prevRow}
                    >
                        Prev Row
                    </button>
                    <button
                        className="px-4 py-3 mx-4 rounded custom-functional block mt-2 w-full border-2 border-black hover:bg-gray-300"
                        onClick={nextRow}
                    >
                        Next Row
                    </button>
                </div>
            ) : (
                ''
            )}

            {array.length !== 0 && !isEditing ? (
                <button
                    className="px-4 py-3 rounded custom-functional block mt-2 w-full border-2 border-black hover:bg-gray-300"
                    onClick={edit}
                >
                    {isEditing ? 'Stop Editing' : 'Edit Info'}
                </button>
            ) : (
                ''
            )}

            {array.length !== 0 ? (
                <button
                    className="px-4 py-3 rounded custom-functional block mt-2 w-full border-2 border-black hover:bg-gray-300"
                    onClick={handleDownload}
                >
                    Download CSV
                </button>
            ) : (
                ''
            )}
        </div>
    );
};

export default Verifier;

import React from 'react';
import {
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {TQuestion} from './TQuestion.js';

export const TQuestionColumn = ({
                                    examPaper,
                                    isPublished,
                                    deleteQuestion,
                                    handleDescriptionChange,
                                    handleImageUpload,
                                    deleteImage,
                                    handleImageSizeChange,
                                    handlePreviewClick,
                                    previews,
                                    handleQuestionSizingChange,
                                    deleteRubricItem,
                                    deleteSubQuestion,
                                    handleQuestionTypeChange,
                                    handleQuestionChange,
                                    handleRubricDescriptorChange,
                                    handleRubricValueChange,
                                    addRubricItem,
                                    handleWeightChange,
                                    handleMCQOptionChange,
                                    handleMCQCorrectAnswer,
                                    deleteMCQOption,
                                    addMCQOption,
                                    handleSolutionChange,
                                    addSubQuestion,
                                    focusedQuestion,
                                    setFocusedQuestion,
                                    setSubQuestions,
                                    triggerSave,
                                    saveProgress,
                                    focusedSubQuestion,
                                    setFocusedSubQuestion,
                                }) => {
    return (
        <div><SortableContext items={examPaper} strategy={verticalListSortingStrategy}>{examPaper.map((question) => (
            <TQuestion
                key={question.id}
                examPaper={examPaper}
                question={question}
                isPublished={isPublished}
                deleteQuestion={deleteQuestion}
                handleDescriptionChange={handleDescriptionChange}
                handleImageUpload={handleImageUpload}
                deleteImage={deleteImage}
                handleImageSizeChange={handleImageSizeChange}
                handlePreviewClick={handlePreviewClick}
                previews={previews}
                handleQuestionSizingChange={handleQuestionSizingChange}
                deleteRubricItem={deleteRubricItem}
                deleteSubQuestion={deleteSubQuestion}
                handleQuestionTypeChange={handleQuestionTypeChange}
                handleQuestionChange={handleQuestionChange}
                handleRubricDescriptorChange={handleRubricDescriptorChange}
                handleRubricValueChange={handleRubricValueChange}
                addRubricItem={addRubricItem}
                handleWeightChange={handleWeightChange}
                handleMCQOptionChange={handleMCQOptionChange}
                handleMCQCorrectAnswer={handleMCQCorrectAnswer}
                deleteMCQOption={deleteMCQOption}
                addMCQOption={addMCQOption}
                handleSolutionChange={handleSolutionChange}
                addSubQuestion={addSubQuestion}
                focusedQuestion={focusedQuestion}
                setFocusedQuestion={setFocusedQuestion}
                setSubQuestions={setSubQuestions}
                triggerSave={triggerSave}
                saveProgress={saveProgress}
                focusedSubQuestion={focusedSubQuestion}
                setFocusedSubQuestion={setFocusedSubQuestion}
            />
        ))}</SortableContext></div>);
};
import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {apiUrl, gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext.js';
import DragAndDropUpload from '../DragDropAnswer';
import DueTimeDisplay from '../DueTimeDisplay.js';
import AddStudentsToSubmission from './AddStudentsToSubmission';

function SUnpublishedPset({assignment, class_id}) {
    const {user} = useAuth();
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [submissionData, setSubmissionData] = useState({});
    const [file, setFile] = useState();
    const [feedbackItems, setFeedbackItems] = useState({});
    const [update, setUpdate] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isPolling, setIsPolling] = useState(false);
    const pollingIntervalRef = useRef(null);
    const [isGroupAdmin, setIsGroupAdmin] = useState(false);
    const [showHelp, setShowHelp] = useState(false);

    const startPolling = submissionId => {
        if (isPolling) {
            return; // Prevent multiple polling intervals
        }
        setIsPolling(true);
        pollingIntervalRef.current = setInterval(() => {
            checkGradingStatus(submissionId);
        }, 5000);
    };

    const stopPolling = () => {
        setIsPolling(false);
        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
        }
    };

    const checkGradingStatus = async submissionId => {
        try {
            const response = await gradeApi.get({
                path: `/api/submission/${submissionId}/grading-status/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            if (response.data.is_grading_done) {
                stopPolling();
                setMessage('Grading completed. Refreshing submission data...');
                setShowHelp(false);
                getAssignmentSubmission();
            }
        } catch (error) {
            console.error('Error checking grading status:', error);
            stopPolling();
            setMessage('Error checking grading status. Please refresh the page.');
            setShowHelp(false);
        }
    };

    const getAssignmentSubmission = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/course/${class_id}/assignment/${assignment.id}/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const data = response.data;
            if (data.is_grade_in_progres) {
                setMessage('Assignment is being graded... Please wait.');
                setShowHelp(false);
                setIsButtonDisabled(true);
                if (data.id) {
                    startPolling(data.id);
                }
            } else {
                setIsButtonDisabled(false);
                setMessage('');
                setShowHelp(false);
            }
            if (response.status !== 200) {
                setMessage('Failed to verify session. Please log out and back in.');
                setIsButtonDisabled(true);
                setShowHelp(false);
            }
            setSubmitted(data.submitted);
            setSubmissionData(data.submitted ? data : {});
            setIsGroupAdmin(data.is_group_admin);
            setFeedbackItems(data.submitted ? data.feedback : {});
        } catch (error) {
            setIsButtonDisabled(false);
            setMessage(
                error.response?.data?.error || 'An error occurred while fetching submission data.'
            );
            setShowHelp(false);
        }
    };

    const submitOrResubmit = async isResubmission => {
        const formData = new FormData();
        formData.append('file_data', file);
        formData.append('user_id', user.user.id);

        try {
            setMessage(
                isResubmission
                    ? 'Resubmission in progress... please wait'
                    : 'Submission in progress... please wait'
            );
            setIsButtonDisabled(true);
            setShowHelp(false);

            const delete_response = await gradeApi.delete({
                path: `/api/assignment/${assignment.id}/delete-submissions/`,
                headers: {
                    Authorization: `Bearer ${user.session_token}`
                },
                body: {
                    user_id: user.user.id
                }
            });

            const response = await gradeApi.post({
                path: `/api/assignment/${assignment.id}/submissions/`,
                body: formData,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            });

            if (response.status !== 200 || delete_response.status !== 200) {
                throw new Error('Error while submitting. Please try again.');
            }

            const submissionId = response.data.id;
            setMessage(
                isResubmission
                    ? 'Assignment resubmitted successfully! Please wait, grading...'
                    : 'Assignment submitted successfully! Please wait, grading...'
            );
            setShowHelp(false);

            const gradingResponse = await gradeApi.get({
                path: `/api/assignment/${assignment.id}/ai-grade/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });

            if (gradingResponse.status === 200) {
                setMessage('Assignment is being graded... Please wait.');
                setShowHelp(false);
                startPolling(submissionId);
            } else {
                throw new Error('Grading failed. Please try again later.');
            }
        } catch (error) {
            console.error(
                isResubmission ? 'Error resubmitting assignment:' : 'Error submitting assignment:',
                error
            );
            setIsButtonDisabled(false);
            setMessage(error.response?.data?.error || 'An unknown error occurred');
            setShowHelp(true);

            try {
                await gradeApi.delete({
                    path: `/api/assignment/${assignment.id}/delete-submissions/`,
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    },
                    body: {
                        user_id: user.user.id
                    }
                });
            } catch (deleteError) {
                console.error('Error deleting submission:', deleteError);
            }
        }
    };

    const handleLeaveGroup = async () => {
        try {
            await gradeApi.post({
                path: `${url}/api/assignment/${assignment.id}/leave-group/`,
                body: {},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setMessage('You have left the group. Refreshing...');
            setShowHelp(false);

            getAssignmentSubmission();
        } catch (error) {
            setMessage('Error leaving group. Please try again.');
            setShowHelp(false);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        await submitOrResubmit(false);
    };

    const handleResubmit = async e => {
        e.preventDefault();
        await submitOrResubmit(true);
    };

    const handleCancel = async () => {
        try {
            await gradeApi.delete({
                path: `/api/assignment/${assignment.id}/delete-submissions/`,
                body: {
                    user_id: user.user.id
                },
                headers: {
                    Authorization: `Bearer ${user.session_token}`
                }
            });
            setIsButtonDisabled(false);
            setMessage('Submission cancelled.');
            setShowHelp(false);

            stopPolling();
        } catch (error) {
            console.error('Error cancelling submission:', error);
            setMessage('Error cancelling submission. Please try again.');
            setShowHelp(false);
        }
    };

    useEffect(() => {
        getAssignmentSubmission();
        return () => {
            stopPolling();
        };
    }, []);

    return (
        <div className="flex flex-col bg-white px-5 py-7">
            <div className="flex flex-col space-y-4 w-full">
                <div className="flex flex-row items-center space-x-2 mb-4">
                    <Link to="/sclasses">
                        <div className="size-10 flex items-center justify-center bg-gray-50 rounded-full">
                            <img src="/assets/sidebar/menu-home.png" className="size-5" alt="Home"/>
                        </div>
                    </Link>
                    <Link to="/sclasses">
                        <div className="text-black text-lg font-medium ml-4">My Classes</div>
                    </Link>
                    <Link to="/sclasses">
                        <div className="size-6 flex items-center justify-center">
                            <img src="/assets/back_arrow.png" className="size-6"/>
                        </div>
                    </Link>
                    <Link to={`/sassignments/${class_id}`}>
                        <div className="text-black text-lg font-medium ml-4">{assignment.course.name}</div>
                    </Link>
                    <Link to={`/sassignments/${class_id}`}>
                        <div className="size-6 flex items-center justify-center">
                            <img src="/assets/back_arrow.png" className="size-6"/>
                        </div>
                    </Link>
                    <div className="text-black text-lg font-medium ml-4">{assignment.title}</div>
                </div>

                <Link
                    to="https://drive.google.com/file/d/1y_2woeaCCz9R42i368KK4cPkylqsdw03/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-orange-300 font-bold mb-4 hover:underline"
                >
                    How do I submit?
                </Link>

                <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6">
                    {assignment.description && <p className="text-gray-600 mb-4">{assignment.description}</p>}
                    <p className="text-gray-600 mb-4">
                        Due:{' '}
                        <DueTimeDisplay
                            dueTime={assignment.due_time}
                            dueDate={assignment.due_date}
                            timezone={assignment.timezone}
                        />
                    </p>

                    {assignment.is_group_task && submitted ? (
                        isGroupAdmin ? (
                            <>
                                <div className="mb-6">
                                    <DragAndDropUpload onFileChange={setFile}/>
                                </div>
                                <form className="flex items-center space-x-4" onSubmit={handleResubmit}>
                                    <button
                                        type="submit"
                                        disabled={isButtonDisabled}
                                        className={`px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative ${
                                            isButtonDisabled
                                                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                : 'bg-orange-200 hover:bg-orange-300 text-black'
                                        }`}
                                    >
                                        {isButtonDisabled ? (
                                            <>
                                                <span className="opacity-0">Resubmit</span>
                                                <div className="absolute inset-0 flex items-center justify-center">
                                                    <div
                                                        className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                                                </div>
                                            </>
                                        ) : (
                                            'Resubmit'
                                        )}
                                    </button>
                                    {isButtonDisabled && (
                                        <button
                                            type="button"
                                            className="px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                    )}
                                </form>
                                {submissionData.is_grade_in_progres === false && !isButtonDisabled && (
                                    <p className="text-orange-400 font-medium mt-4">
                                        Your assignment has been graded, but you can still resubmit! You will see your
                                        grade once professor publishes grades.
                                    </p>
                                )}
                            </>
                        ) : (
                            <div className="mb-6">
                                <p className="text-gray-600">
                                    You are not the group admin. Only the group admin can submit assignments.
                                </p>
                                <button
                                    className="mt-4 px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                                    onClick={handleLeaveGroup}
                                >
                                    Leave Group
                                </button>
                            </div>
                        )
                    ) : (
                        <>
                            <div className="mb-6">
                                <DragAndDropUpload onFileChange={setFile}/>
                            </div>
                            <form className="flex items-center space-x-4" onSubmit={handleSubmit}>
                                <button
                                    type="submit"
                                    disabled={isButtonDisabled}
                                    className={`px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative ${
                                        isButtonDisabled
                                            ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                            : 'bg-orange-200 hover:bg-orange-300 text-black'
                                    }`}
                                >
                                    {isButtonDisabled ? (
                                        <>
                                            <span className="opacity-0">Submit</span>
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <div
                                                    className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                                            </div>
                                        </>
                                    ) : (
                                        'Submit'
                                    )}
                                </button>
                                {isButtonDisabled && (
                                    <button
                                        type="button"
                                        className="px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                )}
                            </form>
                            {submitted && submissionData.is_grade_in_progres === false && !isButtonDisabled && (
                                <p className="text-orange-400 font-medium mt-4">
                                    Your assignment has been graded, but you can still resubmit! You will see your
                                    grade once professor publishes grades.
                                </p>
                            )}
                        </>
                    )}

                    {message && <p className="text-red-500 mt-4">{message}</p>}
                    {showHelp && (
                        <Link
                            to="https://drive.google.com/file/d/1gWYt4atdKHK_-Dt8i5Ab6VaC7EWdxcg7/view?usp=sharing"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-orange-300 font-bold mb-4 hover:underline"
                        >
                            Refer to Submission Error documentation
                        </Link>
                    )}
                </div>

                {submissionData?.file?.filename && assignment.is_group_task && isGroupAdmin && (
                    <AddStudentsToSubmission aid={assignment.id}/>
                )}
            </div>

            <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6 mt-8">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">Assignment PDF</h3>
                {assignment.url ? (
                    <>
                        <p className="text-gray-600 mb-4">
                            To submit your work, download the assignment PDF below, fill it out and submit it as
                            PDF. You can take pictures of your work or use a scanner, but please make sure all 4
                            corners of the page are visible.
                        </p>
                        <iframe
                            id={update}
                            src={`${apiUrl}/api/gen/${assignment.url}`}
                            title="PDF Viewer"
                            className="w-full h-96 mt-4 mb-4 border border-gray-300 rounded-lg"
                        />
                    </>
                ) : (
                    <p className="text-gray-600">Your Professor has not created your assignment yet.</p>
                )}
            </div>

            {submissionData?.file?.filename && (
                <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6 mt-8">
                    <h3 className="text-xl font-semibold text-gray-800 mb-4">Your Submission:</h3>
                    <iframe
                        src={`${apiUrl}/api/uploads/${submissionData.file.filename}`}
                        title="PDF Viewer"
                        className="w-full h-96 border border-gray-300 rounded-lg"
                    />
                </div>
            )}
        </div>
    );
}

export default SUnpublishedPset;

import React, { useEffect, useState } from 'react';
import { gradeApi } from '../../api';
import { useAuth } from '../../auth/AuthContext';

const AddStudentsToSubmission = ({ aid }) => {
  const [studentEmail, setStudentEmail] = useState('');
  const [groupStudents, setGroupStudents] = useState([]);
  const [error, setError] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    fetchGroupStudents();
  }, [aid]);

  const fetchGroupStudents = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/assignment/get_students/${aid}/student/${user.user.id}`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setGroupStudents(response.data);
    } catch (error) {
      console.error('Error fetching group students:', error);
      setError('Failed to fetch existing students.');
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (studentEmail.trim() === '') {
      setError('Please enter at least one email address.');
      return;
    }

    const emailArray = studentEmail
      .split(',')
      .map(email => email.trim())
      .filter(email => email !== '');
    await addStudents(emailArray);
    setStudentEmail('');
  };

  const addStudents = async emails => {
    const addStudentPromises = emails.map(async email => {
      try {
        await gradeApi.post({
          path: `/api/assignment/add_student/${aid}/`,
          body: { email: email.trim() },
          config: {
            headers: {
              Authorization: `Bearer ${user.session_token}`
            }
          }
        });
        return { email, success: true };
      } catch (error) {
        console.error(`Error adding student with email ${email}:`, error);
        return { email, success: false };
      }
    });

    const results = await Promise.all(addStudentPromises);

    const successfulAdds = results.filter(result => result.success);
    const failedAdds = results.filter(result => !result.success);

    if (successfulAdds.length > 0) {
      setError(''); // Clear any previous errors
    }

    if (failedAdds.length > 0) {
      setError(
        `Failed to add ${failedAdds.length} student(s): ${failedAdds.map(result => result.email).join(', ')}`
      );
    }
    fetchGroupStudents();
  };

  const handleRemove = async email => {
    if (email === user.user.email) {
      setError('You cannot remove yourself from the group.');
      return;
    }
    try {
      await gradeApi.post({
        path: `/api/assignment/${aid}/leave-group/`,
        body: { user_email: email },
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      fetchGroupStudents();
    } catch (error) {
      console.error('Error removing student:', error);
      setError('Failed to remove student. Please try again.');
    }
  };

  return (
    <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6 mt-8">
      <h3 className="text-xl font-semibold text-gray-800 mb-4">Group Members</h3>
      <form className="mb-4" onSubmit={handleSubmit}>
        <div className="flex items-center input-entry-box bg-white font-normal">
          <div className="size-9 mr-0.5 flex items-center justify-center">
            <img
              src="/assets/authentication/email.png"
              className="size-5 opacity-30"
              alt="Email icon"
            />
          </div>
          <input
            type="text"
            value={studentEmail}
            className="actual-input mr-2.5 flex-grow"
            placeholder="Student Email(s), comma-separated"
            onChange={e => setStudentEmail(e.target.value)}
          />
        </div>
        <div className="flex justify-end mt-4">
          <button
            type="submit"
            className="sexy-button transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow h-9 w-auto px-6 py-2 flex items-center justify-center whitespace-nowrap"
          >
            Add Students
          </button>
        </div>
      </form>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <ul className="student-list list-none">
        {groupStudents.map((student, index) => (
          <li key={index} className="student-item flex justify-between items-center border-b py-2">
            <div>
              <span className="font-medium">{student[0]}</span>
              <span className="text-gray-500 ml-2">{student[1]}</span>
            </div>
            {student[1] !== user.user.email && (
              <button className="focus:outline-none" onClick={() => handleRemove(student[1])}>
                <img
                  src="/assets/trash.png"
                  alt="Remove"
                  className="w-5 h-5 z-5 opacity-70 hover:opacity-100"
                />
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddStudentsToSubmission;

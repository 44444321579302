import React from 'react';
import { useParams } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import SAssignments from '../../components/Student/SAssignments';

const HomePage = () => {
  let { class_id } = useParams();

  return (
    <div id="Main-container" className="flex flex-row w-full min-h-screen">
      <div
        id="Left-Sidebar"
        className="sticky top-0 h-screen overflow-y-auto w-side-bar-size flex-shrink-0"
      >
        <SideBar activePage="Home" />
      </div>
      <div id="Right-content" className="flex-grow overflow-y-auto">
        <SAssignments class_id={class_id} />
      </div>
    </div>
  );
};

export default HomePage;

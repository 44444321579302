import React from 'react';

export const ViewSelectIcon = ({ cardView, toggleCardView }) => {
  return (
    cardView ? <CardViewSymbol toggleCardView={toggleCardView} /> : <TableViewSymbol toggleCardView={toggleCardView} />
  );
};

// Fixed prop name for consistency and reliability
export const CardViewSymbol = ({ toggleCardView }) => {
  return (
    <div className="size-12 flex items-center justify-center">
      <button onClick={toggleCardView}>
        <img src="/assets/table_view.png" className="size-10" alt="Card View" />
      </button>
    </div>
  );
};

// Fixed prop name for consistency and reliability
export const TableViewSymbol = ({ toggleCardView }) => {
  return (
    <div className="size-12 flex items-center justify-center">
      <button onClick={toggleCardView}>
        <img src="/assets/card_view.png" className="size-12" alt="Table View" />
      </button>
    </div>
  );
};
import React, {useEffect, useState} from "react";
import axios from "axios";
import {url} from "../../../constants.js";
import {useAuth} from "../../../auth/AuthContext.js";
import {ChevronDown, ChevronUp} from "lucide-react";

const SubQuestionTable = ({submissions, assignment_id, questions}) => {
    const [tableData, setTableData] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    console.log(submissions);
    console.log(questions);
    const {user} = useAuth();
    useEffect(() => {
        if (questions.length > 0 && submissions.length > 0) {
            processData();
        }
    }, [questions, submissions, assignment_id]);

    const getSubQuestionLetter = (index) => {
        return String.fromCharCode(97 + index); // 97 is the ASCII code for 'a'
    };

    const processData = () => {
        const processedData = questions.flatMap((question, qIndex) =>
            question.subQuestions.map((subQ, subIndex) => ({
                questionNumber: qIndex + 1,
                questionName: question.description,
                subQuestionLetter: getSubQuestionLetter(subIndex),
                subQuestionID: subQ.id,
                subQuestionName: subQ.question,
                points: Math.max(...subQ.rubricItems.map(item => item.value)),
                feedback: subQ.feedback,
                mean: 0,
            }))
        );
        calculateAverages(processedData);
    };

    const calculateAverages = (data) => {
        const scoresBySubQuestionId = {};
        console.log(submissions);
        submissions.forEach(submission => {
            console.log(submission);
            Object.values(submission.feedback).forEach(feedback => {
                const subQuestionId = feedback.assignment_subquestion_id;
                const value = feedback.rubric_items[0].value;
                if (!scoresBySubQuestionId[subQuestionId]) {
                    scoresBySubQuestionId[subQuestionId] = [];
                }
                scoresBySubQuestionId[subQuestionId].push(value);
            });
        });

        const updatedData = data.map(item => {
            const scores = scoresBySubQuestionId[item.subQuestionID] || [];
            const mean = scores.length ? scores.reduce((sum, score) => sum + score, 0) / scores.length : 0;
            return {...item, mean};
        });

        setTableData(updatedData);
    };

    const toggleExpand = (index) => {
        setExpandedRows(prev => ({...prev, [index]: !prev[index]}));
    };

    return (
        <div className="w-full">
            <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Question</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sub
                        Question
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Points</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mean</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-orange-400 uppercase tracking-wider">AI
                        Analysis ✨
                    </th>
                </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                {tableData.map((item, index) => (
                    <React.Fragment key={index}>
                        <tr className="hover:bg-gray-50">
                            <td className="px-6 py-4">
                                <div className="text-sm font-medium text-gray-900">Q{item.questionNumber}</div>
                                <div className="text-sm text-gray-500 whitespace-normal">{item.questionName}</div>
                            </td>
                            <td className="px-6 py-4">
                                <div
                                    className="text-sm text-gray-900 whitespace-normal">{item.subQuestionLetter}. {item.subQuestionName}</div>
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500">{item.points}</td>
                            <td className="px-6 py-4 text-sm text-gray-500">{item.mean.toFixed(2)}</td>
                            <td className="px-6 py-4 text-right text-sm font-medium">
                                <button
                                    onClick={() => toggleExpand(index)}
                                    className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                                >
                                    {expandedRows[index] ? <ChevronUp size={20}/> : <ChevronDown size={20}/>}
                                </button>
                            </td>
                        </tr>
                        {expandedRows[index] && (
                            <tr>
                                <td colSpan="5" className="px-6 py-4">
                                    <div className="text-sm text-gray-900 break-words">
                                        <strong>AI Analysis:</strong> {item.feedback}
                                    </div>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default SubQuestionTable;
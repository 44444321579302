import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gradeApi } from '../../api.js';
import { useAuth } from '../../auth/AuthContext.js';
import SideBar from '../../components/SideBar';
import SPublished from '../../components/Student/SPublished';
import SUnpublishedEssay from '../../components/Student/SUnpublishedEssay.js';
import SUnpublishedPset from '../../components/Student/SUnpublishedPset.js';

const SDetailsPage = () => {
  let { assignment_id } = useParams();
  let { class_id } = useParams();
  const { user } = useAuth();
  const [assignmentData, setAssignmentData] = useState(null);
  const [message, setMessage] = useState('');

  const getAssignmentDetails = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/courses/${class_id}/assignment/${assignment_id}/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setAssignmentData(response.data);
    } catch (error) {
      console.error('Error fetching assignment details:', error);
      setMessage('Error fetching assignment details. Please try again later.');
    }
  };
  useEffect(() => {
    getAssignmentDetails();
  }, []);

  if (!assignmentData) {
    return <div>loading</div>;
  }

  if (assignmentData?.is_grades_published) {
    return <SPublished assignment={assignmentData} class_id={class_id} />;
  } else {
    return (
      <div id="Main-container" className="flex flex-row w-full min-h-screen">
        <div
          id="Left-Sidebar"
          className="sticky top-0 h-screen overflow-y-auto w-side-bar-size flex-shrink-0"
        >
          <SideBar activePage="Home" />
        </div>
        <div id="Right-content" className="flex-grow overflow-y-auto">
          <div className="max-w-full">
            {assignmentData?.is_essay ? (
              <SUnpublishedEssay assignment={assignmentData} class_id={class_id} />
            ) : (
              <SUnpublishedPset assignment={assignmentData} class_id={class_id} />
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default SDetailsPage;

import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const SideBar = ({ activePage }) => {
  const [selectedTab, setSelectedTab] = useState(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const videoModalRef = useRef();
  const [isRosterImportOpen, setIsRosterImportOpen] = useState(false);

  const isActiveTab = tabName => tabName === activePage;

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleVideoModal = () => {
    setIsVideoModalOpen(true);
  };

  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
  };

  const [myBool, setmyBool] = useState(true);

  function toggleBool() {
    setmyBool(!myBool);
  }

  function MoreButton() {
    return myBool ? <MoreBefore toggleBool={toggleBool} /> : <MoreAfter toggleBool={toggleBool} />;
  }

  function MoreBefore(props) {
    return (
      <div className="flex items-center space-x-5 border border-white rounded-3xl p-2">
        <div className="size-5 flex items-center justify-center">
          <button onClick={props.toggleBool}>
            <img src="/assets/more.png" className="opacity-70 size-3" alt="More" />
          </button>
        </div>
      </div>
    );
  }

  function MoreAfter(props) {
    return (
      <div className="flex items-center space-x-5 border border-gray-250 rounded-3xl p-2">
        <div className="size-5 flex items-center justify-center">
          <button onClick={handleLogout}>
            <img src="/assets/sidebar/logout.png" className="opacity-70 size-5" alt="Logout" />
          </button>
        </div>
        <Link to="/profile">
          <div className="size-5 flex items-center justify-center">
            <img
              src="/assets/sidebar/account_settings.png"
              className="opacity-70 size-5"
              alt="Account Settings"
            />
          </div>
        </Link>
        <div className="size-5 flex items-center justify-center">
          <button onClick={props.toggleBool}>
            <img src="/assets/more.png" className="opacity-70 size-3" alt="More" />
          </button>
        </div>
      </div>
    );
  }

  const SidebarItem = ({ to, icon, label, tabName, exact, onClick }) => (
    <div
      className={`rounded transition-all duration-300 flex items-center space-x-2 p-3 cursor-pointer`}
      onClick={() => {
        setSelectedTab(tabName);
        if (onClick) {
          onClick();
        }
      }}
    >
      <div
        className={`size-9 flex items-center justify-center rounded-full transition-all duration-300 ${
          isActiveTab(tabName) ? 'bg-custom-orange' : ''
        }`}
      >
        <img src={icon} alt={label} className="size-5" />
      </div>
      <div className="text-sm">{label}</div>
    </div>
  );

  return (
    <div
      id="sidebar"
      className="w-side-bar-size h-screen pt-8 pl-5 pr-7 p-4 flex flex-col justify-between bg-gray-50"
    >
      <div id="Top-container" className="flex-col flex space-y-0">
        <Link to={user.is_teacher ? '/tclasses' : '/sclasses'}>
          <div className="logo" onClick={() => setSelectedTab('Homepage')}>
            <div className="flex items-center space-x-2 pb-3">
              <div className="size-12 flex items-center justify-center">
                <img src="/assets/logo_icon.png" alt="GradeWiz Icon" className="size-12" />
              </div>
              <span className="text-lg font-medium text-gray-950">GradeWiz</span>
            </div>
          </div>
        </Link>

        <Link to={user.is_teacher ? '/tclasses' : '/sclasses'}>
          <SidebarItem
            icon={
              isActiveTab('Home')
                ? `/assets/sidebar/menu-home-white.png`
                : `/assets/sidebar/menu-home.png`
            }
            label="My Classes"
            tabName="Home"
          />
        </Link>

        {user.is_teacher && (
          <>
            <Link to="/statistics">
              <SidebarItem
                icon={
                  isActiveTab('Statistics')
                    ? `/assets/sidebar/analytics-white.png`
                    : `/assets/sidebar/analytics.png`
                }
                label="Statistics"
                tabName="Statistics"
              />
            </Link>
            <Link to="/regrade">
              <SidebarItem
                icon={
                  isActiveTab('Regrade')
                    ? `/assets/sidebar/quote-request-white.png`
                    : `/assets/sidebar/quote-request.png`
                }
                label="Regrade Requests"
                tabName="Regrade"
              />
            </Link>
          </>
        )}

        {/*<SidebarItem*/}
        {/*    icon="/assets/sidebar/spellbook_dark.png"*/}
        {/*    label="Demo Video"*/}
        {/*    tabName="Demo Video"*/}
        {/*    onClick={handleVideoModal}*/}
        {/*/>*/}
      </div>

      <div className="bg-white rounded-full shadow-sm p-2 flex items-center justify-between space-x-3">
        <div className="flex items-center space-x-3">
          <img
            src="/assets/sidebar/Profile.png"
            alt="ProfileIcon"
            className="w-[20px] h-[20px] opacity-30"
          />
          <div>
            <div className="text-sm leading-[1]">ID: {user.user.id}</div>
            <div className="text-xxs">
              {user.user.first_name} {user.user.last_name}
            </div>
          </div>
        </div>
        <div>
          <MoreButton />
        </div>
      </div>

      {/*{isVideoModalOpen && createPortal(*/}
      {/*    <div*/}
      {/*        className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50"*/}
      {/*    >*/}
      {/*        <div className="relative mx-auto p-6 bg-white shadow-lg rounded-xl w-full max-w-[720px]"*/}
      {/*             ref={videoModalRef}>*/}
      {/*            <div className="flex flex-col space-y-4">*/}
      {/*                /!*<div className="flex flex-row justify-between">*!/*/}
      {/*                /!*    <h2 className="custom-title-sm text-black">GradeWiz Demo</h2>*!/*/}
      {/*                /!*    <button*!/*/}
      {/*                /!*        type="button"*!/*/}
      {/*                /!*        onClick={closeVideoModal}*!/*/}
      {/*                /!*        className="desktop:hover:opacity-50 transition-all duration-300"*!/*/}
      {/*                /!*    >*!/*/}
      {/*                /!*        <img src="/assets/X.png" alt="Exit" className="w-[24px] h-[24px]"/>*!/*/}
      {/*                /!*    </button>*!/*/}
      {/*                /!*</div>*!/*/}
      {/*                <video controls className="w-full">*/}
      {/*                    <source src={demoVideo} type="video/mp4"/>*/}
      {/*                    Your browser does not support the video tag.*/}
      {/*                </video>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*    </div>,*/}
      {/*    document.body*/}
      {/*)}*/}
    </div>
  );
};

export default SideBar;

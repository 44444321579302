import React from 'react';
import PropTypes from 'prop-types'; // Importing PropTypes for type-checking props

const BigStepper = ({ stepNumber, top = '0', ht = 'full' }) => {
  const topStyle = `mt-${top}`;
  const hStyle = `h-${ht}`;

  return (
    <div className="constructor-steps-width relative flex justify-center items-center">
      <div className="flex justify-center absolute top-0 z-10">
        <span className="inline-block bg-custom-orange text-white font-bold py-[7px] px-[15.5px] rounded-full mt-5">
          {stepNumber}
        </span>
      </div>
      <div className={`w-[0.04px] bg-gray-300 absolute top-0 ${topStyle} ${hStyle}`}></div>
    </div>
  );
};

export default BigStepper;
import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ReferenceLine, Label, ResponsiveContainer } from "recharts";
import { useAuth } from "../../../auth/AuthContext.js";

const ClassBar = ({ data }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const { user } = useAuth();

  let overallMean = 0;

  const transformedData = Object.entries(data).map(([id, { grades, mean, max_score, name }]) => {
    const adjustedMean = mean / max_score * 100;
    overallMean += adjustedMean;
    return {
      id,
      mean: adjustedMean,
      title: name
    };
  });

  // Calculate the final overall mean
  overallMean /= Object.keys(data).length;

  return (
    <div>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={transformedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 50,
          }}
        >
          <XAxis dataKey="title"
            interval={0}
            angle={-45}
            textAnchor="end"
            height={80}
            tick={{ fontSize: 10 }}>
          </XAxis>
          <YAxis>
            <Label value="Avg Score (%)" angle={-90} position="insideLeft" style={{ textAnchor: "middle" }} />
          </YAxis>
          <Tooltip />
          <Bar
            dataKey="mean"
            fill="#FFCC80"
            label={{ position: 'top', fill: '#666', formatter: (value) => `${value.toFixed(2)}%` }}
          />
          <ReferenceLine
            y={overallMean}
            label={{
              position: "top",
              value: `Overall Mean: ${overallMean.toFixed(2)}%`,
              fill: "grey",
            }}
            stroke="grey"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ClassBar;
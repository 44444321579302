import React, {useState, useEffect} from 'react';

const AIRegrade = ({subQuestion, onClose, onSubmit}) => {
    const [localRubricItems, setLocalRubricItems] = useState([]);

    const [regradeInstructions, setRegradeInstructions] = useState('');
    const [regradeFeedbackInstructions, setRegradeFeedbackInstructions] = useState('');

    const [isRegradeInstructionsVisible, setIsRegradeInstructionsVisible] = useState(false);
    const [isRegradeFeedbackInstructionsVisible, setIsRegradeFeedbackInstructionsVisible] = useState(false);

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    useEffect(() => {
        setLocalRubricItems(JSON.parse(JSON.stringify(subQuestion.rubricItems)));
    }, [subQuestion]);

    useEffect(() => {
        const isValid = localRubricItems.length >= 2 &&
            localRubricItems.every(item =>
                item.descriptor.trim() !== '' &&
                Number.isInteger(item.value) &&
                item.value >= 0
            );
        setIsSubmitDisabled(!isValid);
    }, [localRubricItems]);

    const handleRubricChange = (index, field, value) => {
        const newRubricItems = [...localRubricItems];
        if (field === 'value') {
            const numValue = parseInt(value);
            newRubricItems[index][field] = isNaN(numValue) ? '' : numValue;
        } else {
            newRubricItems[index][field] = value;
        }
        setLocalRubricItems(newRubricItems);
    };

    const addRubricItem = () => {
        setLocalRubricItems([...localRubricItems, {descriptor: '', value: ''}]);
    };

    const deleteRubricItem = (index) => {
        const newRubricItems = localRubricItems.filter((_, i) => i !== index);
        setLocalRubricItems(newRubricItems);
    };

    const handleSubmit = () => {
        onSubmit(localRubricItems, regradeInstructions, regradeFeedbackInstructions);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-xl shadow-lg max-w-3xl w-full max-h-[90vh] flex flex-col">
                <h2 className="text-2xl font-bold mb-4">Edit Rubric</h2>

                <div className="mb-4 p-4 bg-gray-100 rounded-lg text-sm">
                    <p>• Modifying this rubric will trigger AI regrading of this specific subquestion for all
                        students.</p>
                    <p>• Any manual grades you've given for this subquestion will be overwritten by the AI
                        regrading.</p>
                    <p>• You can modify existing rubric items, add new ones, delete items, and adjust point values as
                        needed.</p>
                    <p>• If you've noticed consistent grading issues, use the optional re-grading instructions below to
                        guide the AI in applying the rubric correctly.</p>
                </div>

                <div className="flex-grow overflow-y-auto">
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Rubric Items</h3>
                        {localRubricItems.map((item, index) => (
                            <div key={index} className="flex mb-2 items-center">
                                <input
                                    type="text"
                                    value={item.descriptor}
                                    onChange={(e) => handleRubricChange(index, 'descriptor', e.target.value)}
                                    className="sexy-border rounded px-2 py-1 flex-grow mr-2"
                                    placeholder="Descriptor"
                                />
                                <input
                                    type="number"
                                    value={item.value}
                                    onChange={(e) => handleRubricChange(index, 'value', e.target.value)}
                                    className="sexy-border rounded px-2 py-1 w-16 mr-2"
                                    placeholder="Pts"
                                    min="0"
                                    step="1"
                                />
                                <button onClick={() => deleteRubricItem(index)} className="p-1">
                                    <img src="/assets/trash.png" alt="Delete" className="w-4 h-4"/>
                                </button>
                            </div>
                        ))}
                        <button onClick={addRubricItem} className="less-sexy-button mt-2">
                            Add Rubric Item
                        </button>
                    </div>

                    <div className="flex flex-col w-full mb-5 mt-8">
                        <button
                            onClick={() => setIsRegradeInstructionsVisible(!isRegradeInstructionsVisible)}
                            className="flex items-center text-sm text-gray-500 mb-2"
                        >
                            <img
                                src="/assets/back_arrow.png"
                                className={`size-5 opacity-60 transform ${isRegradeInstructionsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                alt="Toggle grading instructions"
                            />
                            Re-Grading Instructions (optional)
                        </button>
                        {isRegradeInstructionsVisible && (
                            <textarea
                                value={regradeInstructions}
                                onChange={(e) => setRegradeInstructions(e.target.value)}
                                className="sexy-border rounded w-full h-32 p-2 text-sm text-gray-500"
                                placeholder="Enter re-grading instructions, such as common issues you found with how this question was graded, things to look out for, and any other instructions that should help GradeWiz regrade the question properly..."
                            />
                        )}
                        <button
                            onClick={() => setIsRegradeFeedbackInstructionsVisible(!isRegradeFeedbackInstructionsVisible)}
                            className="flex items-center text-sm text-gray-500 mb-2"
                        >
                            <img
                                src="/assets/back_arrow.png"
                                className={`size-5 opacity-60 transform ${isRegradeFeedbackInstructionsVisible ? 'rotate-90' : '-rotate-90'} mr-2`}
                                alt="Toggle grading instructions"
                            />
                            Re-Grading Feedback Instructions (optional)
                        </button>
                        {isRegradeFeedbackInstructionsVisible && (
                            <textarea
                                value={regradeFeedbackInstructions}
                                onChange={(e) => setRegradeFeedbackInstructions(e.target.value)}
                                className="sexy-border rounded w-full h-32 p-2 text-sm text-gray-500"
                                placeholder="Enter feedback re-grading instructions, which will influence the feedback generated by the model. This will not affect grading ..."
                            />
                        )}
                    </div>

                </div>

                <div className="flex justify-end space-x-2 mt-4">
                    <button onClick={onClose} className="less-sexy-button">
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        className={`sexy-button ${isSubmitDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isSubmitDisabled}
                    >
                        Confirm and Regrade
                    </button>
                </div>

                {isSubmitDisabled && (
                    <p className="text-red-500 text-sm mt-2">
                        Please ensure there are at least 2 rubric items, all descriptors are filled, and all point
                        values are set to whole, non-negative integers.
                    </p>
                )}
            </div>
        </div>
    );
};

export default AIRegrade;
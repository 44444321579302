import React from 'react';

const convertTo12HourFormat = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  let period = 'AM';
  let adjustedHours = hours;

  if (hours >= 12) {
    period = 'PM';
    if (hours > 12) {
      adjustedHours = hours - 12;
    }
  }
  if (hours === 0) {
    adjustedHours = 12;
  }

  return `${adjustedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
};

const DueTimeDisplay = ({ dueTime, dueDate, timezone }) => {
  const formattedTime = convertTo12HourFormat(dueTime);

  return (
    <div className="text-gray-900 text-smallish truncate">
      {formattedTime} {timezone} {dueDate}
    </div>
  );
};

export default DueTimeDisplay;
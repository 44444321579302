import React from "react";
import {Link} from "react-router-dom";

const AssignmentList = ({class_id, assignmentStats}) => {
    return (
        <div className="flex-grow container mx-auto pt-8 px-6 bg-white-50">
            <div className="flex flex-col w-full mb-5">
                <h2 className="text-xl font-bold mb-1">Assignment List</h2>
                <p className="text-sm mb-4">View and analyze the statistics for each assignment in this class.</p>
            </div>
            <div className="sexy-border rounded-xl mb-4 w-full overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-orange-200">
                    <tr>
                        <th scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                            Assignment
                        </th>
                        <th scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                            Min Points
                        </th>
                        <th scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                            Max Points
                        </th>
                        <th scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                            Mean
                        </th>
                        <th scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                            Median
                        </th>
                        <th scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">
                            Standard Deviation
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {Object.keys(assignmentStats).length > 0 ? (
                        Object.entries(assignmentStats).map(([id, data]) => (
                            <tr key={id} className="hover:bg-gray-50">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    <Link
                                        to={`/statistics/${class_id}/${id}`}
                                        className="text-blue-600 hover:text-blue-800 hover:underline"
                                    >
                                        {data.name}
                                    </Link>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data.min_score}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data.max_score}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data.mean.toFixed(2)}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data.median.toFixed(2)}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data.std.toFixed(2)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={6} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                                No data available
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AssignmentList;
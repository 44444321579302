import React, {useEffect, useState} from 'react';
import {gradeApi} from '../api.js';
import {useAuth} from '../auth/AuthContext';

import '../App.css';

const SimpleTimezoneSelector = ({timezone}) => {
    const [selectedTimezone, setSelectedTimezone] = useState(timezone || '');
    const {user} = useAuth();
    const [message, setMessage] = useState('');
    useEffect(() => {
        setSelectedTimezone(timezone || '');
    }, [timezone]);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await gradeApi.post({
                path: `/api/change-timezone/${user.user.id}/`,
                body: {timezone: selectedTimezone},
                config: {headers: {Authorization: `Bearer ${user.session_token}`}}
            });
            // console.log('Timezone updated successfully', response.data);
            setMessage('Successfully set timezone');
        } catch (error) {
            console.error('Error updating timezone:', error);
        }
    };

    return (
        <form className="grid grid-cols-2 gap-4" onSubmit={handleSubmit}>
            <div>
                <select
                    id="timezone"
                    value={selectedTimezone}
                    className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    onChange={e => setSelectedTimezone(e.target.value)}
                >
                    <option value="">Choose a timezone</option>
                    <option value="UTC">UTC</option>
                    <option value="EST">EST</option>
                    <option value="CST">CST</option>
                    <option value="PST">PST</option>
                    {/* Add more timezone options as needed */}
                </select>
            </div>
            <div className="flex items-end">
                <button
                    type="submit"
                    className="px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                >
                    Save Timezone
                </button>
            </div>
            <div>{message}</div>
        </form>
    );
};

export default SimpleTimezoneSelector;

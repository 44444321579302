import React, {useEffect, useState} from 'react';
import {gradeApi} from '../api.js';
import {useAuth} from '../auth/AuthContext';
import ConfirmationModal from './ConfirmationModal';
import SimpleTimezoneSelector from './SimpleTimezoneSelector';

import '../App.css';

const Profile = () => {
    const {user, logout} = useAuth();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [deleteInput, setDeleteInput] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [profileData, setProfileData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setIsLoading(true);
                const response = await gradeApi.get({
                    path: `/api/users/${user.user.id}/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                });
                setProfileData(response.data);
                // console.log('User data fetched:', response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setErrorMessage('Failed to load user data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, [user.user.id]);

    const handleDeleteUser = () => {
        setIsConfirmationModalOpen(true);
    };

    const confirmDeleteUser = async () => {
        if (deleteInput.toLowerCase() !== 'delete') {
            setErrorMessage("You must type 'delete' to confirm.");
            return;
        }

        try {
            const response = await gradeApi.delete({
                path: `/api/delete/${user.user.id}/`,
                headers: {Authorization: `Bearer ${user.session_token}`}
            });
            if (response.status === 200) {
                alert('User successfully deleted');
                logout();
            } else {
                setErrorMessage('Failed to delete user');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            setErrorMessage('Error deleting user');
        }
        setIsConfirmationModalOpen(false);
    };

    if (isLoading) {
        return <div>Loading user data...</div>;
    }

    if (!profileData) {
        return <div>Error: {errorMessage || 'Failed to load user data'}</div>;
    }

    return (
        <div id="Profile" className="flex flex-row p-8 justify-start">
            <div id="Main-Content-container" className="flex flex-col space-y-4 w-full">
                <div id="Overview" className="flex flex-row space-x-full w-full justify-between">
                    <span className="text-black text-2xl font-medium">My Profile</span>
                </div>
                <div className="bg-white sexy-border p-6">
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <p className="font-bold">First Name:</p>
                            <p>{profileData.first_name}</p>
                        </div>
                        <div>
                            <p className="font-bold">Last Name:</p>
                            <p>{profileData.last_name}</p>
                        </div>
                        <div>
                            <p className="font-bold">Email:</p>
                            <p>{profileData.email}</p>
                        </div>
                        <div>
                            <p className="font-bold">Position:</p>
                            <p>{profileData.is_teacher ? 'Teacher' : 'Student'}</p>
                        </div>

                        {profileData.timezone && profileData.is_teacher && (
                            <div>
                                <p className="font-bold">Timezone:</p>
                                <SimpleTimezoneSelector timezone={profileData.timezone}/>
                            </div>
                        )}
                    </div>

                    {profileData.student_courses && profileData.student_courses.length > 0 && (
                        <div className="mt-4">
                            <p className="font-bold">Enrolled Courses:</p>
                            <ul className="list-disc pl-5">
                                {profileData.student_courses.map((course, index) => (
                                    <li key={index}>{course.name}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {profileData.instructor_courses && profileData.instructor_courses.length > 0 && (
                        <div className="mt-4">
                            <p className="font-bold">Teaching Courses:</p>
                            <ul className="list-disc pl-5">
                                {profileData.instructor_courses.map((course, index) => (
                                    <li key={index}>{course.name}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <button
                        className="mt-6 px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                        onClick={handleDeleteUser}
                    >
                        Delete Account
                    </button>
                </div>
            </div>

            <ConfirmationModal
                isOpen={isConfirmationModalOpen}
                message="Type 'delete' to confirm account deletion. This action cannot be undone."
                onClose={() => setIsConfirmationModalOpen(false)}
                onConfirm={confirmDeleteUser}
            >
                <input
                    type="text"
                    value={deleteInput}
                    placeholder="Type 'delete' to confirm"
                    className="mt-2 p-2 border border-gray-300 rounded w-full"
                    onChange={e => setDeleteInput(e.target.value)}
                />
                {errorMessage && <p className="text-orange-500 mt-2">{errorMessage}</p>}
            </ConfirmationModal>
        </div>
    );
};

export default Profile;

import {Card, CardContent, CardHeader, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {gradeApi} from '../../../api.js';
import {useAuth} from '../../../auth/AuthContext.js';
import AssMeanMedian from './AssMeanMedian.js';
import SubQuesBarWMean from './SubQuesBarWMean.js';
import SubQuesPointsTable from './SubQuesPointsTable.js';

const QuestionStatistics = () => {
    const [subQuestionKeys, setSubQuestionKeys] = useState({});
    const [chartData, setChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const {user} = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const [aggregatedFeedback, setAggregatedFeedback] = useState('');
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [stdDeviation, setStdDeviation] = useState(0.0);
    const [mean, setMean] = useState(0.0);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [median, setMedian] = useState(0);

    const location = useLocation();
    const submissions = location.state?.submissions;
    const assignment_id = location.state?.assignment_id;
    const questions = location.state?.questions;
    const scoresByQuestionID = location.state?.scoresByQuestionID;
    const question_number = location.state?.question_number;
    const subquestion_number = location.state?.subquestion_number;
    const subQuestionID = location.state?.subQuestionID;

    const getAggregatedFeedback = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/subquestion/${subQuestionID}/aggregate-feedback`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setAggregatedFeedback(response.data.aggregated_feedback);
            // console.log('response.data.aggregated_feedback');
            // console.log(typeof response.data.aggregated_feedback);
            // console.log(response.data.aggregated_feedback);
        } catch (error) {
            setErrorMessage('Failed to fetch scores. Please try again.');
            setIsErrorVisible(true);
        }
    };

    const formatText = text => {
        // Replace newlines with <br> and handle bold text
        return text.replace(/\n/g, '<br />').replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    };

    useEffect(() => {
        // console.log('scoresByQuestionID: ', scoresByQuestionID);
        getAggregatedFeedback();
        get_min_max_median();
    }, [assignment_id, assignment_id, questions, scoresByQuestionID, subQuestionID]);

    const StyledCard = styled(Card)(({theme}) => ({
        margin: '30px',
        border: `1px solid ${theme.palette.divider}`
    }));

    const StyledCardHeader = styled(CardHeader)(({theme}) => ({
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    }));

    const StyledCardContent = styled(CardContent)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        padding: theme.spacing(2)
    }));
    const get_min_max_median = () => {
        const scores = scoresByQuestionID[subQuestionID];
        const sortedArr = [...scores].sort((a, b) => a - b);
        setMin(sortedArr[0]);
        setMax(sortedArr[sortedArr.length - 1]);
        const median_to_set =
            sortedArr.length % 2 === 0
                ? (sortedArr[sortedArr.length / 2 - 1] + sortedArr[sortedArr.length / 2]) / 2
                : sortedArr[Math.floor(sortedArr.length / 2)];
        setMedian(median_to_set);
        const total = scores.reduce((acc, num) => acc + num, 0);
        setMean(total / scores.length);
        const variance = scores.reduce((acc, num) => acc + Math.pow(num - mean, 2), 0) / scores.length;
        setStdDeviation(Math.sqrt(variance));
    };
    const formattedFeedback = formatText(aggregatedFeedback);
    return (
        <div>
            <SubQuesBarWMean scoresByQuestionID={scoresByQuestionID} subQuestionID={subQuestionID}/>
            <div>
                <AssMeanMedian
                    mean={mean}
                    stdDeviation={stdDeviation}
                    min={min}
                    max={max}
                    median={median}
                />
            </div>

            <SubQuesPointsTable
                scoresByQuestionID={scoresByQuestionID}
                questions={questions}
                question_number={question_number}
                subquestion_number={subquestion_number}
            />
            <StyledCard>
                <StyledCardHeader
                    title={
                        <Typography variant="h3" style={{fontSize: '16px'}}>
                            Summary
                        </Typography>
                    }
                />
                <CardContent>
                    <Typography variant="body1" style={{fontSize: '15px'}}>
                        <div dangerouslySetInnerHTML={{__html: formattedFeedback}}/>
                    </Typography>
                </CardContent>
            </StyledCard>
        </div>
    );
};

export default QuestionStatistics;

import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ReferenceLine, Label } from "recharts";

const AssFrequencyChart = ({ scoresByQuestionID }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const allScores = Object.values(scoresByQuestionID).flat();

    const frequencyMap = allScores.reduce((acc, score) => {
      acc[score] = (acc[score] || 0) + 1;
      return acc;
    }, {});

    const data = Object.keys(frequencyMap).map((score) => ({
      score: Number(score),
      frequency: frequencyMap[score],
    }));

    setChartData(data);
  }, [scoresByQuestionID]);

  const meanScore = chartData.reduce((sum, { score, frequency }) => sum + score * frequency, 0) / chartData.reduce((sum, { frequency }) => sum + frequency, 0);

  const roundedMeanScore = Math.round(meanScore);

  return (
    <div style={{ marginTop: "20px" }}>
      <BarChart
        width={1000}
        height={500}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <XAxis dataKey="score" /> */}
        <XAxis dataKey="score" domain={[0, "dataMax"]}>
          <Label value="Avg Score" offset={-2} position="insideBottom" />
        </XAxis>
        <YAxis>
          <Label value="Frequency" angle={-90} position="insideLeft" style={{ textAnchor: "middle" }} />
        </YAxis>
        <Tooltip />
        <Bar dataKey="frequency" fill="#d8d4d4" />
        <ReferenceLine
          x={roundedMeanScore}
          label={{
            position: "right",
            value: `Average: ${meanScore.toFixed(2)}`,
            fill: "red",
          }}
          stroke="red"
        />
      </BarChart>
    </div>
  );
};

export default AssFrequencyChart;

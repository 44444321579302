import React from 'react';
import Profile from '../components/Profile';
import SideBar from '../components/SideBar';

const TProfilePage = () => {
  return (
    <div className="HomePageHeader h-screen flex flex-col">
      <div id="Main-container" className="flex flex-row flex-grow overflow-hidden">
        <div id="Left-Sidebar" className="flex-shrink-0 overflow-y-auto">
          <SideBar activePage="Profile" />
        </div>
        <div id="Right-content" className="flex-grow overflow-y-auto">
          <Profile />
        </div>
      </div>
    </div>
  );
};
export default TProfilePage;

import React, {useState, useEffect} from "react";
import {useAuth} from "../auth/AuthContext";
import "../App.css";
import {useNavigate} from "react-router-dom";

const Form = ({setPageToggle, pageToggle, setBackToggle, GradeWizHeader}) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const [role, setRole] = useState("");
    const [agreedTerms, setAgreedTerms] = useState(false);
    const {user, signUp} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.isAuthenticated && user.is_teacher) {
            navigate("/tclasses");
        } else if (user.isAuthenticated && !user.is_teacher) {
            navigate("/sclasses");
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!agreedTerms) {
            setError({message: "Please accept the terms and conditions."});
            return;
        }
        if (role === "") {
            setError({message: "Please select a role."});
            return;
        }
        if (role === "teacher" && code !== "SanFranciscoPitch") {
            setError({message: "Access code not valid!"});
            return;
        }
        if (firstName.trim() === "" || lastName.trim() === "") {
            setError({message: "Please enter your first and last name"});
            return;
        }
        try {
            const value = signUp(firstName, lastName, email, password, role === "teacher");
            value.then((message) => {
                if (message.type === "success") {
                    setError({message: message.message, type: message.type});
                } else {
                    setError({message: message.error || message.message});
                }
                // console.log(message);
                // console.log(error)
            });
        } catch (error) {
            console.log(error);
        }
    };

    const isFormValid =
        firstName && lastName && email && password && (code || role === "student") && agreedTerms && role;

    const [emailFocused, setEmailFocused] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [codeFocused, setCodeFocused] = useState(false);

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="h-full flex flex-col justify-between">
            <div className="h-full">
                <div className="mb-7">
                    <GradeWizHeader/>
                </div>
                <h2 className="custom-title-sm text-black mb-5 font-bold">Create Account</h2>
                <form onSubmit={handleSubmit} className="h-[345px] flex flex-col justify-between">
                    <div>
                        <div
                            className={`flex items-center input-entry-box ${error && error.type !== "success" ? "border-red-500" : ""
                            }`}
                            onFocus={() => {
                                setError("");
                            }}>
                            <input type="text"
                                   value={firstName}
                                   onChange={(e) => setFirstName(e.target.value)}
                                   className={`actual-input ml-2.5 mr-2.5 ${error && error.type !== "success" ? "text-red-500" : ""
                                   }`}
                                   placeholder="First name"/>
                        </div>

                        <div
                            className={`flex items-center input-entry-box mt-3 ${error && error.type !== "success" ? "border-red-500" : ""
                            }`}
                            onFocus={() => {
                                setError("");
                            }}>
                            <input type="text"
                                   value={lastName}
                                   onChange={(e) => setLastName(e.target.value)}
                                   className={`actual-input ml-2.5 mr-2.5 ${error && error.type !== "success" ? "text-red-500" : ""
                                   }`}
                                   placeholder="Last name"/>
                        </div>

                        <div
                            className={`flex items-center input-entry-box mt-3 ${error && error.type !== "success" ? "border-red-500" : ""
                            }`}
                            onFocus={() => {
                                setEmailFocused(true);
                                setError("");
                            }}
                            onBlur={() => setEmailFocused(false)}>
                            <div className="size-9 mr-0.5 flex items-center justify-center">
                                <img
                                    src={
                                        error && error.type !== "success"
                                            ? "/assets/authentication/red-email.png"
                                            : "/assets/authentication/email.png"
                                    }
                                    className={
                                        emailFocused || (error && error.type !== "success")
                                            ? "size-5"
                                            : "size-5 opacity-30"
                                    }
                                />
                            </div>
                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                   className={`actual-input mr-2.5 ${error && error.type !== "success" ? "text-red-500" : ""
                                   }`}
                                   placeholder="Email (use school email)"/>
                        </div>

                        <div
                            className={`flex items-center input-entry-box mt-3 ${error && error.type !== "success" ? "border-red-500" : ""
                            }`}
                            onFocus={() => {
                                setPasswordFocused(true);
                                setError("");
                            }}
                            onBlur={() => setPasswordFocused(false)}
                        >
                            <div className="size-9 mr-0.5 flex items-center justify-center">
                                <img
                                    src={
                                        error && error.type !== "success"
                                            ? "/assets/authentication/red-password.png"
                                            : "/assets/authentication/password.png"
                                    }
                                    className={
                                        passwordFocused || (error && error.type !== "success")
                                            ? "size-5"
                                            : "size-5 opacity-30"
                                    }
                                />
                            </div>
                            <input
                                type={passwordVisible ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={`actual-input mr-2.5 ${error && error.type !== "success" ? "text-red-500" : ""
                                }`}
                                placeholder="Password"
                            />
                            {(!error || error.type === "success") && (
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="mr-3 text-gray-600 hover:text-gray-900 focus:outline-none"
                                >
                                    <img
                                        src={
                                            passwordVisible
                                                ? "/assets/authentication/show-password.png"
                                                : "/assets/authentication/hide-password.png"
                                        }
                                        alt="Toggle password visibility"
                                        className={passwordFocused ? "size-5" : "size-5 opacity-30"}
                                    />
                                </button>
                            )}
                        </div>

                        <div className="mt-4 mx-5 flex items-center justify-between">
                            <div className="text-xs">I am</div>
                            <div className="flex items-center justify-between space-x-8">
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        name="role"
                                        value="student"
                                        checked={role === "student"}
                                        onChange={(e) => setRole(e.target.value)}
                                        className="radio-style"
                                    />
                                    <span className="ml-2 text-xs">Student</span>
                                </label>
                                <div className="italic text-xs">or</div>
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        name="role"
                                        value="teacher"
                                        checked={role === "teacher"}
                                        onChange={(e) => setRole(e.target.value)}
                                        className="radio-style"
                                    />
                                    <span className="ml-2 text-xs">Teacher</span>
                                </label>
                            </div>
                        </div>

                        {role === "teacher" && (
                            <div
                                className={`flex items-center input-entry-box mt-3 ${error && error.type !== "success" ? "border-red-500" : ""
                                }`}
                                onFocus={() => {
                                    setCodeFocused(true);
                                    setError("");
                                }}
                                onBlur={() => setCodeFocused(false)}
                            >
                                <div className="size-9 mr-0.5 flex items-center justify-center">
                                    <img
                                        src={
                                            error && error.type !== "success"
                                                ? "/assets/authentication/red-code.png"
                                                : "/assets/authentication/code.png"
                                        }
                                        className={
                                            codeFocused || (error && error.type !== "success")
                                                ? "size-5 filter-red"
                                                : "size-5 opacity-30"
                                        }
                                    />
                                </div>
                                <input
                                    type="text"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    className={`actual-input mr-2.5 ${error && error.type !== "success" ? "text-red-500" : ""
                                    }`}
                                    placeholder="Access Code"
                                />
                            </div>
                        )}
                    </div>

                    <div>
                        <div className="flex items-center ml-2">
                            <input
                                type="checkbox"
                                checked={agreedTerms}
                                onChange={() => setAgreedTerms(!agreedTerms)}
                                className="mr-2 focus:ring-transparent"
                            />
                            <label htmlFor="terms" className="text-sm">
                                I agree to the{" "}
                                <button
                                    className="font-bold"
                                    onClick={() => {
                                        setBackToggle(pageToggle);
                                        setPageToggle("terms");
                                    }}
                                >
                                    terms and conditions
                                </button>
                            </label>
                        </div>

                        <button
                            type="submit"
                            disabled={!isFormValid}
                            className={`mt-3.5 text-sm font-medium py-2 px-4 rounded-full transition-all duration-300 w-full ${isFormValid
                                ? "bg-black text-white"
                                : "text-black border border-border cursor-not-allowed"
                            } ${error && error.type !== "success" ? "border-red-500" : ""}`}
                        >
                            Create Account
                        </button>

                        {error && error.type !== "success" && (
                            <div className="text-sm mt-2 text-red-500">{error.message}</div>
                        )}
                        {error && error.type === "success" && (
                            <div className="text-sm mt-2 text-orange-300">{error.message}</div>
                        )}
                    </div>
                </form>
            </div>

            <div>
                <div className="mb-3">
                    <hr className="border-t border-gray-100"/>
                </div>
                <button
                    className="login-button custom-functional block mx-auto text-gray-800 text-smallish"
                    onClick={() => setPageToggle("sign-in")}
                >
                    Sign In
                </button>
            </div>
        </div>
    );
};

export default Form;
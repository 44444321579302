import React from 'react';
import { useParams } from "react-router-dom";
import SideBar from "../../components/SideBar";
import TAssignmentStats from "../../components/Teacher/Statistics/TAssignmentStats.js";

const TAssignmentStatsPage = () => {
  let { class_id, assignment_id } = useParams();

  return (
    <div id="Main-container" className="flex flex-row w-full min-h-screen">
      <div id="Left-Sidebar" className="sticky top-0 h-screen overflow-y-auto w-side-bar-size flex-shrink-0">
        <SideBar activePage="Statistics" />
      </div>
      <div className="flex flex-grow overflow-x-hidden">
        <div id="Right-content" className="flex-grow overflow-y-auto p-8">
          <div className="max-w-7xl mx-auto">
            <TAssignmentStats class_id={class_id} assignment_id={assignment_id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TAssignmentStatsPage;
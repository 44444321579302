import React from 'react';

const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    let period = 'AM';
    let adjustedHours = hours;

    if (hours >= 12) {
        period = 'PM';
        if (hours > 12) {
            adjustedHours = hours - 12;
        }
    }
    if (hours === 0) {
        adjustedHours = 12;
    }

    return `${adjustedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
};

const formatDateTime = (datetimeString, timezone) => {
    // Split the datetime string into date and time components
    const [datePart, timePart] = datetimeString.split(' '); // Split by the space character
    const [time] = timePart.split('.'); // Remove the milliseconds and timezone part
    const [year, month, day] = datePart.split('-');

    // Format the time using the convertTo12HourFormat function
    const formattedTime = convertTo12HourFormat(time);

    // Return the formatted string
    return `${formattedTime} ${timezone} ${year}-${month}-${day}`;
};

const DueTimeDisplayTable = ({datetimeString, timezone}) => {
    // console.log(datetimeString);
    const formattedDateTime = formatDateTime(datetimeString, timezone);

    return (
        <div>
            {formattedDateTime}
        </div>
    );
};

export default DueTimeDisplayTable;
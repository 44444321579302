import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import ProtectedRoute from './auth/ProtectedRoute';
import GradeIC from './demoCSS/GradeIC';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import TProfilePage from './pages/ProfilePage';
import Reset from './pages/Reset';
import SAssignmentsPage from './pages/Student/SAssignmentsPage';
import SClassesPage from './pages/Student/SClassesPage';
import SDetailsPage from './pages/Student/SDetailsPage';
import TAssignmentsPage from './pages/Teacher/TAssignmentsPage';
import TAssignmentStatistics from './pages/Teacher/TAssignmentStatsPage';
import TClassesPage from './pages/Teacher/TClassesPage';
import TClassStatsPage from './pages/Teacher/TClassStatsPage';
import TDetailsPage from './pages/Teacher/TDetailsPage';
import TQuestionStatsPage from './pages/Teacher/TQuestionStatsPage';
import TRegradePage from './pages/Teacher/TRegradePage';
import TStatsOverviewPage from './pages/Teacher/TStatsOverviewPage';
import TSubmissionsPage from './pages/Teacher/TSubmissionsPage';
import Verify from './pages/Verify';
import Visualizer from './pages/Visualizer';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" exact element={<Login />} />

          <Route exact path="/tclasses" element={<ProtectedRoute />}>
            <Route exact path="/tclasses" element={<TClassesPage />} />
          </Route>

          <Route exact path="/profile" element={<ProtectedRoute />}>
            <Route exact path="/profile" element={<TProfilePage />} />
          </Route>

          <Route exact path="/regrade" element={<ProtectedRoute />}>
            <Route exact path="/regrade" element={<TRegradePage />} />
          </Route>

          <Route exact path="/tassignments/:class_id" element={<ProtectedRoute />}>
            <Route exact path="/tassignments/:class_id" element={<TAssignmentsPage />} />
          </Route>

          <Route exact path="/tsubmissions/:assignment_id" element={<ProtectedRoute />}>
            <Route exact path="/tsubmissions/:assignment_id" element={<TSubmissionsPage />} />
          </Route>

          <Route
            exact
            path="/tdetails/:assignment_id/submission/:submission_id"
            element={<ProtectedRoute />}
          >
            <Route
              exact
              path="/tdetails/:assignment_id/submission/:submission_id"
              element={<TDetailsPage />}
            />
          </Route>

          <Route exact path="/sclasses" element={<ProtectedRoute />}>
            <Route exact path="/sclasses" element={<SClassesPage />} />
          </Route>

          <Route exact path="/sassignments/:class_id" element={<ProtectedRoute />}>
            <Route exact path="/sassignments/:class_id" element={<SAssignmentsPage />} />
          </Route>

          <Route exact path="/sdetails/:class_id/:assignment_id" element={<ProtectedRoute />}>
            <Route exact path="/sdetails/:class_id/:assignment_id" element={<SDetailsPage />} />
          </Route>

          <Route exact path="/statistics" element={<ProtectedRoute />}>
            <Route exact path="/statistics" element={<TStatsOverviewPage />} />
          </Route>

          <Route exact path="/statistics/:class_id" element={<ProtectedRoute />}>
            <Route exact path="/statistics/:class_id" element={<TClassStatsPage />} />
          </Route>

          <Route exact path="/statistics/:class_id/:assignment_id" element={<ProtectedRoute />}>
            <Route
              exact
              path="/statistics/:class_id/:assignment_id"
              element={<TAssignmentStatistics />}
            />
          </Route>

          <Route exact path="/statistics/question/:assignment_id" element={<ProtectedRoute />}>
            <Route
              exact
              path="/statistics/question/:assignment_id"
              element={<TQuestionStatsPage />}
            />
          </Route>

          <Route path="/verify-email/:verification_token" element={<Verify />} />

          <Route path="/change-password/:password_change_token" element={<Reset />} />
          <Route path="*" element={<NotFound />} />

          <Route path="/verify" element={<Visualizer />} />

          <Route exact path="/" element={<RedirectSite />} />

          <Route exact path="/demo" element={<GradeIC />} />
        </Routes>
      </Router>
    </div>
  );
}

const RedirectSite = () => {
  window.location.href = 'marketing/index.html';
  return <></>;
};

export default App;

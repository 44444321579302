import React from "react";
import SideBar from "../../components/SideBar";
import QuestionStatistics from "../../components/Teacher/Statistics/QuestionStatistics";

const QuestionStatisticsPage = () => {
  return (
    <div id="Main-container" className="flex flex-row">
      <div id="Left-Sidebar" className="sticky top-0 h-screen overflow-y-auto">
        <SideBar activePage="Statistics" />
      </div>
      <div id="Right-content" className="flex-grow">
        <QuestionStatistics />
      </div>
    </div>
  );
};

export default QuestionStatisticsPage;

import React from 'react';
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { TSubQuestion } from './TSubQuestion';

export const TSubQuestionColumn = ({ question, subQuestions, isPublished, handleImageUpload, deleteImage, handleImageSizeChange, handlePreviewClick, previews, handleQuestionSizingChange, deleteRubricItem, deleteSubQuestion, handleQuestionTypeChange, handleQuestionChange, handleRubricDescriptorChange, handleRubricValueChange, addRubricItem, handleWeightChange, handleMCQOptionChange, handleMCQCorrectAnswer, deleteMCQOption, addMCQOption, handleSolutionChange, focusedSubQuestion, setFocusedSubQuestion }) => {
  return (<div><SortableContext items={subQuestions} strategy={verticalListSortingStrategy}>{subQuestions.map((subQuestion) => (
    <TSubQuestion
      key={subQuestion.id}
      question={question}
      subQuestion={subQuestion}
      isPublished={isPublished}
      handleImageUpload={handleImageUpload}
      deleteImage={deleteImage}
      handleImageSizeChange={handleImageSizeChange}
      handlePreviewClick={handlePreviewClick}
      previews={previews}
      handleQuestionSizingChange={handleQuestionSizingChange}
      deleteRubricItem={deleteRubricItem}
      deleteSubQuestion={deleteSubQuestion}
      handleQuestionTypeChange={handleQuestionTypeChange}
      handleQuestionChange={handleQuestionChange}
      handleRubricDescriptorChange={handleRubricDescriptorChange}
      handleRubricValueChange={handleRubricValueChange}
      addRubricItem={addRubricItem}
      handleWeightChange={handleWeightChange}
      handleMCQOptionChange={handleMCQOptionChange}
      handleMCQCorrectAnswer={handleMCQCorrectAnswer}
      deleteMCQOption={deleteMCQOption}
      addMCQOption={addMCQOption}
      handleSolutionChange={handleSolutionChange}
      focusedSubQuestion={focusedSubQuestion}
      setFocusedSubQuestion={setFocusedSubQuestion}
    />
  ))}</SortableContext></div>);
};
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import DragAndDropUpload from '../DragDropQuestion.js';

export const TSubQuestion = ({
  question,
  subQuestion,
  isPublished,
  handleImageUpload,
  deleteImage,
  handleImageSizeChange,
  handlePreviewClick,
  previews,
  handleQuestionSizingChange,
  deleteRubricItem,
  deleteSubQuestion,
  handleQuestionTypeChange,
  handleQuestionChange,
  handleRubricDescriptorChange,
  handleRubricValueChange,
  addRubricItem,
  handleWeightChange,
  handleMCQOptionChange,
  handleMCQCorrectAnswer,
  deleteMCQOption,
  addMCQOption,
  handleSolutionChange,
  focusedSubQuestion,
  setFocusedSubQuestion
}) => {
  const { isDragging, setNodeRef, attributes, listeners, transform, transition } = useSortable({
    id: subQuestion.id,
    animateLayoutChanges: () => false
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };
  //
  // if (isDragging) {
  //     setFocusedSubQuestion(true);
  // }

  return (
    <div
      ref={setNodeRef}
      key={subQuestion.id}
      style={style}
      className="rounded-xl bg-white p-4 mb-4 w-full"
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center space-x-4">
          <div className="size-4 flex items-center justify-center ${disabledStyle}">
            <button
              disabled={isPublished}
              onClick={() => deleteSubQuestion(question.id, subQuestion.id)}
            >
              <img src="/assets/trash.png" className="size-4" alt="Delete sub-question" />
            </button>
          </div>
          <div className="font-bold flex items-center text-base">
            Sub-question {String.fromCharCode(96 + subQuestion.id).toUpperCase()}
          </div>
          <select
            value={subQuestion.questionType}
            disabled={isPublished}
            className="border rounded px-2 py-1"
            onChange={event => handleQuestionTypeChange(question.id, subQuestion.id, event)}
          >
            <option value="freeform">Freeform Question</option>
            <option value="mcq">Multiple Choice Question</option>
          </select>
        </div>
        <div className="flex items-center space-x-4">
          <div className="size-10 flex items-center justify-center">
            <button
              {...(isPublished ? {} : { ...listeners, ...attributes })}
              disabled={isPublished}
            >
              <img src="/assets/list.png" className="size-10" />
            </button>
          </div>
          <div className="size-10 flex items-center justify-center ${disabledStyle}">
            <button
              onClick={() =>
                setFocusedSubQuestion(focusedSubQuestion === subQuestion.id ? '' : subQuestion.id)
              }
            >
              <img
                src={
                  focusedSubQuestion === subQuestion.id
                    ? '/assets/arrow-up-white.png'
                    : '/assets/arrow-down-white.png'
                }
                className="size-10"
              />
            </button>
          </div>
        </div>
      </div>

      {subQuestion.id === focusedSubQuestion && (
        <>
          <div className="flex flex-col md:flex-row justify-between items-start mt-4">
            <div className="w-full">
              <textarea
                value={subQuestion.question}
                placeholder="Content"
                disabled={isPublished}
                className="sexy-border rounded-lg mb-4 focus:ring-0 focus:sexy-border focus:rounded-lg w-full h-36 ${disabledStyle}"
                onChange={event => handleQuestionChange(question.id, subQuestion.id, event)}
              />
              <div className="mb-2 text-sm">Optional: Upload an Image or Drawing</div>
              <div className="w-full">
                <DragAndDropUpload
                  handleImageUpload={event => handleImageUpload(event, question.id, subQuestion.id)}
                  isPublished={isPublished}
                />
              </div>
              {subQuestion.imageS3Key && (
                <div className="relative mt-2">
                  <img
                    src={`${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${subQuestion.imageS3Key}`}
                    alt="Sub-question"
                    className="w-20 h-20 object-cover"
                  />
                  <button
                    disabled={isPublished}
                    onClick={() => deleteImage(question.id, subQuestion.id)}
                  >
                    <img src="/assets/trash.png" className="size-4" alt="Delete sub-question" />
                  </button>
                </div>
              )}
              {subQuestion.imageS3Key && (
                <>
                  <div className="mb-2 text-sm">Image size: {subQuestion.imageSize}%</div>
                  <div className="input-entry-box font-normal px-2 w-16 ${disabledStyle}">
                    <input
                      type="number"
                      min="1"
                      max="100"
                      value={subQuestion.imageSize}
                      className="actual-input text-black ${disabledStyle}"
                      disabled={isPublished}
                      onChange={event => handleImageSizeChange(question.id, subQuestion.id, event)}
                    />
                  </div>
                </>
              )}
              <div className="mb-4 mt-2 ${disabledStyle}">
                <button
                  disabled={isPublished}
                  className="less-sexy-button h-9 px-4 flex items-center justify-center mb-2 ${disabledStyle}"
                  onClick={() => handlePreviewClick(question.id, subQuestion.id)}
                >
                  Preview
                </button>
                {previews[`${question.id}-${subQuestion.id}`] && (
                  <div className="mt-2">
                    <img
                      src={previews[`${question.id}-${subQuestion.id}`].url}
                      alt="LaTeX Preview"
                      style={{
                        width: previews[`${question.id}-${subQuestion.id}`].width,
                        height: previews[`${question.id}-${subQuestion.id}`].height,
                        maxWidth: '100%',
                        objectFit: 'contain'
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col w-full md:w-[450px] md:ml-8">
              {subQuestion.questionType === 'freeform' && (
                <div className="flex justify-between items-start mb-4">
                  <div className="flex flex-col">
                    <div className="text-sm font-medium mb-2">Answer box size (%)</div>
                    <div className="input-entry-box font-normal px-2 w-16 ${disabledStyle}">
                      <input
                        type="number"
                        value={subQuestion.questionSizing}
                        className="actual-input text-black ${disabledStyle}"
                        disabled={isPublished}
                        onChange={event =>
                          handleQuestionSizingChange(question.id, subQuestion.id, event)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex-grow">
                {subQuestion.questionType === 'freeform' ? (
                  <div className="flex flex-col">
                    <div className="text-sm font-medium mb-2">Rubric Items</div>
                    {subQuestion.rubricItems.map((rubricItem, rubricIndex) => (
                      <div key={rubricIndex} className="flex mb-2 items-center">
                        <div className="size-4 flex items-center justify-center mr-3 ${disabledStyle}">
                          <button
                            disabled={isPublished}
                            onClick={() =>
                              deleteRubricItem(question.id, subQuestion.id, rubricIndex)
                            }
                          >
                            <img
                              src="/assets/trash.png"
                              className="size-4"
                              alt="Delete rubric item"
                            />
                          </button>
                        </div>
                        <div className="flex items-center justify-center space-x-2">
                          <div className="input-entry-box font-normal px-2 w-5/6 ${disabledStyle}">
                            <input
                              type="text"
                              value={rubricItem.descriptor}
                              placeholder="Descriptor"
                              disabled={isPublished}
                              className="actual-input text-black w-full ${disabledStyle}"
                              onChange={event =>
                                handleRubricDescriptorChange(
                                  question.id,
                                  subQuestion.id,
                                  rubricIndex,
                                  event
                                )
                              }
                            />
                          </div>
                          <div className="input-entry-box font-normal w-16 ${disabledStyle}">
                            <input
                              type="number"
                              disabled={isPublished}
                              min="0"
                              value={rubricItem.value}
                              placeholder="Pts"
                              className="actual-input text-black w-full px-2 py-1 box-border appearance-none ${disabledStyle}"
                              onChange={event =>
                                handleRubricValueChange(
                                  question.id,
                                  subQuestion.id,
                                  rubricIndex,
                                  event
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="flex items-center mt-4 ml-8 ${disabledStyle}">
                      <button
                        disabled={isPublished}
                        onClick={() => addRubricItem(question.id, subQuestion.id)}
                      >
                        <div className="size-4 flex items-center justify-center ${disabledStyle}">
                          <img
                            src="/assets/add-circle.png"
                            className="size-4"
                            alt="Add rubric item"
                          />
                        </div>
                      </button>
                      <div className="ml-5 text-sm ${disabledStyle}">Add Rubric Item</div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="text-sm font-medium mb-2">Question Weight</div>
                    <div className="input-entry-box font-normal px-2 w-16 ${disabledStyle}">
                      <input
                        type="number"
                        disabled={isPublished}
                        min="1"
                        max="99"
                        value={subQuestion.weight}
                        className="actual-input text-black ${disabledStyle}"
                        onChange={event => handleWeightChange(question.id, subQuestion.id, event)}
                      />
                    </div>
                    <div className="text-sm font-medium mt-4 mb-2">Multiple Choice Options</div>
                    {subQuestion.mcqOptions.map((option, optionIndex) => (
                      <div key={optionIndex} className="flex items-center mb-2">
                        <div className="mr-2">{option.option})</div>
                        <input
                          type="text"
                          disabled={isPublished}
                          value={option.text}
                          className="sexy-border rounded px-2 py-1 flex-grow mr-2 ${disabledStyle}"
                          placeholder={`Option ${option.option}`}
                          onChange={event =>
                            handleMCQOptionChange(question.id, subQuestion.id, optionIndex, event)
                          }
                        />
                        <div className="relative inline-block mr-2">
                          <input
                            type="checkbox"
                            disabled={isPublished}
                            checked={option.isCorrect}
                            className="sr-only peer"
                            id={`checkbox-${question.id}-${subQuestion.id}-${optionIndex}`}
                            onChange={() =>
                              handleMCQCorrectAnswer(question.id, subQuestion.id, optionIndex)
                            }
                          />
                          <label
                            htmlFor={`checkbox-${question.id}-${subQuestion.id}-${optionIndex}`}
                            className={`${
                              isPublished ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
                            } w-5 h-5 bg-white border border-gray-300 rounded-3xl peer-checked:bg-orange-200 peer-checked:border-orange-300 flex items-center justify-center transition-colors duration-200 ease-in-out`}
                          ></label>
                        </div>
                        <button
                          disabled={isPublished}
                          onClick={() => deleteMCQOption(question.id, subQuestion.id, optionIndex)}
                        >
                          <img src="/assets/trash.png" className="size-4" alt="Delete option" />
                        </button>
                      </div>
                    ))}
                    <button
                      disabled={isPublished}
                      className="less-sexy-button w-40 h-9 mt-2 ${disabledStyle}"
                      onClick={() => addMCQOption(question.id, subQuestion.id)}
                    >
                      Add Option
                    </button>
                  </div>
                )}
              </div>
              {subQuestion.questionType === 'freeform' && (
                <div className="mt-4">
                  <div className="mb-2 text-sm font-medium ${disabledStyle}">
                    Solution (and Grading Instructions)
                  </div>
                  <textarea
                    value={subQuestion.solution}
                    disabled={isPublished}
                    placeholder="Solution"
                    className="sexy-border rounded-lg mb-4 focus:ring-0 focus:sexy-border focus:rounded-lg w-full h-36 ${disabledStyle}"
                    onChange={event => handleSolutionChange(question.id, subQuestion.id, event)}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

import React, {useState, useCallback, useRef} from 'react';

function DragAndDropUpload({onFileChange}) {
    const [file, setFile] = useState(null);
    const [dragging, setDragging] = useState(false);
    const inputRef = useRef(null);

    const handleFileChange = (selectedFile) => {
        setFile(selectedFile);
        onFileChange(selectedFile);
        // console.log("File selected:", selectedFile);
    };

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileChange(e.dataTransfer.files[0]);
        }
        setDragging(false);
    }, []);

    const handleDrag = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragIn = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    }, []);

    const handleDragOut = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    }, []);

    return (
        <div className="">
            <label
                onDrop={handleDrop}
                onDragOver={handleDrag}
                onDragEnter={handleDragIn}
                onDragLeave={handleDragOut}
                className={`flex justify-center w-full h-16 px-4 transition bg-white border-2 ${
                    dragging ? 'border-blue-300' : 'border-gray-500'
                } border-dashed border-[1px] rounded-md appearance-none cursor-pointer hover:border-gray-900 focus:outline-none`}
            >
                <span className="flex items-center space-x-2">
                    <span className="text-gray-600 italic text-smallish">
                        Drag & drop file here or <span className="underline">Browse</span>
                    </span>
                </span>
                <input
                    ref={inputRef}
                    type="file"
                    accept="application/pdf"
                    name="file_upload"
                    className="hidden"
                    onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                            handleFileChange(e.target.files[0]);
                        }
                    }}
                />
            </label>
            {file && <div className="mt-2 text-sm text-gray-600">File: {file.name}</div>}
        </div>
    );
}

export default DragAndDropUpload;
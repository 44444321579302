import React, { useRef, useState } from 'react';
import { gradeApi } from '../../api.js';
import { useAuth } from '../../auth/AuthContext';
import DragAndDropUpload from '../DragDropAnswer.js';

function TSubmissions({ assignment_id, setUpdateTrigger }) {
  const [file, setFile] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPolling, setIsPolling] = useState(false);
  const { user } = useAuth();
  const pollIntervalRef = useRef(null);

  const handleFileChange = selectedFile => {
    setFile(selectedFile);
  };

  const checkGradingStatus = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/assignment/${assignment_id}/grading-status/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      return response.data.is_assignment_graded;
    } catch (error) {
      console.error('Error checking grading status:', error);
      return false;
    }
  };

  const startPolling = () => {
    setIsPolling(true);
    pollIntervalRef.current = setInterval(async () => {
      const isGraded = await checkGradingStatus();
      if (isGraded) {
        clearInterval(pollIntervalRef.current);
        setIsPolling(false);
        setLoading(false);
        setErrorMessage('Assignments graded successfully!');
        setUpdateTrigger(prev => !prev);
      }
    }, 5000);
  };

  const handleCancel = () => {
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
    }
    setIsPolling(false);
    setLoading(false);
    setErrorMessage('');
  };

  const uploadAndGradeAssignments = async e => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    if (!file) {
      setErrorMessage('Please select a file to upload.');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('file_data', file);
    formData.append('user_id', user.user.id);

    try {
      const uploadResponse = await gradeApi.post({
        path: `/api/assignment/${assignment_id}/batch-upload/`,
        body: formData,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      });

      if (uploadResponse.status === 200) {
        setSubmitted(true);

        // Grading process
        const gradingResponse = await gradeApi.get({
          path: `/api/assignment/${assignment_id}/ai-grade/`,
          config: {
            headers: {
              Authorization: `Bearer ${user.session_token}`
            }
          }
        });
        if (gradingResponse.status === 200) {
          startPolling();
        } else {
          setErrorMessage('Failed to initiate grading. Please try again.');
          setLoading(false);
        }
      } else {
        setErrorMessage('Failed to upload. Please try again.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error uploading or grading assignments:', error);
      setErrorMessage(error.response?.data?.error || 'An error occurred. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className={`flex flex-col w-full`}>
      <h2 className="text-xl font-bold mb-1">Step 4: Upload student work</h2>
      <p className="text-sm mb-4">
        Scan all student exams and upload in a single PDF.
        <br /> The system will automatically grade the submissions.
      </p>
      <div>
        <DragAndDropUpload onFileChange={handleFileChange} />
        <div className="flex items-center space-x-4 mt-4">
          <button
            disabled={!file || loading || isPolling}
            className={`px-10 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow relative ${
              loading || isPolling
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'sexy-button w-[200px]' // Increased width from 170px to 200px
            }`}
            onClick={uploadAndGradeAssignments}
          >
            {loading || isPolling ? (
              <>
                <span className="opacity-0">{submitted ? 'Resubmit' : 'Upload & Grade'}</span>
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                </div>
              </>
            ) : submitted ? (
              'Resubmit'
            ) : (
              'Upload & Grade'
            )}
          </button>
          {(loading || isPolling) && (
            <button
              className="px-6 py-2 rounded-3xl border border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-red-200 hover:bg-red-300 text-black"
              onClick={handleCancel}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
      {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
      {(loading || isPolling) && (
        <p className="text-blue-500 mt-4">Grading in progress, please check back later...</p>
      )}
    </div>
  );
}

export default TSubmissions;

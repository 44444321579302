// import React from "react";
//
// const Form = () => {
//     return (
//         <div className="h-full flex items-center justify-center">
//             <h1 className="text-2xl font-bold">Website under maintenance</h1>
//         </div>
//     );
// };

// export default Form;
// todo:uncomment
import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import '../App.css';
import {useAuth} from '../auth/AuthContext';

const Form = ({setPageToggle, GradeWizHeader}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const {user, login} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.isAuthenticated && user.is_teacher) {
            navigate('/tclasses');
        } else if (user.isAuthenticated && !user.is_teacher) {
            navigate('/sclasses');
        }
    });

    const handleSubmit = e => {
        e.preventDefault();
        try {
            const value = login(email, password);
            value.then(message => {
                setError(message);
                // console.log(message);
            });
        } catch (error) {
            setError(error.response.data.error);
            // console.log(error);
        }
    };

    const isFormValid = email && password;

    const [emailFocused, setEmailFocused] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="h-full flex flex-col justify-between">
            <div>
                <div className="mb-10">
                    <GradeWizHeader/>
                </div>
                <h2 className="custom-title-sm text-black mb-5 font-bold">Sign In</h2>
                <form className="" onSubmit={handleSubmit}>
                    <div
                        className={`flex items-center input-entry-box ${error !== '' ? 'border-red-500' : ''}`}
                        onFocus={() => {
                            setEmailFocused(true);
                            setError('');
                        }}
                        onBlur={() => setEmailFocused(false)}
                    >
                        <div className="size-9 mr-0.5 flex items-center justify-center">
                            <img
                                src={
                                    error == ''
                                        ? '/assets/authentication/email.png'
                                        : '/assets/authentication/red-email.png'
                                }
                                className={emailFocused || error != '' ? 'size-5' : 'size-5 opacity-30'}
                            />
                        </div>
                        <input
                            type="text"
                            value={email}
                            className={`actual-input mr-2.5 ${error !== '' ? 'text-red-500' : ''}`}
                            placeholder="Email"
                            onChange={e => setEmail(e.target.value.toLowerCase())}
                        />
                    </div>
                    <div
                        className={`flex items-center input-entry-box mt-3 ${error !== '' ? 'border-red-500' : ''}`}
                        onFocus={() => {
                            setPasswordFocused(true);
                            setError('');
                        }}
                        onBlur={() => setPasswordFocused(false)}
                    >
                        <div className="size-9 mr-0.5 flex items-center justify-center">
                            <img
                                src={
                                    error == ''
                                        ? '/assets/authentication/password.png'
                                        : '/assets/authentication/red-password.png'
                                }
                                className={passwordFocused || error != '' ? 'size-5' : 'size-5 opacity-30'}
                            />
                        </div>
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            value={password}
                            className={`actual-input flex-grow ${error !== '' ? 'text-red-500 border-' : ''}`}
                            placeholder="Password"
                            onChange={e => setPassword(e.target.value)}
                        />
                        {error == '' && (
                            <button
                                type="button"
                                className="mr-3 text-gray-600 hover:text-gray-900 focus:outline-none"
                                onClick={togglePasswordVisibility}
                            >
                                <img
                                    src={
                                        passwordVisible
                                            ? '/assets/authentication/show-password.png'
                                            : '/assets/authentication/hide-password.png'
                                    }
                                    alt="Toggle password visibility"
                                    className={passwordFocused ? 'size-5' : 'size-5 opacity-30'}
                                />
                            </button>
                        )}
                    </div>
                    {error !== '' && (
                        <div className="text-red-500 mt-2 text-smallish">
                            {error}
                            <p>
                                Having issues? Refer to our{' '}
                                <Link
                                    to="https://drive.google.com/file/d/1Q2ahTxVlJQFLk75NR11IXOsaMBz_Jpu4/view?usp=sharing"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-orange-300 hover:underline"
                                >
                                    documentation
                                </Link>
                                .
                            </p>
                            <p>Note: We have updated our password encryption.
                                If you had a special character in your password (i.e. # or &),
                                you may need to reset your password using Forgot Password.</p>
                        </div>
                    )}
                    <button
                        type="submit"
                        disabled={!isFormValid}
                        className={`mt-3.5 bg-black text-white text-sm font-medium py-2 px-4 rounded-full transition-all duration-300 w-full ${isFormValid ? 'bg-black text-white' : 'text-black border border-border cursor-not-allowed'}`}
                    >
                        Sign In
                    </button>
                </form>
                <button
                    className="login-button custom-functional block mt-5 mx-auto text-gray-800 text-smallish"
                    onClick={() => setPageToggle('forgot-password')}
                >
                    Forgot password
                </button>
            </div>
            <div>
                <div className="mb-6">
                    <hr className="border-t border-gray-100"></hr>
                </div>
                <button
                    className="login-button custom-functional block mx-auto text-gray-800 text-smallish"
                    onClick={() => setPageToggle('create-account')}
                >
                    Create an account
                </button>
            </div>
            <div className="mb-2">
                <hr className="border-t border-gray-100"></hr>
            </div>
            <Link
                to="https://drive.google.com/file/d/1Q2ahTxVlJQFLk75NR11IXOsaMBz_Jpu4/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                className="login-button custom-functional block mx-auto text-gray-800 text-smallish hover:underline text-center"
            >
                How do I sign up?
            </Link>
        </div>
    );
};

export default Form;

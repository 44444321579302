import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {apiUrl, gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import ConfirmationModal from '../ConfirmationModal';
import AIRegrade from './AIRegrade';

const TDetails = props => {
    const {user} = useAuth();
    const {assignment_id, submission_id} = useParams();
    const [submissionData, setSubmissionData] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [examPaper, setExamPaper] = useState([]);
    const [feedbackItems, setFeedbackItems] = useState({});
    const [editableFeedback, setEditableFeedback] = useState({});
    const [message, setMessage] = useState('');
    const feedbackRefs = useRef({});
    const [assignment, setAssignment] = useState(null);
    const [studentName, setStudentName] = useState('');
    const [renderedImages, setRenderedImages] = useState({});
    const navigate = useNavigate();
    const [isRegradeOpen, setIsRegradeOpen] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [regradeData, setRegradeData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [activeSubQuestionId, setActiveSubQuestionId] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [isReviewed, setIsReviewed] = useState(false);
    const [students, setStudents] = useState([]);
    const [assignedStudentId, setAssignedStudentId] = useState(null);
    const [error, setError] = useState(null);
    const [isStudentAssignmentConfirmationOpen, setIsStudentAssignmentConfirmationOpen] =
        useState(false);
    const [newStudentAssignment, setNewStudentAssignment] = useState(null);
    const [regradeInProgress, setRegradeInProgress] = useState({});
    const pollingInterval = useRef(null);
    const [gradePending, setGradePending] = useState(false);
    const [groupStudents, setGroupStudents] = useState([]);

    // Fetching assignment submission details
    const getAssignmentSubmission = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/submission/${submission_id}/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const data = response.data;
            setSubmitted(data.submitted);
            setSubmissionData(data.submitted ? data : {});
            setFeedbackItems(data.submitted ? data.feedback : {});
            setIsReviewed(data.is_reviewed);
            setAssignedStudentId(data.user.id);
            setStudentName(`${data.user.first_name} ${data.user.last_name}`);
        } catch (error) {
            setMessage(error.response.data.error);
        }
    };

    const getStudents = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/courses/${assignment.course_id}/students/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const users = response.data.users;
            const userInfo = users.map(user => ({
                name: user.first_name + ' ' + user.last_name,
                id: user.id,
                is_null_user: user.is_null_user,
                email: user.email
            }));
            setStudents(userInfo);
            setAssignedStudentId(submissionData.user.id);
        } catch (error) {
            console.error('Error fetching students:', error);
            setError('Failed to fetch students. Please try again.');
        }
    };

    const handleStudentAssignment = event => {
        const newStudentId = event.target.value;
        const selectedStudent = students.find(s => s.id.toString() === newStudentId);

        if (selectedStudent) {
            setNewStudentAssignment(selectedStudent);
        } else {
            // If the student is not in the list (which shouldn't happen normally),
            // we'll create a placeholder with just the ID
            setNewStudentAssignment({id: newStudentId, name: `Unknown Student (ID: ${newStudentId})`});
        }

        setIsStudentAssignmentConfirmationOpen(true);
    };

    const handleConfirmStudentAssignment = async () => {
        try {
            await gradeApi.post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/assign-student/`,
                body: {new_student_id: newStudentAssignment.id},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setAssignedStudentId(newStudentAssignment.id);
            setStudentName(newStudentAssignment.name);

            // Refresh the submission data
            getAssignmentSubmission();
            setIsStudentAssignmentConfirmationOpen(false);
        } catch (error) {
            console.error('Failed to assign student:', error);
            setError('Failed to assign student. Please try again.');
        }
    };

    useEffect(() => {
        if (assignment?.is_exam && assignment.course_id && submissionData.user) {
            getStudents();
        }
    }, [assignment, submissionData]);

    const fetchGroupStudents = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/get_students/${assignment.id}/student/${assignedStudentId}`,  // Make sure to use assignment.id, not aid
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setGroupStudents(response.data);  // Assuming response.data is an array
        } catch (error) {
            console.error('Error fetching group students:', error);
            // Consider setting an error state here instead of using setError
        }
    };

    useEffect(() => {
        fetchGroupStudents();
    }, [assignment]);

    const handleMarkReviewed = async () => {
        try {
            const newReviewedStatus = !isReviewed; // Toggle the current status
            const response = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/mark-reviewed/`,
                body: {is_reviewed: newReviewedStatus},
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setIsReviewed(newReviewedStatus);
        } catch (error) {
            console.error('Failed to update review status:', error);
            setMessage('Failed to update review status.');
        }
    };

    const startPolling = () => {
        if (!pollingInterval.current) {
            pollingInterval.current = setInterval(getQuestions, 5000);
        }
    };

    const stopPolling = () => {
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current);
            pollingInterval.current = null;
            setRefreshKey(refreshKey + 1);
        }
    };

    useEffect(() => {
        getQuestions();
        getAssignmentSubmission();
        getAssignment();
        return () => stopPolling();
    }, [assignment_id, submission_id, refreshKey]);

    const getQuestions = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/questions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            const questionsFromDB = response.data;
            // console.log(questionsFromDB);
            setExamPaper(questionsFromDB.questions);

            // Check for regrade_in_progress
            const newRegradeStatus = {};
            questionsFromDB.questions.forEach(question => {
                question.subQuestions.forEach(subQuestion => {
                    if (subQuestion.regrade_in_progress) {
                        newRegradeStatus[subQuestion.id] = true;
                    }
                });
            });
            setRegradeInProgress(newRegradeStatus);

            // Start or stop polling based on regrade status
            if (Object.values(newRegradeStatus).some(status => status)) {
                startPolling();
            } else {
                stopPolling();
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Fetching assignment details
    const getAssignment = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/only/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setAssignment(response.data);
        } catch (error) {
            setMessage('Failed to fetch assignment details');
        }
    };

    // Update student name when submission data changes
    useEffect(() => {
        if (submissionData.user) {
            setStudentName(`${submissionData.user.first_name} ${submissionData.user.last_name}`);
        }
    }, [submissionData, refreshKey]);

    // Re-fetch data when assignment_id or submission_id changes
    useEffect(() => {
        getAssignmentSubmission();
        getQuestions();
        getAssignment();
    }, [assignment_id, submission_id, refreshKey]);

    const renderLatexImage = imageS3Key => {
        const dpi = 350; // Hardcoded DPI value
        const img = new Image();
        img.src = `${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${imageS3Key}`;

        return new Promise(resolve => {
            img.onload = () => {
                const widthInches = img.naturalWidth / dpi;
                const heightInches = img.naturalHeight / dpi;
                const widthCm = widthInches * 2.54;
                const heightCm = heightInches * 2.54;

                resolve(
                    <img
                        src={img.src}
                        alt="LaTeX rendered question"
                        style={{
                            width: `${widthCm}cm`,
                            height: `${heightCm}cm`
                        }}
                    />
                );
            };
        });
    };

    useEffect(() => {
        const renderImages = async () => {
            const newRenderedImages = {};
            for (const question of examPaper) {
                // console.log(question);
                // if (question.latex_render) {
                //     newRenderedImages[`question-${question.id}`] = await renderLatexImage(question.latex_image_render_s3_key);
                // }
                for (const subQuestion of question.subQuestions) {
                    if (subQuestion.latex_image_render_s3_key) {
                        newRenderedImages[`subquestion-${subQuestion.id}`] = await renderLatexImage(
                            subQuestion.latex_image_render_s3_key
                        );
                    }
                }
            }
            setRenderedImages(newRenderedImages);
        };

        renderImages();
    }, [examPaper, refreshKey]);

    function getNextElement(arr, element) {
        if (!arr || arr.length === 0) {
            console.error('Empty or invalid array');
            return null;
        }
        const index = arr.indexOf(Number(element));
        if (index === -1) {
            console.error('Element not found in the array');
            return arr[0]; // Return first element if current not found
        }
        const nextIndex = (index + 1) % arr.length;
        return arr[nextIndex];
    }

    function getPrevElement(arr, element) {
        if (!arr || arr.length === 0) {
            console.error('Empty or invalid array');
            return null;
        }
        const index = arr.indexOf(Number(element));
        if (index === -1) {
            console.error('Element not found in the array');
            return arr[arr.length - 1]; // Return last element if current not found
        }
        const prevIndex = (index - 1 + arr.length) % arr.length;
        return arr[prevIndex];
    }

    const clickRight = () => {
        if (!props.submissionIds || props.submissionIds.length === 0) {
            console.error('No submissions available for navigation');
            return;
        }

        const currentIndex = props.submissionIds.indexOf(Number(submission_id));
        let prevIndex =
            currentIndex === -1
                ? props.submissionIds.length - 1
                : (currentIndex - 1 + props.submissionIds.length) % props.submissionIds.length;

        navigateToSubmission(prevIndex, -1);
    };

    const clickLeft = () => {
        if (!props.submissionIds || props.submissionIds.length === 0) {
            console.error('No submissions available for navigation');
            return;
        }

        const currentIndex = props.submissionIds.indexOf(Number(submission_id));
        let nextIndex = currentIndex === -1 ? 0 : (currentIndex + 1) % props.submissionIds.length;

        navigateToSubmission(nextIndex, 1);
    };

    const navigateToSubmission = (startIndex, direction) => {
        let currentIndex = startIndex;
        const maxAttempts = props.submissionIds.length;

        for (let attempt = 0; attempt < maxAttempts; attempt++) {
            const nextSubmissionId = props.submissionIds[currentIndex];
            if (nextSubmissionId != null) {
                navigate(`/tdetails/${assignment_id}/submission/${nextSubmissionId}`);
                return;
            }
            currentIndex =
                (currentIndex + direction + props.submissionIds.length) % props.submissionIds.length;
        }

        console.error('No valid submissions found to navigate to');
    };
    const hasRubricItem = (subquestionId, rubricId) => {
        if (!feedbackItems) {
            return false;
        }
        if (!subquestionId) {
            return false;
        }
        if (subquestionId in feedbackItems) {
            let rubricItems = feedbackItems[subquestionId]?.rubric_items;
            for (let i = 0; i < rubricItems.length; i++) {
                if (rubricItems[i].id === rubricId) {
                    return true;
                }
            }
        }
        return false;
    };

    const getFeedback = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].feedback;
        }
        return '';
    };

    const getRR = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].regrade_request;
        }
        return '';
    };

    const getRRText = subquestionId => {
        if (!feedbackItems) {
            return '';
        }
        if (subquestionId in feedbackItems) {
            return feedbackItems[subquestionId].regrade_text;
        }
        return '';
    };

    const handleRubricItemChange = (subquestionId, rubricItem) => () => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/grade/`,
                body: {
                    subquestionId: subquestionId,
                    rubric: rubricItem
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                // console.log(error);
                setGradePending(false);
            })
            .then(response => {
                setFeedbackItems(response.data.feedback);
                setSubmissionData({...submissionData, grade: response.data.grade});
                setGradePending(false);
            });
    };

    const handleFeedbackEdit = subquestionId => {
        setEditableFeedback(prev => ({
            ...prev,
            [subquestionId]: getFeedback(subquestionId) || ''
        }));
    };

    const handleFeedbackChange = (subquestionId, value) => {
        setEditableFeedback(prev => ({
            ...prev,
            [subquestionId]: value
        }));
    };

    const handleFeedbackSave = subquestionId => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submission_id}/feedback/`,
                body: {
                    subquestionId: subquestionId,
                    feedback: editableFeedback[subquestionId]
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error updating feedback:', error);
            })
            .then(response => {
                if (response) {
                    setFeedbackItems(prevFeedbackItems => ({
                        ...prevFeedbackItems,
                        [subquestionId]: {
                            ...prevFeedbackItems[subquestionId],
                            feedback: editableFeedback[subquestionId]
                        }
                    }));

                    setEditableFeedback(prev => {
                        const {[subquestionId]: _, ...rest} = prev;
                        return rest;
                    });

                    // console.log('Feedback updated successfully:', response.data);
                }
            });
    };

    const isEditing = subquestionId => editableFeedback.hasOwnProperty(subquestionId);

    const handleRegradeReq = subquestionId => {
        if (subquestionId in feedbackItems) {
            handleRegradeReqDel(subquestionId);
        }
    };

    const handleRegradeReqDel = subquestionId => {
        const submissionID = submissionData.id;

        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/submissions/${submissionID}/regrade-request/`,
                body: {
                    subquestionId: subquestionId,
                    regradeRequest: false
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error updating regrade request:', error);
            })
            .then(response => {
                if (response) {
                    setFeedbackItems(prevFeedbackItems => ({
                        ...prevFeedbackItems,
                        [subquestionId]: {
                            ...prevFeedbackItems[subquestionId],
                            regrade_request: false
                        }
                    }));

                    // console.log('Regrade request updated successfully:', response.data);
                }
            });
    };

    const renderFeedback = feedback => {
        return <div className="mb-2">{feedback}</div>;
    };

    const [isEditingEssay, setIsEditingEssay] = useState(false);
    const [editableEssayFeedback, setEditableEssayFeedback] = useState('');
    const feedbackRef = useRef(null);

    const handleEssayFeedbackChange = value => {
        setEditableEssayFeedback(value);
    };

    const handleEssayFeedbackEdit = () => {
        setEditableEssayFeedback(submissionData?.file?.filename || '');
        setIsEditingEssay(true);
    };

    const handleEssayFeedbackSave = () => {
        gradeApi
            .post({
                path: `/api/assignment/${assignment_id}/update-feedback/`,
                body: {
                    feedback: editableEssayFeedback
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                console.log('Error updating feedback:', error);
            })
            .then(response => {
                if (response) {
                    setSubmissionData(prevData => ({
                        ...prevData,
                        file: {
                            ...prevData.file,
                            filename: editableEssayFeedback
                        }
                    }));

                    setIsEditingEssay(false);

                    // Optionally refetch the submission data if necessary
                    getAssignmentSubmission();

                    // console.log('Feedback updated successfully:', response.data);
                }
            });
    };

    const handleRegradeClick = subQuestionId => {
        setActiveSubQuestionId(subQuestionId);
        setIsRegradeOpen(true);
    };

    const handleRegradeClose = () => {
        setIsRegradeOpen(false);
        setActiveSubQuestionId(null);
    };

    const handleRegradeSubmit = (newRubric, regradeInstructions, regradeFeedbackInstructions) => {
        setRegradeData({newRubric, regradeInstructions, regradeFeedbackInstructions});
        setIsConfirmationOpen(true);
    };

    const handleConfirmRegrade = async () => {
        setIsConfirmationOpen(false);
        setIsLoading(true);
        try {
            const response = await gradeApi.post({
                path: `/api/assignment/${assignment_id}/ai-regrade/`,
                body: {
                    subQuestionId: activeSubQuestionId,
                    newRubric: regradeData.newRubric,
                    regradeInstructions: regradeData.regradeInstructions,
                    regradeFeedbackInstructions: regradeData.regradeFeedbackInstructions

                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            // console.log('AI-Regrade initiated:', response.data);
            getQuestions(); // Fetch the updated questions
            setMessage('AI-Regrade initiated. The system will update the grades shortly.');
        } catch (error) {
            console.error('AI-Regrade failed:', error);
            setMessage('AI-Regrade failed. Please try again.');
        } finally {
            setIsLoading(false);
            setIsRegradeOpen(false);
            setActiveSubQuestionId(null);
        }
    };

    const SortRubricItemsAndDisplay = ({subQuestion}) => {
        subQuestion.rubricItems.sort((a, b) => {
            if (a.value == b.value) {
                return 0;
            }
            return a.value > b.value ? -1 : 1;
        })
        return subQuestion.rubricItems.map((rubricItem, rubricIndex) => (
            <button
                key={rubricIndex}
                disabled={gradePending}
                className={`flex p-2 text-sm rounded-3xl items-center ${hasRubricItem(subQuestion.id, rubricItem.id)
                    ? 'border border-black bg-orange-200 hover:bg-orange-300'
                    : 'border border-black bg-white-200 hover:bg-gray-100'
                }`}
                onClick={handleRubricItemChange(subQuestion.id, rubricItem)}
            >
                <div className="mr-1 ml-1 w-[30px] h-[30px] p-1 rounded-full text-center
        border-[1px] border-black flex-none bg-white">
                    {`${rubricItem.value}`}
                </div>
                <div className="text-left m-auto ml-2">
                    {`${rubricItem.descriptor}`}
                </div>
            </button>
        ))
    };

    return (
        <div
            key={`${assignment_id}-${submission_id}-${refreshKey}`}
            className="flex flex-col mt-5 w-full overflow-x-hidden"
        >
            {/* navigation trail */}
            <div className="flex flex-col fixed w-full py-7 z-40 bg-white px-5">
                <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center space-x-2">
                        <Link to="/tclasses">
                            <div className="size-10 flex items-center justify-center bg-gray-50 rounded-full">
                                <img src="/assets/sidebar/menu-home.png" className="size-5" alt="Home"/>
                            </div>
                        </Link>
                        <Link to="/tclasses">
                            <button>
                                <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                            </button>
                        </Link>
                        <Link to="/tclasses" className="text-black text-lg font-medium ml-4">
                            My Classes
                        </Link>
                        <Link to={`/tassignments/${assignment?.course_id}`}>
                            <button>
                                <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                            </button>
                        </Link>
                        <Link
                            to={`/tassignments/${assignment?.course_id}`}
                            className="text-black text-lg font-medium ml-4"
                        >
                            {assignment?.course?.name.length > 40 ?
                                assignment?.course?.name.substring(0, 37) + "..." :
                                assignment?.course?.name}
                        </Link>
                        <Link to={`/tsubmissions/${assignment?.id}`}>
                            <button>
                                <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                            </button>
                        </Link>
                        <Link
                            to={`/tsubmissions/${assignment?.id}`}
                            className="text-black text-lg font-medium ml-4"
                        >
                            {assignment?.title.length > 40 ?
                                assignment?.title.substring(0, 37) + "..." :
                                assignment?.title}
                        </Link>
                        <Link to={`/tsubmissions/${assignment?.id}`}>
                            <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                        </Link>
                        <div className="text-black text-lg font-medium ml-4">
                            {studentName.length > 20 ?
                                studentName.substring(0, 17) + "..." :
                                studentName}
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <button
                                className="text-gray-600 text-l font-medium flex items-center"
                                onClick={clickRight}
                            >
                                <span>Back</span>
                                <i className="fas fa-sort-up -rotate-90 text-2xl ml-3"></i>
                            </button>
                            <button
                                className="text-gray-600 text-l font-medium flex items-center"
                                onClick={clickLeft}
                            >
                                <i className="fas fa-sort-up rotate-90 text-2xl mr-3"></i>
                                <span>Next</span>
                            </button>
                        </div>

                        <div className="flex items-center ml-5 bg-grey-700 rounded-full p-2 shadow-md">
                            <input
                                type="checkbox"
                                id="markReviewedCheckbox"
                                checked={isReviewed}
                                className="sr-only peer"
                                onChange={handleMarkReviewed}
                            />
                            <label
                                htmlFor="markReviewedCheckbox"
                                className="cursor-pointer w-5 h-5 bg-white border border-gray-300 rounded-3xl peer-checked:bg-orange-200 peer-checked:border-orange-300 flex items-center justify-center transition-colors duration-200 ease-in-out mr-2"
                            ></label>
                            <label htmlFor="markReviewedCheckbox" className="text-sm font-medium text-black">
                                Mark Reviewed
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            {/* main content */}
            <div className="flex h-[calc(100vh-9rem)] mt-24">
                {/* student work */}
                <div className="w-1/2 p-4">
                    {submitted &&
                        (submissionData.essay_text ? (
                            <div className="w-full h-full overflow-auto bg-white border border-gray-300 p-4 rounded-xl">
                                <pre className="whitespace-pre-wrap font-sans">{submissionData.essay_text}</pre>
                            </div>
                        ) : (
                            <iframe
                                src={`${apiUrl}/api/uploads/${submissionData.file.filename}#zoom=70`}
                                title="PDF Viewer"
                                className="w-full h-full"
                                style={{border: 'none'}}
                            />
                        ))}
                </div>

                <div className="w-1/2 overflow-y-auto p-8">
                    {assignment?.is_exam && (
                        <div className="mb-4">
                            <label
                                htmlFor="studentAssignment"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Assigned to:
                            </label>
                            <select
                                id="studentAssignment"
                                value={assignedStudentId || ''}
                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                onChange={handleStudentAssignment}
                            >
                                {assignedStudentId && !students.some(s => s.id === assignedStudentId) && (
                                    <option
                                        value={assignedStudentId}
                                    >{`${studentName} (ID: ${assignedStudentId})`}</option>
                                )}
                                {students.map(student => (
                                    <option key={student.id} value={student.id}>
                                        {`${student.name} (ID: ${student.id})`}
                                    </option>
                                ))}
                            </select>
                            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                        </div>
                    )}
                    <h2 className="text-3xl font-bold mb-4">
                        Score: {submissionData?.grade}/{assignment?.max_score}
                    </h2>
                    <div>{submissionData?.late ? '(Late submission)' : '(On time)'}</div>
                    {groupStudents.length > 0 && (
                        <div>
                            <p>Group Members:</p>
                            <p>
                                {Array.isArray(groupStudents) && groupStudents.length > 0
                                    ? groupStudents.map((student, index) => (
                                        <span key={index}>
                      {student[0]} ({student[1]})
                                            {index < groupStudents.length - 1 ? ', ' : ''}
                    </span>
                                    ))
                                    : 'No students to display'}
                            </p>
                        </div>
                    )}
                    {examPaper.map((question, questionIndex) => (
                        <div key={questionIndex} className="mb-8">
                            <h2 className="text-xl font-bold mb-4">
                                {assignment?.is_essay
                                    ? `Question ${questionIndex + 1}: ${question.description}`
                                    : `Question ${questionIndex + 1}:`}
                            </h2>
                            {renderedImages[`question-${question.id}`]}

                            {question.subQuestions.map((subQuestion, subQuestionIndex) => (
                                <div
                                    key={subQuestionIndex}
                                    className={`mb-6 ${regradeInProgress[subQuestion.id] ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''}`}
                                >
                                    {regradeInProgress[subQuestion.id] && (
                                        <div className="mt-2 mb-4 text-xl text-orange-300">
                                            This subquestion is being regraded...
                                        </div>
                                    )}
                                    {assignment?.is_essay ? (
                                        <h3 className="font-semibold mb-2">
                                            {`${String.fromCharCode(97 + subQuestionIndex)}) ${subQuestion.question}`}
                                        </h3>
                                    ) : (
                                        renderedImages[`subquestion-${subQuestion.id}`]
                                    )}
                                    <div className="flex flex-col gap-2 mb-2 mt-3">
                                        <SortRubricItemsAndDisplay subQuestion={subQuestion}/>
                                    </div>
                                    {
                                        <div className="mt-2">
                                            {isEditing(subQuestion.id) ? (
                                                <>
                          <textarea
                              ref={el => (feedbackRefs.current[subQuestion.id] = el)}
                              value={editableFeedback[subQuestion.id]}
                              className="w-full p-2 border rounded"
                              rows="3"
                              onChange={e => handleFeedbackChange(subQuestion.id, e.target.value)}
                          />
                                                    <div className="flex justify-between items-center mt-2">
                                                        <button
                                                            className="less-sexy-button w-40 h-9 hover:bg-gray-200"
                                                            onClick={() => handleFeedbackSave(subQuestion.id)}
                                                        >
                                                            Save
                                                        </button>
                                                        {!assignment?.is_essay && subQuestion.questionType !== 'mcq' && (
                                                            <button
                                                                className="less-sexy-button w-40 h-9 hover:bg-gray-200 flex items-center justify-center"
                                                                onClick={() => handleRegradeClick(subQuestion.id)}
                                                            >
                                <span className="mr-2 text-2xl" style={{fontSize: '1.5em'}}>
                                  ✨
                                </span>
                                                                <span>Edit Rubric</span>
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {renderFeedback(getFeedback(subQuestion.id))}
                                                    <div className="flex justify-between items-center mt-2">
                                                        <button
                                                            className="less-sexy-button w-40 h-9 hover:bg-gray-200"
                                                            onClick={() => handleFeedbackEdit(subQuestion.id)}
                                                        >
                                                            Edit
                                                        </button>
                                                        {!assignment?.is_essay && subQuestion.questionType !== 'mcq' && (
                                                            <button
                                                                className="less-sexy-button w-40 h-9 hover:bg-gray-200 flex items-center justify-center"
                                                                onClick={() => handleRegradeClick(subQuestion.id)}
                                                            >
                                <span className="mr-2 text-2xl" style={{fontSize: '1.5em'}}>
                                  ✨
                                </span>
                                                                <span>Edit Rubric</span>
                                                            </button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    }

                                    {getRR(subQuestion.id) && (
                                        <div className="mt-4 bg-orange-100 p-4 rounded">
                                            <h4 className="font-semibold mb-2">Regrade Request:</h4>
                                            <p>{getRRText(subQuestion.id)}</p>
                                            <button
                                                className="less-sexy-button w-full h-9 hover:bg-gray-200 mt-2"
                                                onClick={() => handleRegradeReq(subQuestion.id)}
                                            >
                                                Dismiss Regrade Request
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                    {assignment?.is_essay && (
                        <div className="w-full bg-white border border-gray-300 p-4 rounded-xl">
                            <style>{`
                    .feedback-content h2 {
                        font-size: 20px;
                        font-weight: var(--h2-font-weight);
                        margin: var(--h2-margin);
                    }
                    .feedback-content p {
                        margin: var(--p-margin);
                    }
                    .feedback-content ul {
                        list-style-type: disc;
                        padding: var(--ul-padding);
                        margin: var(--ul-margin);
                    }
                    .feedback-content li {
                        margin: var(--li-margin);
                    }
                `}</style>
                            {isEditingEssay ? (
                                <>
                                    <div className="">
                    <textarea
                        ref={feedbackRef}
                        value={editableEssayFeedback}
                        className="w-full p-2 border-none h-[500px]"
                        rows="10"
                        onChange={e => handleEssayFeedbackChange(e.target.value)}
                    />
                                        <button
                                            className="less-sexy-button w-40 h-9 hover:bg-gray-200 mt-2"
                                            onClick={handleEssayFeedbackSave}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="flex flex-col justify-between feedback-content">
                                        <div
                                            dangerouslySetInnerHTML={{__html: submissionData?.file?.filename}}
                                            className="feedback-content"
                                            style={{
                                                '--h2-font-size': '1.5em',
                                                '--h2-font-weight': 'bold',
                                                '--h2-margin': '1em 0 0.5em',
                                                '--p-margin': '0 0 1em',
                                                '--ul-padding': '0 0 0 2em',
                                                '--ul-margin': '0 0 1em',
                                                '--li-margin': '0 0 0.5em',
                                                height: 'auto', // Adjust the height property
                                                overflow: 'visible' // Ensure overflow is visible
                                            }}
                                        />
                                        <button
                                            className="less-sexy-button w-40 h-9 hover:bg-gray-200 mt-2"
                                            onClick={handleEssayFeedbackEdit}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {isRegradeOpen && (
                <AIRegrade
                    subQuestion={examPaper
                        .flatMap(q => q.subQuestions)
                        .find(sq => sq.id === activeSubQuestionId)}
                    onClose={handleRegradeClose}
                    onSubmit={handleRegradeSubmit}
                />
            )}

            <ConfirmationModal
                isOpen={isConfirmationOpen}
                message="Are you sure you want to regrade this subquestion for all students with the new rubric and grading instructions?"
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleConfirmRegrade}
            >
                <p>This action cannot be undone.</p>
            </ConfirmationModal>

            <ConfirmationModal
                isOpen={isStudentAssignmentConfirmationOpen}
                message={`Assigning to ${newStudentAssignment?.name} (ID: ${newStudentAssignment?.id}) will unassign any submission that may be currently assigned to them.`}
                onClose={() => setIsStudentAssignmentConfirmationOpen(false)}
                onConfirm={handleConfirmStudentAssignment}
            >
                <p>Are you sure you want to proceed?</p>
            </ConfirmationModal>

            {isLoading && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-xl shadow-lg">
                        <p>Regrading in progress...</p>
                        <p>Expected wait time: 30 seconds</p>
                        {/* Add your loading animation here */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TDetails;

import React, {useEffect, useState, useRef, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext';
import SideBar from '../../components/SideBar';
import BigStepper from '../../components/Stepper.js';
import BigStepperLine from '../../components/StepperLine.js';
import AnswerUpload from '../../components/Teacher/AnswerUpload';
import RenderBatch from '../../components/Teacher/RenderBatch';
import TConstructor from '../../components/Teacher/TConstructor';
import TGrade from '../../components/Teacher/TGrade';
import TSubmissions from '../../components/Teacher/TSubmissions';
import TopBar from '../../components/TopBar';

const HomePage = () => {
    const [essayCriteria, setEssayCriteria] = useState([]);
    const [essayInstructions, setEssayInstructions] = useState('');
    const [showRubric, setShowRubric] = useState(false);
    const [assignment, setAssignment] = useState();
    const [submissions, setSubmissions] = useState();
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [pullQuestions, setPullQuestions] = useState(false);
    const [solRecent, setSolRecent] = useState(false);
    const [essayGradingInstructions, setEssayGradingInstructions] = useState('');
    const [edited, setEdited] = useState(false);
    const [topErrorMessage, setTopErrorMessage] = useState('');
    const {user} = useAuth();
    let {assignment_id} = useParams();
    const tGradeRef = useRef(null);

    const scrollToTGrade = () => {
        // console.log(tGradeRef.current)
        tGradeRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    const [showScroll, setShowScroll] = useState(false)

    const handleIntersection = useCallback((entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
            setShowScroll(false);
        } else {
            setShowScroll(true);
        }
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1,
        });

        const currentRef = tGradeRef.current;

        if (!currentRef) {
            const mutationObserver = new MutationObserver((mutations) => {
                if (tGradeRef.current) {
                    observer.observe(tGradeRef.current);
                    mutationObserver.disconnect();
                }
            });

            mutationObserver.observe(document.body, {
                childList: true,
                subtree: true,
            });

            return () => {
                mutationObserver.disconnect();
                observer.disconnect();
            };
        } else {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
            observer.disconnect();
        };
    }, [handleIntersection]);

    const getSubmissions = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/${assignment_id}/submissions/`,
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setAssignment(response.data.assignment);
            setSubmissions(response.data.submissions);
            // console.log(response.data.submissions);
            // console.log(response.data.assignment);
        } catch (error) {
            console.log('Failed to fetch submissions. Please try again.');
        }
    };
    useEffect(() => {
        getSubmissions();
    }, [updateTrigger]);

    return assignment ? (
        <div className="HomePageHeader">
            <div id="Main-container" className="flex flex-row">
                <div id="Left-Sidebar" className="fixed">
                    <SideBar activePage="Home"/>
                </div>
                <div id="Right-content" className="flex ml-[330px] mr-[30px] w-full">
                    <TopBar
                        assignment_in={assignment}
                        submissions_in={submissions}
                        setUpdateTrigger={setUpdateTrigger}
                        essayCriteria={essayCriteria}
                        essayInstructions={essayInstructions}
                        setPullQuestions={setPullQuestions}
                        edited={edited}
                        essayGradingInstructions={essayGradingInstructions}
                        showRubric={showRubric}
                        topErrorMessage={topErrorMessage}
                        setTopErrorMessage={setTopErrorMessage}
                    />

                    <div className={`flex flex-col mb-32 w-full ${topErrorMessage ? 'mt-44' : 'mt-36'}`}>
                        <div className="grid grid-cols-[84px_auto]">
                            <BigStepper stepNumber={1} top={5}/>
                            <TConstructor
                                assignment_id={assignment_id}
                                updateTrigger={updateTrigger}
                                setUpdateTrigger={setUpdateTrigger}
                                pullQuestions={pullQuestions}
                                setPullQuestions={setPullQuestions}
                                is_essay={assignment.is_essay}
                                is_published={assignment.is_assignment_published}
                                criteria={essayCriteria}
                                setCriteria={setEssayCriteria}
                                instructions={essayInstructions}
                                setInstructions={setEssayInstructions}
                                setEdited={setEdited}
                                edited={edited}
                                showRubric={showRubric}
                                setShowRubric={setShowRubric}
                                setSolRecent={setSolRecent}
                                solRecent={solRecent}
                                essayGradingInstructions={essayGradingInstructions}
                                setEssayGradingInstructions={setEssayGradingInstructions}
                            />
                        </div>

                        <BigStepperLine/>

                        {!assignment.is_essay && (
                            <div className="grid grid-cols-[84px_auto]">
                                <BigStepper stepNumber={2} top={0}/>
                                <AnswerUpload
                                    assignment_id={assignment_id}
                                    setUpdateTrigger={setUpdateTrigger}
                                    isPublished={assignment.is_assignment_published}
                                    setPullQuestions={setPullQuestions}
                                    setEdited={setEdited}
                                    setSolRecent={setSolRecent}
                                />
                            </div>
                        )}

                        {assignment.is_exam && (
                            <>
                                <BigStepperLine/>
                                <div className="grid grid-cols-[84px_auto]">
                                    <BigStepper stepNumber={3} top={0}/>
                                    <RenderBatch assignment_id={assignment_id} updateTrigger={updateTrigger}/>
                                </div>
                            </>
                        )}

                        {assignment.is_exam && (
                            <>
                                <BigStepperLine/>
                                <div className="grid grid-cols-[84px_auto]">
                                    <BigStepper stepNumber={4} top={0}/>
                                    <TSubmissions assignment_id={assignment_id} setUpdateTrigger={setUpdateTrigger}/>
                                </div>
                            </>
                        )}

                        <BigStepperLine/>
                        <div ref={tGradeRef} className="grid grid-cols-[84px_auto]">
                            <BigStepper
                                stepNumber={
                                    assignment ? (assignment.is_essay ? 2 : assignment.is_exam ? 5 : 3) : 3 // Default to 3 if assignment is undefined
                                }
                                top={0}
                                ht={'[50px]'}
                            />
                            <TGrade
                                assignment_in={assignment}
                                submissions_in={submissions}
                                setUpdateTrigger={setUpdateTrigger}
                            />
                        </div>
                    </div>
                    {assignment.is_assignment_published && showScroll ?
                        <button className="fixed h-[40px] w-[40px] right-[3%] bottom-[5%] z-40
            rounded-full border-[1px] border-black bg-white"
                                onClick={scrollToTGrade}>
                            <i className="fas fa-chevron-down m-auto"/>
                        </button> : null}
                </div>
            </div>
        </div>
    ) : null;
};

export default HomePage;

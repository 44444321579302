import React from "react";

const AssMeanMedian = ({
                           meanTotalScore,
                           medianTotalScore,
                           stdDeviationTotalScore,
                           highestTotalScore,
                           lowestTotalScore,
                           highestPossibleTotalScore,
                           lowestPossibleTotalScore
                       }) => {
    // Helper function to round values
    const roundValue = (value) => Math.round(value * 100) / 100;  // Rounds to two decimal places

    const StatItem = ({label, value}) => (
        <div className="flex flex-col items-center justify-center p-2 bg-white rounded-lg shadow h-full">
            <span className="text-xs font-semibold text-gray-500 uppercase">{label}</span>
            <span className="text-sm font-bold text-gray-800">{roundValue(value)}</span>
        </div>
    );

    return (
        <div className="mb-6">
            <h2 className="text-xl font-bold mb-3 ml-5">
                Student Total Score Statistics
            </h2>
            <div className="bg-gray-100 p-4 rounded-xl sexy-border">
                <div className="grid grid-cols-7 gap-2">
                    <StatItem label="MAX POSSIBLE" value={highestPossibleTotalScore}/>
                    <StatItem label="MIN POSSIBLE" value={lowestPossibleTotalScore}/>
                    <StatItem label="MAX" value={highestTotalScore}/>
                    <StatItem label="MIN" value={lowestTotalScore}/>
                    <StatItem label="MEAN" value={meanTotalScore}/>
                    <StatItem label="MED" value={medianTotalScore}/>
                    <StatItem label="STD DEV" value={stdDeviationTotalScore}/>
                </div>
            </div>
        </div>
    );
};

export default AssMeanMedian;
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import * as authService from './AuthService.js';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({ isAuthenticated: false, loginRecent: false });
  const latestSessionToken = useRef(null);

  useEffect(() => {
    const checkCookiesAndUpdateSession = async () => {
      const sessionToken = Cookies.get('session_token');
      const userId = Cookies.get('user_id');
      const userEmail = Cookies.get('user_email');
      const isTeacher = Cookies.get('is_teacher');
      const firstName = Cookies.get('first_name');
      const lastName = Cookies.get('last_name');
      const isVerified = Cookies.get('is_verified');

      if (sessionToken && userId && userEmail) {
        setUser({
          user: {
            id: userId,
            email: userEmail,
            first_name: firstName,
            last_name: lastName,
            is_verified: isVerified === 'true',
            is_teacher: isTeacher === 'true'
          },
          is_teacher: isTeacher === 'true',
          isAuthenticated: true,
          loginRecent: false,
          session_token: sessionToken
        });
        latestSessionToken.current = sessionToken;
      }
    };
    checkCookiesAndUpdateSession();
  }, []);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      async config => {
        // Skip session check for login and logout endpoints
        if (config.url.includes('/courses-lite/')) {
          await waitForSession();
        }
        return config;
      },
      error => Promise.reject(error)
    );

    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  const login = async (email, password) => {
    const userData = await authService.login(email, password);
    if ('error' in userData) {
      return userData.error;
    }

    setUser({
      ...userData,
      isAuthenticated: true,
      loginRecent: true,
      session_token: userData.session_token
    });

    latestSessionToken.current = userData.session_token;

    Cookies.set('session_token', userData.session_token, { expires: 1 });
    Cookies.set('user_id', userData.user.id, { expires: 1 });
    Cookies.set('user_email', userData.user.email, { expires: 1 });
    Cookies.set('is_teacher', String(userData.user.is_teacher), { expires: 1 });
    Cookies.set('first_name', userData.user.first_name, { expires: 1 });
    Cookies.set('last_name', userData.user.last_name, { expires: 1 });
    Cookies.set('is_verified', String(userData.user.is_verified), { expires: 1 });

    return '';
  };

  const logout = async () => {
    if (user.session_token) {
      await authService.logout(user.session_token);
    }

    Cookies.remove('session_token');
    Cookies.remove('user_id');
    Cookies.remove('user_email');
    Cookies.remove('is_teacher');
    Cookies.remove('first_name');
    Cookies.remove('last_name');
    Cookies.remove('is_verified');

    setUser({ isAuthenticated: false });
    latestSessionToken.current = null;

    window.location.href = '/login';
  };

  const signUp = async (firstName, lastName, email, password, is_teacher) => {
    const userData = await authService.signUp(firstName, lastName, email, password, is_teacher);
    if ('error' in userData) {
      return { error: userData.error, type: userData.type };
    }
    return { message: userData.message, type: 'success' };
  };

  const waitForSession = () => {
    return new Promise(resolve => {
      const checkSession = () => {
        if (latestSessionToken.current) {
          resolve();
        } else {
          setTimeout(checkSession, 10);
        }
      };
      checkSession();
    });
  };

  const updateUser = async () => {
    const response = await authService.getUser(user.user.id, user.session_token);
    if ('error' in response) {
      console.log(response.error);
    } else {
      setUser({
        ...user,
        user: response,
        session_token: response.session_token
      });

      latestSessionToken.current = response.session_token;

      Cookies.set('session_token', response.session_token, { expires: 1 });
      Cookies.set('user_id', response.id, { expires: 1 });
      Cookies.set('user_email', response.email, { expires: 1 });
      Cookies.set('is_teacher', response.is_teacher, { expires: 1 });
      Cookies.set('first_name', response.first_name, { expires: 1 });
      Cookies.set('last_name', response.last_name, { expires: 1 });
      Cookies.set('is_verified', response.is_verified, { expires: 1 });
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, login, signUp, logout, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

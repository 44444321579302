import React, {useEffect, useState} from "react";
import {
    BarChart,
    Bar,
    Rectangle,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label
} from "recharts";

const TScoreFrequency = ({scores, max}) => {
    const [chartData, setChartData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const createFrequencyData = () => {
        const scoreCounts = {};
        scores.forEach((score) => {
            if (scoreCounts[score]) {
                scoreCounts[score] += 1;
            } else {
                scoreCounts[score] = 1;
            }
        });
        const result = [];

        // Create the histogram buckets
        for (let i = 0; i <= 10; i++) {
            result.push({"Score %": `${i * 10}%`, "# of Students": 0});
        }

        // console.log("scoreCounts");
        // console.log(scoreCounts);
        // Calculate the percentage and populate the histogram buckets
        Object.entries(scoreCounts).forEach(([score, count]) => {
            const percentage = (score / max) * 100;
            const bucketIndex = Math.floor(percentage / 10);
            result[bucketIndex]["# of Students"] += count;
        });
        setChartData(result);
        // console.log("scoreCounts");
        // console.log(scoreCounts);
        //
        // console.log("result");
        // console.log(result);
    };

    useEffect(() => {
        try {
            createFrequencyData();
        } catch {
            console.log("error in creating frequency data");
        }
    }, [scores]);
    const handleMouseEnter = (data, index) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };

    return (
        <div>
            <BarChart
                width={1000}
                height={500}
                data={chartData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey="Score %" tickCount={20}>
                    <Label value="Score (%)" offset={-5} position="insideBottom"/>
                </XAxis>
                <YAxis dataKey={"# of Students"}>
                    <Label value="# of Students" angle={-90} offset={10} position="insideLeft"/>
                </YAxis>
                <Tooltip/>
                {/* <Legend /> */}
                <Bar type="monotone" dataKey="# of Students" fill="#FFCC80" barSize={75} onMouseEnter={handleMouseEnter}
                     onMouseLeave={handleMouseLeave}>
                    {" "}
                    {chartData.map((entry, index) => (
                        <Rectangle key={`cell-${index}`} fill={index === activeIndex ? "#ca2929" : "#ca2929"}/>
                    ))}
                </Bar>
            </BarChart>
        </div>
    );
};

export default TScoreFrequency;

import React from 'react';
import { useParams } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import AddDrop from '../../components/Teacher/AddDrop';
import TAssignments from '../../components/Teacher/TAssignments';

const HomePage = () => {
  let { class_id } = useParams();

  return (
    <div id="Main-container" className="flex flex-row w-full min-h-screen">
      <div
        id="Left-Sidebar"
        className="sticky top-0 h-screen overflow-y-auto w-side-bar-size flex-shrink-0"
      >
        <SideBar activePage="Home" />
      </div>
      <div className="flex flex-grow overflow-x-hidden">
        {' '}
        {/* This ensures content doesn't overflow horizontally */}
        <div id="Right-content" className="flex-grow overflow-y-auto">
          {' '}
          {/* Allow vertical scrolling if needed */}
          <TAssignments class_id={class_id} />
        </div>
        <div
          id="Student addition/Deletion"
          className="flex-none w-[330px] bg-gray-50 overflow-y-auto"
        >
          {' '}
          {/* Allow vertical scrolling if needed */}
          <AddDrop class_id={class_id} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;

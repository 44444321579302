import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {apiUrl, gradeApi} from '../../api.js';
import {useAuth} from '../../auth/AuthContext.js';

function SPublished({assignment, class_id}) {
    const {user} = useAuth();
    const [message, setMessage] = useState('');
    const [submissionData, setSubmissionData] = useState({});
    const [feedbackItems, setFeedbackItems] = useState({});
    const [renderedImages, setRenderedImages] = useState({});
    const [groupStudents, setGroupStudents] = useState([]);

    useEffect(() => {
        const renderImages = async () => {
            const newRenderedImages = {};
            for (const question of assignment?.questions || []) {
                for (const subQuestion of question.subQuestions) {
                    if (subQuestion.latex_image_render_s3_key) {
                        newRenderedImages[`subquestion-${subQuestion.id}`] = await renderLatexImage(
                            subQuestion.latex_image_render_s3_key
                        );
                    }
                }
            }
            setRenderedImages(newRenderedImages);
        };

        if (!assignment?.is_essay) {
            renderImages();
        }
    }, [assignment]);

    useEffect(() => {
        const getAssignmentSubmission = async () => {
            try {
                const response = await gradeApi.get({
                    path: `/api/course/${class_id}/assignment/${assignment.id}/`,
                    config: {
                        headers: {
                            Authorization: `Bearer ${user.session_token}`
                        }
                    }
                });
                const data = response.data;
                setSubmissionData(data);
                setFeedbackItems(data.feedback || {}); // Add a fallback to an empty object
            } catch (error) {
                setSubmissionData({submitted: false});
                setMessage(
                    error.response?.data?.error || 'An error occurred while fetching the submission.'
                );
            }
        };

        getAssignmentSubmission();
    }, []);

    useEffect(() => {
        fetchGroupStudents();
    }, [assignment.id]);

    const renderLatexImage = imageS3Key => {
        const dpi = 350; // Hardcoded DPI value
        const img = new Image();
        img.src = `${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${imageS3Key}`;

        return new Promise(resolve => {
            img.onload = () => {
                const widthInches = img.naturalWidth / dpi;
                const heightInches = img.naturalHeight / dpi;
                const widthCm = widthInches * 2.54;
                const heightCm = heightInches * 2.54;

                resolve(
                    <img
                        src={img.src}
                        alt="LaTeX rendered question"
                        style={{
                            width: `${widthCm}cm`,
                            height: `${heightCm}cm`
                        }}
                    />
                );
            };
        });
    };

    const hasRubricItem = (subquestionId, rubricId) => {
        if (!feedbackItems || !(subquestionId in feedbackItems)) {
            return false;
        }
        if (subquestionId in feedbackItems) {
            let rubricItems = feedbackItems[subquestionId]?.rubric_items;
            for (let i = 0; i < rubricItems.length; i++) {
                if (rubricItems[i].id === rubricId) {
                    return true;
                }
            }
        }
        return false;
    };

    const getFeedback = subquestionId => {
        if (subquestionId in feedbackItems) {
            return feedbackItems?.[subquestionId]?.feedback || '';
        }
    };

    const handleRegradeReq = subquestionId => {
        setFeedbackItems(prevFeedbackItems => ({
            ...prevFeedbackItems,
            [subquestionId]: {
                ...prevFeedbackItems[subquestionId],
                regrade_request: true,
                regrade_text: feedbackItems[subquestionId]?.regrade_text || '',
                edit_mode: true
            }
        }));
    };
    const fetchGroupStudents = async () => {
        try {
            const response = await gradeApi.get({
                path: `/api/assignment/get_students/${assignment.id}/student/${user.user.id}`,  // Make sure to use assignment.id, not aid
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            });
            setGroupStudents(response.data);  // Assuming response.data is an array
        } catch (error) {
            console.error('Error fetching group students:', error);
            // Consider setting an error state here instead of using setError
        }
    };
    const handleRegradeReqSave = subquestionId => {
        const regradeReason = feedbackItems[subquestionId]?.regrade_text || '';
        const submissionID = submissionData.id;

        gradeApi
            .post({
                path: `/api/assignment/${assignment.id}/submissions/${submissionID}/regrade-request/`,
                body: {
                    subquestionId: subquestionId,
                    regradeRequest: true,
                    regradeText: regradeReason
                },
                config: {
                    headers: {
                        Authorization: `Bearer ${user.session_token}`
                    }
                }
            })
            .catch(error => {
                // console.log('Error updating regrade request:', error);
            })
            .then(response => {
                if (response) {
                    setFeedbackItems(prevFeedbackItems => ({
                        ...prevFeedbackItems,
                        [subquestionId]: {
                            ...prevFeedbackItems[subquestionId],
                            regrade_request: true,
                            regrade_text: regradeReason,
                            edit_mode: false
                        }
                    }));
                    // console.log('Regrade request updated successfully:', response.data);
                }
            });
    };

    const SortRubricItemsAndDisplay = ({subQuestion}) => {
        subQuestion.rubricItems.sort((a, b) => {
            if (a.value == b.value) {
                return 0;
            }
            return a.value > b.value ? -1 : 1;
        })
        return subQuestion.rubricItems.map((rubricItem, rubricIndex) => (
            <div
                key={rubricIndex}
                className={`flex p-2 text-sm rounded-3xl items-center ${hasRubricItem(subQuestion.id, rubricItem.id)
                    ? 'border border-black bg-orange-200'
                    : 'border border-black bg-white-200'
                }`}
            >
                <div className="mr-1 ml-1 w-[30px] h-[30px] p-1 rounded-full text-center
      border-[1px] border-black flex-none bg-white">
                    {`${rubricItem.value}`}
                </div>
                <div className="text-left m-auto ml-2">
                    {`${rubricItem.descriptor}`}
                </div>
            </div>
        ))
    };

    const handleRegradeReqCancel = subquestionId => {
        setFeedbackItems(prevFeedbackItems => ({
            ...prevFeedbackItems,
            [subquestionId]: {
                ...prevFeedbackItems[subquestionId],
                regrade_request: false,
                regrade_text: '',
                edit_mode: false
            }
        }));
    };

    const handleEditRegradeReq = subquestionId => {
        setFeedbackItems(prevFeedbackItems => ({
            ...prevFeedbackItems,
            [subquestionId]: {
                ...prevFeedbackItems[subquestionId],
                edit_mode: true
            }
        }));
    };

    if (!submissionData) {
        return <h1>loading</h1>;
    }

    return (
        <div className="flex flex-col mt-5 w-full overflow-x-hidden">
            {/* navigation trail */}
            <div className="flex flex-col fixed w-full max-w-screen-xl py-7 z-40 bg-white px-5">
                <div className="flex items-center space-x-2 mb-4">
                    <Link to="/sclasses">
                        <div className="size-10 flex items-center justify-center bg-gray-50 rounded-full">
                            <img src="/assets/sidebar/menu-home.png" className="size-5" alt="Home"/>
                        </div>
                    </Link>
                    <Link to="/sclasses">
                        <button>
                            <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                        </button>
                    </Link>
                    <Link to="/sclasses" className="text-black text-lg font-medium ml-4">
                        My Classes
                    </Link>
                    <Link to={`/sassignments/${assignment?.course_id}`}>
                        <button>
                            <img src="/assets/back_arrow.png" className="size-6" alt="Back"/>
                        </button>
                    </Link>
                    <Link
                        to={`/sassignments/${assignment?.course_id}`}
                        className="text-black text-lg font-medium ml-4"
                    >
                        {assignment?.course?.name}
                    </Link>
                </div>
            </div>

            {/* main content */}
            <div className="flex h-[calc(100vh-9rem)] mt-24">
                {/* student work */}
                <div className="w-1/2 p-4">
                    {!submissionData.submitted ? (
                        <div className="w-full h-full flex flex-col items-center justify-center bg-gray-100 rounded-xl">
                            <img src="/assets/folder.png" alt="Missing Submission" className="w-24 h-24 mb-4"/>
                            <p className="text-xl font-semibold text-gray-700">Missing Submission</p>
                        </div>
                    ) : assignment?.is_essay ? (
                        <div className="w-full h-full overflow-auto bg-white border border-gray-300 p-4">
                            <pre className="whitespace-pre-wrap font-sans">{submissionData.essay_text}</pre>
                        </div>
                    ) : (
                        <div className="w-full h-full rounded-xl overflow-hidden bg-gray-200 shadow-inner">
                            <iframe
                                src={`${apiUrl}/api/uploads/${submissionData?.file?.filename}#zoom=70`}
                                title="PDF Viewer"
                                className="w-full h-full bg-gray-200"
                                style={{border: 'none'}}
                            />
                        </div>
                    )}
                </div>

                {/* feedback */}
                <div className="w-1/2 overflow-y-auto p-8">
                    {submissionData?.submitted && (
                        <h2 className="text-3xl font-bold mb-4">
                            Score: {submissionData?.grade}/{assignment?.max_score}
                        </h2>
                    )}
                    <div>{submissionData?.late ? '(Late submission)' : '(On time)'}</div>
                    <div>
                        <p>Group Members:</p>
                        <p>
                            {Array.isArray(groupStudents) && groupStudents.length > 0
                                ? groupStudents.map((student, index) => (
                                    <span key={index}>
                      {student[0]} ({student[1]})
                                        {index < groupStudents.length - 1 ? ', ' : ''}
                    </span>
                                ))
                                : 'No students to display'}
                        </p>
                    </div>

                    {assignment?.questions?.map((question, questionIndex) => (
                        <div key={questionIndex} className="mb-8">
                            <h2 className="text-xl font-bold mb-4">
                                {assignment.is_essay
                                    ? `Question ${questionIndex + 1}: ${question.description}`
                                    : `Question ${questionIndex + 1}:`}
                            </h2>
                            {!assignment.is_essay && renderedImages[`question-${question.id}`]}

                            {question.subQuestions.map((subQuestion, subQuestionIndex) => (
                                <div key={subQuestionIndex} className="mb-6">
                                    {assignment.is_essay ? (
                                        <h3 className="font-semibold mb-2">
                                            {`${String.fromCharCode(97 + subQuestionIndex)}) ${subQuestion.question}`}
                                        </h3>
                                    ) : (
                                        renderedImages[`subquestion-${subQuestion.id}`]
                                    )}
                                    <div className="flex flex-col gap-2 mb-2 mt-3">
                                        <SortRubricItemsAndDisplay subQuestion={subQuestion}/>
                                    </div>
                                    <div>{getFeedback(subQuestion.id)}</div>
                                    {submissionData.submitted &&
                                        (feedbackItems[subQuestion.id]?.regrade_request &&
                                        !feedbackItems[subQuestion.id]?.edit_mode ? (
                                            <div>
                                                <div className="mt-4 bg-orange-100 p-4 rounded-xl">
                                                    <h4 className="font-semibold mb-2">
                                                        Regrade Request: {feedbackItems[subQuestion.id]?.regrade_text}
                                                    </h4>
                                                    <button
                                                        className="mt-1 mr-2 px-5 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                                                        onClick={() => handleEditRegradeReq(subQuestion.id)}
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        className="mt-1 px-3 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-white hover:bg-gray-300 text-black"
                                                        onClick={() => handleRegradeReqCancel(subQuestion.id)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        ) : feedbackItems[subQuestion.id]?.edit_mode ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    placeholder="Reason for regrade request"
                                                    value={feedbackItems[subQuestion.id]?.regrade_text || ''}
                                                    className="mt-2 border rounded-xl px-4 py-2 mb-2 w-full focus:border-orange-200 focus:ring-gray-400 focus:outline-none"
                                                    onChange={e =>
                                                        setFeedbackItems(prevFeedbackItems => ({
                                                            ...prevFeedbackItems,
                                                            [subQuestion.id]: {
                                                                ...prevFeedbackItems[subQuestion.id],
                                                                regrade_text: e.target.value
                                                            }
                                                        }))
                                                    }
                                                />
                                                <button
                                                    className="mt-1 mr-2 px-3 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-orange-200 hover:bg-orange-300 text-black"
                                                    onClick={() => handleRegradeReqSave(subQuestion.id)}
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    className="mt-1 px-3 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-white hover:bg-gray-300 text-black"
                                                    onClick={() => handleRegradeReqCancel(subQuestion.id)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        ) : (
                                            <button
                                                className="mt-3 px-4 py-2 rounded-3xl border-[1px] border-black text-smallish font-medium transition-all duration-300 desktop:hover:-translate-y-[0.5px] desktop:hover:shadow bg-white hover:bg-gray-300 text-black"
                                                onClick={() => handleRegradeReq(subQuestion.id)}
                                            >
                                                Request Regrade
                                            </button>
                                        ))}
                                </div>
                            ))}
                        </div>
                    ))}
                    {assignment?.is_essay && submissionData.submitted && (
                        <div className="w-full h-full overflow-auto bg-white border border-gray-300 p-4">
                            <style>{`
                            .feedback-content h2 {
                                font-size: 20;
                                font-weight: var(--h2-font-weight);
                                margin: var(--h2-margin);
                            }
                            .feedback-content p {
                                margin: var(--p-margin);
                            }
                            .feedback-content ul {
                                list-style-type: disc;
                                padding: var(--ul-padding);
                                margin: var(--ul-margin);
                            }
                            .feedback-content li {
                                margin: var(--li-margin);
                            }
                        `}</style>
                            <div
                                dangerouslySetInnerHTML={{__html: submissionData?.file?.filename}}
                                className="feedback-content"
                                style={{
                                    '--h2-font-size': '1.5em',
                                    '--h2-font-weight': 'bold',
                                    '--h2-margin': '1em 0 0.5em',
                                    '--p-margin': '0 0 1em',
                                    '--ul-padding': '0 0 0 2em',
                                    '--ul-margin': '0 0 1em',
                                    '--li-margin': '0 0 0.5em'
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SPublished;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { gradeApi } from '../../../api.js';
import { useAuth } from '../../../auth/AuthContext.js';

const TStatistics = () => {
  const { user } = useAuth();
  const [classes, setClasses] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const getClasses = async () => {
    try {
      const response = await gradeApi.get({
        path: `/api/users/${user.user.id}/courses-lite/`,
        config: {
          headers: {
            Authorization: `Bearer ${user.session_token}`
          }
        }
      });
      setClasses(response.data.courses);
    } catch (error) {
      setErrorMessage('Failed to fetch classes. Please try again.');
      setIsErrorVisible(true);
    }
  };

  useEffect(() => {
    getClasses();
  }, []);

  return (
    <div id="Statistics" className="flex flex-col space-y-4 w-full p-8">
      <div id="Overview" className="flex flex-row space-x-full w-full justify-between">
        <span className="text-black text-xl font-medium">Statistics</span>
      </div>
      <div className="bg-white sexy-border p-6">
        {isErrorVisible && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {errorMessage}
          </div>
        )}
        <table className="w-full border-collapse">
          <thead>
            <tr className="border-b border-border">
              <th className="py-4 text-left text-gray custom-16">Class</th>
              <th className="py-4 text-left text-gray custom-16">Class Code</th>
              <th className="py-4 text-left text-gray custom-16">Number of Students</th>
            </tr>
          </thead>
          {classes.length > 0 ? (
            <tbody>
              {classes.map(value => (
                <tr key={value.id} className="border-b border-border">
                  <td className="text-left text-black custom-16-semi py-4">
                    <Link to={`/statistics/${value.id}`} className="hover:underline">
                      {value.name}
                    </Link>
                  </td>
                  <td className="text-left text-black custom-16-med py-4">{value.code}</td>
                  <td className="text-left text-black custom-16-med py-4">{value.num_students}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="3" className="text-center py-4 text-gray-500">
                  No classes available
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default TStatistics;

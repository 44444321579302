import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import DragAndDropUpload from '../DragDropQuestion.js';

import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import { DndContext, rectIntersection } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { TSubQuestionColumn } from './TSubquestionColumn.js';

export const TQuestion = ({
  examPaper,
  question,
  isPublished,
  deleteQuestion,
  handleDescriptionChange,
  handleImageUpload,
  deleteImage,
  handleImageSizeChange,
  handlePreviewClick,
  previews,
  handleQuestionSizingChange,
  deleteRubricItem,
  deleteSubQuestion,
  handleQuestionTypeChange,
  handleQuestionChange,
  handleRubricDescriptorChange,
  handleRubricValueChange,
  addRubricItem,
  handleWeightChange,
  handleMCQOptionChange,
  handleMCQCorrectAnswer,
  deleteMCQOption,
  addMCQOption,
  handleSolutionChange,
  addSubQuestion,
  focusedQuestion,
  setFocusedQuestion,
  setSubQuestions,
  triggerSave,
  saveProgress,
  focusedSubQuestion,
  setFocusedSubQuestion
}) => {
  const { isDragging, setNodeRef, attributes, listeners, transform, transition } = useSortable({
    id: question.id,
    animateLayoutChanges: () => false
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  if (isDragging) {
    setFocusedQuestion(true);
  }

  const getTaskPos = (id, subQuestions) =>
    subQuestions.findIndex(subQuestion => subQuestion.id === id);

  const updateSubQuestionIds = subQuestions => {
    return subQuestions.map((subQuestion, index) => ({
      ...subQuestion,
      id: index + 1 // Assign new ID based on position
    }));
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id === over.id) {
      return;
    }

    // Find the question that contains the dragged sub-question
    const question = examPaper.find(q => q.subQuestions.some(sq => sq.id === active.id));

    if (!question) {
      return;
    }

    const originalPos = getTaskPos(active.id, question.subQuestions);
    const newPos = getTaskPos(over.id, question.subQuestions);

    const updatedSubQuestions = arrayMove(question.subQuestions, originalPos, newPos);

    if (focusedSubQuestion !== '') {
      const originalFocusedPos = getTaskPos(focusedSubQuestion, question.subQuestions);
      const newFocusedPos = updatedSubQuestions.findIndex(
        (subQuestion, index) => index === originalFocusedPos
      );
      setFocusedSubQuestion(updatedSubQuestions[newFocusedPos].id);
    }

    // Update the subQuestions for the specific question
    setSubQuestions(question.id, updateSubQuestionIds(updatedSubQuestions)); // Update IDs after reordering
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="rounded-3xl p-4 mb-4 bg-gray-50 shadow-sm ${disabledStyle}"
    >
      {isPublished && <div className="relative inset-0 bg-opacity-10 z-10" />}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className="size-4 flex items-center justify-center">
            <button disabled={isPublished} onClick={() => deleteQuestion(question.id)}>
              <img src="/assets/trash.png" className="size-4" alt="Delete question" />
            </button>
          </div>
          <div className="font-bold flex items-center text-base">Question {question.id}</div>
        </div>
        <div className="flex items-center space-x-0">
          <div className="size-10 flex items-center justify-center ${disabledStyle}">
            <button
              {...(isPublished ? {} : { ...listeners, ...attributes })}
              disabled={isPublished}
            >
              <img src="/assets/list.png" className="size-10" />
            </button>
          </div>
          <div className="size-10 flex items-center justify-center ${disabledStyle}">
            <button
              onClick={() => setFocusedQuestion(focusedQuestion === question.id ? '' : question.id)}
            >
              <img
                src={
                  focusedQuestion === question.id
                    ? '/assets/arrow-up.png'
                    : '/assets/arrow-down.png'
                }
                className="size-10"
              />
            </button>
          </div>
        </div>
      </div>

      {question.id === focusedQuestion && (
        <>
          <textarea
            value={question.description}
            placeholder="Description"
            className="sexy-border rounded-lg mb-4 w-full focus:ring-0 focus:sexy-border focus:rounded-lg h-36 mt-4"
            disabled={isPublished}
            onChange={event => handleDescriptionChange(question.id, event)}
          />
          <div className="mb-2 text-sm">Optional: Upload an Image or Drawing</div>
          <div className="flex items-center mb-4">
            {!question.imageS3Key && (
              <div className="mr-3">
                <DragAndDropUpload
                  handleImageUpload={event => handleImageUpload(event, question.id)}
                  isPublished={isPublished}
                />
              </div>
            )}
            {question.imageS3Key && (
              <div className="relative flex items-center">
                <img
                  src={`${process.env.REACT_APP_S3_AWS_IMAGES_BUCKET_URL}/${question.imageS3Key}`}
                  alt="Question Image"
                  className="w-20 h-20 object-cover"
                />
                <button
                  disabled={isPublished}
                  className="ml-2"
                  onClick={() => deleteImage(question.id)}
                >
                  <img src="/assets/trash.png" className="size-4" alt="Delete question image" />
                </button>
              </div>
            )}
          </div>
          {question.imageS3Key && (
            <div className="flex items-center mb-4">
              <div className="mr-3 mb-2 text-sm">Image size:</div>
              <div className="input-entry-box font-normal px-2 w-16">
                <input
                  type="number"
                  min="1"
                  max="100"
                  value={question.imageSize}
                  className="actual-input text-black"
                  disabled={isPublished}
                  onChange={event => handleImageSizeChange(question.id, null, event)}
                />
              </div>
              <div className="ml-2">%</div>
            </div>
          )}
          <DndContext
            collisionDetection={rectIntersection}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
          >
            <TSubQuestionColumn
              question={question}
              subQuestions={question.subQuestions}
              isPublished={isPublished}
              handleImageUpload={handleImageUpload}
              deleteImage={deleteImage}
              handleImageSizeChange={handleImageSizeChange}
              handlePreviewClick={handlePreviewClick}
              previews={previews}
              handleQuestionSizingChange={handleQuestionSizingChange}
              deleteRubricItem={deleteRubricItem}
              deleteSubQuestion={deleteSubQuestion}
              handleQuestionTypeChange={handleQuestionTypeChange}
              handleQuestionChange={handleQuestionChange}
              handleRubricDescriptorChange={handleRubricDescriptorChange}
              handleRubricValueChange={handleRubricValueChange}
              addRubricItem={addRubricItem}
              handleWeightChange={handleWeightChange}
              handleMCQOptionChange={handleMCQOptionChange}
              handleMCQCorrectAnswer={handleMCQCorrectAnswer}
              deleteMCQOption={deleteMCQOption}
              addMCQOption={addMCQOption}
              handleSolutionChange={handleSolutionChange}
              focusedSubQuestion={focusedSubQuestion}
              setFocusedSubQuestion={setFocusedSubQuestion}
            />
          </DndContext>
          <button
            disabled={isPublished}
            className="less-sexy-button h-[38px]"
            onClick={() => addSubQuestion(question.id)}
          >
            Add Sub-question
          </button>
        </>
      )}
    </div>
  );
};

import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, {useEffect, useState} from 'react';
import {useAuth} from '../../../auth/AuthContext.js';

const SubQuesPointsTable = ({
                                scoresByQuestionID,
                                questions,
                                question_number,
                                subquestion_number
                            }) => {
    const [tableData, setTableData] = useState([]);
    const {user} = useAuth();

    const prepareDataForTable = () => {
        const tableData = [];
        // console.log('question_number');
        // console.log(question_number);
        const questionIndex = question_number - 1;
        const subQuestionIndex = subquestion_number - 1;

        // console.log('undefined erro:');
        // console.log(questions[`0`]);
        const question = questions[`${questionIndex}`];
        const subQuestion = question.subQuestions[subQuestionIndex];
        const rubricItems = subQuestion.rubricItems.sort((a, b) => b.value - a.value);
        const scores = scoresByQuestionID[subQuestion.id] || [];

        rubricItems.forEach(rubricItem => {
            const points = rubricItem.value;
            const descriptor = rubricItem.descriptor;
            const percentageOfStudents =
                (scores.filter(score => score === points).length / scores.length) * 100;

            tableData.push({
                rubric: descriptor,
                points: points,
                percentageOfStudents: isNaN(percentageOfStudents) ? 0 : percentageOfStudents.toFixed(2)
            });
        });

        setTableData(tableData);
    };

    useEffect(() => {
        // console.log('--------------------');
        // console.log('scoresByQuestionID');
        // console.log(scoresByQuestionID);
        // console.log('--------------------');
        // console.log('questions');
        // console.log(questions);
        // if (scoresByQuestionID.length > 0 && questions.length > 0) {
        // console.log(">0");
        prepareDataForTable();
        // }
    }, [scoresByQuestionID, questions, question_number, question_number]);

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        }
    }));

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0
        }
    }));

    return (
        <div>
            {/* TODO: Remove hardcoded margins */}
            <div style={{margin: '30px'}}>
                {/* <table className="w-full border-collapse">
        <thead>
          <tr className="border-b border-border">
            <th className="py-4 text-left text-gray custom-16">Rubric</th>
            <th className="py-4 text-left text-gray custom-16">Points</th>
            <th className="py-4 text-left text-gray custom-16">% of Students</th>
          </tr>
        </thead>
        <tbody>
          {tableData.length > 0 ? (
            tableData.map((row, index) => (
              <tr key={index} className="border-b border-border py-6">
                <td className="text-left text-black custom-16 table-cell">{row.rubric}</td>
                <td className="text-left text-black custom-16 table-cell">{row.points}</td>
                <td className="text-left text-black custom-16 table-cell">{row.percentageOfStudents}%</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="text-center py-6">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table> */}
                <TableContainer component={Paper}>
                    <Table className="w-full border-collapse" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Rubric</StyledTableCell>
                                <StyledTableCell>Points</StyledTableCell>
                                <StyledTableCell>% of Students</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {tableData.length > 0 ? (
                            <TableBody>
                                {tableData.map((value, index) => (
                                    <StyledTableRow key="{row.name}">
                                        <StyledTableCell>{value.rubric}</StyledTableCell>
                                        <StyledTableCell>{value.points}</StyledTableCell>
                                        <StyledTableCell>{value.percentageOfStudents} %</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        ) : (
                            <tbody>
                            <tr>
                                <td colSpan="5">No data available</td>
                            </tr>
                            </tbody>
                        )}
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default SubQuesPointsTable;

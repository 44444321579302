import React from 'react';
import SideBar from "../../components/SideBar";
import TClassStats from "../../components/Teacher/Statistics/TClassStats";

const TClassStatsPage = () => {
    return (
        <div id="Main-container" className="flex flex-row w-full min-h-screen">
            <div id="Left-Sidebar" className="sticky top-0 h-screen overflow-y-auto w-side-bar-size flex-shrink-0">
                <SideBar activePage="Statistics" />
            </div>
            <div className="flex flex-grow overflow-x-hidden">
                <div id="Right-content" className="flex-grow overflow-y-auto p-8">
                    <div className="max-w-7xl mx-auto">
                        <TClassStats />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TClassStatsPage;
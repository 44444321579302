import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {gradeApi} from '../api.js';
import '../App.css';

const GradeWizHeader = () => {
    return (
        <div className="">
            <div className="flex items-center space-x-2 pb-3">
                <div className="size-12 flex items-center justify-center">
                    <img src="/assets/logo_icon.png" alt="GradeWiz Icon" className="size-12"/>
                </div>
                <span className="text-minilg font-medium text-gray-950">GradeWiz</span>
            </div>
        </div>
    );
};

const Reset = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [success, setSuccess] = useState(false);
    let {password_change_token} = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await gradeApi.post({
                path: `/api/change-password/${password_change_token}/`,
                body: {
                    password: password,
                    password_confirmation: confirmPassword
                }
            });
            if (response.data.type === "error") {
                setError(response.data.message)
                setSuccess(false);
            } else {
                setSuccess(true);
                setError('Successfully changed password. Taking you to login...');
                setTimeout(() => navigate('/login'), 3000);
            }
        } catch (error) {
            setError(error.response?.data?.error || 'An error occurred. Please try again.');
            setSuccess(false);
        }
    };

    const isFormValid = password && confirmPassword;

    const [passwordFocused, setPasswordFocused] = useState(false);
    const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);

    return (
        <div className="h-screen">
            <div className="bg-backgray h-screen w-screen pt-14 flex flex-col justify-between">
                <div className="flex items-center justify-center w-[325px] mx-auto p-6 sexy-border h-[485px]">
                    <div
                        className="absolute w-[350px] mx-auto p-6 sexy-border bg-white mt-6 flex flex-col justify-between h-[485px]">
                        <div>
                            <div className="mb-10">
                                <GradeWizHeader/>
                            </div>
                            <h2 className="custom-title-sm text-black mb-5 font-bold">Reset Password</h2>
                            <form className="" onSubmit={handleSubmit}>
                                <div
                                    className={`flex items-center input-entry-box ${success ? 'border-green-500' : error !== '' ? 'border-red-500' : ''}`}
                                    onFocus={() => {
                                        setPasswordFocused(true);
                                        setError('');
                                        setSuccess(false);
                                    }}
                                    onBlur={() => setPasswordFocused(false)}
                                >
                                    <div className="size-9 mr-0.5 flex items-center justify-center">
                                        <img
                                            src="/assets/authentication/password.png"
                                            className={
                                                passwordFocused || error !== '' || success ? 'size-5' : 'size-5 opacity-30'
                                            }
                                        />
                                    </div>
                                    <input
                                        type="password"
                                        value={password}
                                        className="actual-input mr-2.5"
                                        placeholder="New password"
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                </div>
                                <div
                                    className={`flex items-center input-entry-box mt-4 ${success ? 'border-green-500' : error !== '' ? 'border-red-500' : ''}`}
                                    onFocus={() => {
                                        setConfirmPasswordFocused(true);
                                        setError('');
                                        setSuccess(false);
                                    }}
                                    onBlur={() => setConfirmPasswordFocused(false)}
                                >
                                    <div className="size-9 mr-0.5 flex items-center justify-center">
                                        <img
                                            src="/assets/authentication/password.png"
                                            className={
                                                confirmPasswordFocused || error !== '' || success
                                                    ? 'size-5'
                                                    : 'size-5 opacity-30'
                                            }
                                        />
                                    </div>
                                    <input
                                        type="password"
                                        value={confirmPassword}
                                        className="actual-input mr-2.5"
                                        placeholder="Confirm new password"
                                        onChange={e => setConfirmPassword(e.target.value)}
                                    />
                                </div>
                                {error !== '' && (
                                    <div
                                        className={`you-dirty-dog ${success ? 'text-green-500' : 'text-red-500'} text-base`}
                                    >
                                        {error}
                                    </div>
                                )}
                                <button
                                    type="submit"
                                    disabled={!isFormValid}
                                    className={`mt-3.5 bg-black text-white text-sm font-medium py-2 px-4 rounded-full transition-all duration-300 w-full ${isFormValid ? 'bg-black text-white' : 'text-black border border-border cursor-not-allowed'}`}
                                >
                                    Change Password
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center space-x-3 text-gray-500 text-smallish mb-28">
                    <button onClick={() => {
                    }}>Terms of Use
                    </button>
                    <div> |</div>
                    <button onClick={() => {
                    }}>Privacy Policy
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Reset;

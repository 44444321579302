import {gradeApi} from '../api.js';

export const login = async (email, password) => {
    try {
        const response = await gradeApi.post({
            path: '/api/login/',
            body: {
                email,
                password: password
            }
        });

        return response.data;
    } catch (error) {
        console.error('Login error:', error);
        return {error: error.response?.data?.error || 'Error in backend'};
    }
};

export const signUp = async (firstName, lastName, email, password, is_teacher) => {
    try {
        // console.log('Signing up');
        const response = await gradeApi.post({
            path: '/api/register/',
            body: {
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: password,
                is_teacher: is_teacher
            }
        });
        // console.log(response);
        return response.data;
    } catch (error) {
        console.error('Sign up error:', error);
        const errorData = error.response?.data;
        if (errorData && errorData.type) {
            return {
                error: errorData.error || 'Error in backend',
                type: errorData.type
            };
        } else {
            return {error: errorData?.error || 'Error in backend', type: 'error'};
        }
    }
};

// export const updateSession = async (update_token) => {
//     // console.log(update_token)
//     try {
//         const response = await gradeApi.post({
//             path: `/api/session/`,
//             body: {},
//                config: { headers: {
//                     Authorization: `Bearer ${update_token}`,
//                 }},
//             }
//         );
//         return response.data;
//     } catch (error) {
//         return {error: "Error in backend"};
//     }
// };

export const logout = async token => {
    try {
        const response = await gradeApi.post({
            path: '/api/logout/',
            body: {},
            config: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        });
        return response.data;
    } catch (error) {
        return {error: 'Error in backend'};
    }
};

export const getUser = async (userId, token) => {
    try {
        const response = await gradeApi.get({
            path: `/api/users/${userId}/`,
            config: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        });
        return response.data;
    } catch (error) {
        return {error: 'Error in Backend'};
    }
};
// export const checkSession = async (sessionToken) => {
//     try {
//         const response = await gradeApi.post({
//             path: `/api/check-session/`,
//             body: {},
//                 config: {headers: {
//                     Authorization: `Bearer ${sessionToken}`,
//                 }},
//             }
//         );
//         return response.data;
//     } catch (error) {
//         console.error("Check session error:", error);
//         return {
//             error: error.response?.data?.error || "Error checking session",
//             status: error.response?.status,
//         };
//     }
// };

// export const createTestUser = async (userId, token) => {
//   try {
//     const response = await gradeApi.get({path: '/create_test_user',
//       config: {headers: {
//         Authorization: `Bearer ${token}`,
//       }},
//     });
//     return response.data;
//   } catch (error) {
//     return { error: "Error in Backend" };
//   }
// };
